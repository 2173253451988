/**
 * Generics used to type the icons of the history log items.
 */
export function getHistoryLogItems() {
    return historyLogItems;
}
const historyLogItems = {
    ACCEPT_CONTACT: {
        customIcon: 'users',
        messageKey: 'historyLog.type.acceptedContact',
        messageValues: item => ({
            contact: item.data.name,
        }),
    },
    ADD_CONTACT: {
        customIcon: 'users',
        messageKey: 'historyLog.type.addContact',
        messageValues: item => ({
            email: item.data.email,
        }),
    },
    ADD_CONTACT_RECEIVED: {
        customIcon: 'users',
        messageKey: 'historyLog.type.addContactReceived',
        messageValues: item => ({
            contact: item.data.name,
            email: item.data.email,
        }),
    },
    ADD_STUDY_CONTACT: {
        customIcon: 'users',
        messageKey: 'historyLog.type.addStudyContact',
        messageValues: item => ({
            name: item.data.name,
        }),
    },
    AUTOMATICALLY_CLAIMED_VAULT_CITIZEN: {
        customIcon: 'vault',
        messageKey: 'historyLog.type.automaticallyClaimedVaultCitizen',
        messageValues: item => ({
            user: item.data.name,
        }),
    },
    AUTOMATICALLY_CLAIMED_VAULT_NOTARY: {
        customIcon: 'vault',
        messageKey: 'historyLog.type.automaticallyClaimedVaultNotary',
        messageValues: item => ({
            name: item.data.name,
        }),
    },
    AUTOMATICALLY_REJECTED_CONTACT_REQUEST: {
        customIcon: 'users',
        messageKey: 'historyLog.type.automaticallyRejectedContactRequest',
        messageValues: item => ({
            user: item.data.name,
        }),
    },
    CERTIFICATION_GENERATED: {
        customIcon: 'certified-document',
        messageKey: 'historyLog.type.certificationGenerated',
        messageValues: item => ({
            documentName: item.data.documentName,
            notaryName: `${item.user.firstName} ${item.user.lastName}`,
        }),
    },
    CHANGE_ADDRESS: {
        customIcon: 'user',
        messageKey: 'historyLog.type.changeAddress',
        messageValues: item => ({
            oldAddress: item.data.oldAddress,
            newAddress: item.data.newAddress,
        }),
    },
    CITIZEN_ADDED_AS_CONTACT: {
        customIcon: 'users',
        messageKey: 'historyLog.type.citizenAddedAsContact',
        messageValues: (item, t) => ({
            name: item.data.name,
            studyName: item.user.studyName || t('historyLog.type.yourStudy'),
        }),
    },
    CLAIMED_VAULT_PASSED_OWNER: {
        customIcon: 'vault',
        messageKey: 'historyLog.type.claimedVaultPassedOwner',
        messageValues: item => ({
            vaultName: item.data.deceasedName,
            notaryName: item.data.notaryName,
        }),
    },
    CLAIMED_VAULT_PASSED_RECEIVER: {
        customIcon: 'vault',
        messageKey: 'historyLog.type.claimedVaultPassedReceiver',
        messageValues: item => ({
            vaultName: item.data.deceasedName,
            studyName: item.data.studyName,
        }),
    },
    CONNECTION_ADDED: {
        customIcon: 'share-2',
        featherIconName: 'link',
        messageKey: 'historyLog.type.connectionAdded',
        messageValues: item => ({
            type: item.data.connectionType,
        }),
    },
    CONNECTION_REMOVED: {
        customIcon: 'share-2',
        featherIconName: 'link',
        messageKey: 'historyLog.type.connectionRemoved',
    },
    CONTACT_ACCEPTED: {
        customIcon: 'users',
        messageKey: 'historyLog.type.contactAccepted',
        messageValues: item => ({
            contact: item.data.name,
        }),
    },
    DECLINE_CONTACT: {
        customIcon: 'users',
        messageKey: 'historyLog.type.declineContact',
        messageValues: item => ({
            contact: item.data.name,
        }),
    },
    DELETE_ACT: {
        customIcon: 'document',
        messageKey: 'historyLog.type.deleteAct',
        messageValues: item => ({
            actName: item.data.name,
        }),
    },
    DELETE_CONTACT: {
        customIcon: 'users',
        messageKey: 'historyLog.type.deleteContact',
        messageValues: item => ({
            contact: item.data.name,
        }),
    },
    DELETE_CONTACT_RECEIVED: {
        customIcon: 'users',
        messageKey: 'historyLog.type.deleteContactReceived',
        messageValues: item => ({
            contact: item.data.name,
        }),
    },
    DELETE_CONTACT_RECEIVED_AUTOMATICALLY: {
        customIcon: 'users',
        messageKey: 'historyLog.type.deleteContactReceivedAutomatically',
        messageValues: item => ({
            user: item.data.name,
        }),
    },
    DELETE_CONTACT_AUTOMATICALLY: {
        customIcon: 'users',
        messageKey: 'historyLog.type.deleteContactReceivedAutomatically',
        messageValues: item => ({
            user: item.data.name,
        }),
    },
    DELETE_DOCUMENT: {
        customIcon: 'document',
        messageKey: 'historyLog.type.deleteDocument',
        messageValues: item => ({
            documentName: item.data.name,
        }),
    },
    DOWNLOAD_ACT: {
        customIcon: 'document',
        messageKey: 'historyLog.type.downloadAct',
        messageValues: item => ({
            actName: item.data.name,
        }),
    },
    DOWNLOAD_DOCUMENT: {
        customIcon: 'document',
        messageKey: 'historyLog.type.downloadDocument',
        messageValues: item => ({
            documentName: item.data.name,
        }),
    },
    EDIT_ACT: {
        customIcon: 'document',
        messageKey: 'historyLog.type.editAct',
        messageValues: item => ({
            actName: item.data.deedReference,
        }),
        infoValues: item => ({
            editAct: item.data,
        }),
    },
    EDIT_AFTER_DEATH_TRANSFER_PREFERENCES: {
        customIcon: 'vault',
        messageKey: 'historyLog.type.editAfterDeathPreferences',
        infoValues: item => ({
            afterDeath: item.data,
        }),
    },
    EDIT_DOCUMENT: {
        customIcon: 'document',
        messageKey: 'historyLog.type.editDocument',
        messageValues: item => ({
            documentName: item.data.name,
        }),
        infoValues: item => ({
            editDocument: item.data,
        }),
    },
    EDIT_EMAIL: {
        customIcon: 'user',
        messageKey: 'historyLog.type.editEmail',
        messageValues: item => ({
            previousEmail: item.data.previousEmail,
            newEmail: item.data.newEmail,
        }),
    },
    FREEZE_VAULT_AFTER_DEATH: {
        customIcon: 'vault',
        messageKey: 'historyLog.type.freezeVaultAfterDeath',
    },
    HEIR_ADDED_FOR_VAULT_TRANSFER: {
        customIcon: 'vault',
        messageKey: 'historyLog.type.heirAddedForVaultTransfer',
        messageValues: item => ({
            heirName: `${item.data.firstName} ${item.data.lastName}`,
            vaultName: item.data.vaultOwner,
        }),
        infoValues: item => ({
            nrn: item.data.nrn,
            email: item.data.email,
            heirStatus: { newStatus: item.data.status },
        }),
    },
    HEIR_EDITED_FOR_VAULT_TRANSFER: {
        customIcon: 'vault',
        messageKey: 'historyLog.type.heirEditedForVaultTransfer',
        messageValues: item => ({
            heirName: `${item.data.firstName} ${item.data.lastName}`,
            vaultName: item.data.vaultOwner,
        }),
        infoValues: item => ({
            nrn: item.data.nrn,
            email: item.data.email,
            heirStatus: {
                newStatus: item.data.newStatus,
                oldStatus: item.data.oldStatus,
            },
        }),
    },
    HEIR_REMOVED_FOR_VAULT_TRANSFER: {
        customIcon: 'vault',
        messageKey: 'historyLog.type.heirRemovedForVaultTransfer',
        messageValues: item => ({
            heirName: `${item.data.firstName} ${item.data.lastName}`,
            vaultName: item.data.vaultOwner,
        }),
        infoValues: item => ({
            nrn: item.data.nrn,
            email: item.data.email,
            heirStatus: { newStatus: item.data.status },
        }),
    },
    MANUALLY_CLAIMED_VAULT_CITIZEN: {
        customIcon: 'vault',
        messageKey: 'historyLog.type.manuallyClaimedVaultCitizen',
        messageValues: item => ({
            notaryName: item.data.name,
        }),
    },
    MANUALLY_CLAIMED_VAULT_NOTARY: {
        customIcon: 'vault',
        messageKey: 'historyLog.type.manuallyClaimedVaultNotary',
        messageValues: item => ({
            vaultName: item.data.name,
        }),
    },
    NOTARY_CHANGE: {
        customIcon: 'glasses',
        messageKey: 'historyLog.type.notaryChange',
        messageValues: item => ({
            oldName: item.data.oldName,
            newName: item.data.newName,
        }),
    },
    NOTARY_SET: {
        customIcon: 'glasses',
        messageKey: 'historyLog.type.notarySet',
        messageValues: item => ({
            name: item.data.name,
        }),
    },
    PERMIT_NOTARY_UPLOAD: {
        customIcon: 'glasses',
        messageKey: 'historyLog.type.permitNotaryUpload',
        messageValues: item => ({
            user: `${item.user.firstName} ${item.user.lastName}`,
            notary: item.data.notaryName,
        }),
    },
    PREFERRED_NOTARY_ADDED_AS_CONTACT: {
        customIcon: 'glasses',
        messageKey: 'historyLog.type.preferredNotaryAddedAsContact',
        messageValues: item => ({
            name: item.data.name,
        }),
    },
    PREVIEW_ACT: {
        customIcon: 'document',
        messageKey: 'historyLog.type.previewAct',
        messageValues: item => ({
            actName: item.data.name,
        }),
    },
    PREVIEW_DOCUMENT: {
        customIcon: 'document',
        messageKey: 'historyLog.type.previewDocument',
        messageValues: item => ({
            documentName: item.data.name,
        }),
    },
    PUSH_DOCUMENT_ACCEPTED: {
        customIcon: 'user-share-accepted',
        messageKey: 'historyLog.type.pushDocumentAccepted',
        messageValues: item => ({
            documentName: item.data.documentName,
            sharedBy: item.data.studyName,
        }),
        infoValues: item => ({
            labels: item.data.labels,
            size: item.data.size,
            message: item.data.pushMessage,
        }),
    },
    PUSH_DOCUMENT_ACCEPTED_PUSHER: {
        customIcon: 'push-accepted',
        messageKey: 'historyLog.type.pushDocumentAcceptedPusher',
        messageValues: item => ({
            documentName: item.data.documentName,
            name: `${item.data.firstName} ${item.data.lastName}`,
        }),
        infoValues: item => ({
            labels: item.data.labels,
            size: item.data.size,
            message: item.data.pushMessage,
        }),
    },
    PUSH_DOCUMENT_DECLINED: {
        customIcon: 'user-share-declined',
        messageKey: 'historyLog.type.pushDocumentDeclined',
        messageValues: item => ({
            documentName: item.data.documentName,
            sharedBy: item.data.studyName,
        }),
        infoValues: item => ({
            labels: item.data.labels,
            size: item.data.size,
            message: item.data.pushMessage,
        }),
    },
    PUSH_DOCUMENT_DECLINED_AUTOMATICALLY_CITIZEN: {
        customIcon: 'user-share-declined',
        messageKey: 'historyLog.type.pushDocumentDeclinedAutomatically',
        messageValues: item => ({
            documentName: item.data.documentName,
            sharedBy: item.data.deceasedName,
        }),
        infoValues: item => ({
            labels: item.data.labels,
            size: item.data.size,
            message: item.data.pushMessage,
        }),
    },
    PUSH_DOCUMENT_DECLINED_AUTOMATICALLY_NOTARY: {
        customIcon: 'user-share-declined',
        messageKey: 'historyLog.type.pushDocumentDeclinedAutomatically',
        messageValues: item => ({
            documentName: item.data.documentName,
            sharedBy: item.data.deceasedName,
        }),
        infoValues: item => ({
            labels: item.data.labels,
            size: item.data.size,
            message: item.data.pushMessage,
        }),
    },
    PUSH_DOCUMENT_DECLINED_PUSHER: {
        customIcon: 'push-declined',
        messageKey: 'historyLog.type.pushDocumentDeclinedPusher',
        messageValues: item => ({
            documentName: item.data.documentName,
            name: `${item.data.firstName} ${item.data.lastName}`,
        }),
        infoValues: item => ({
            labels: item.data.labels,
            size: item.data.size,
            message: item.data.pushMessage,
        }),
    },
    FAILED_PUSH_DOCUMENT: {
        customIcon: 'push',
        messageKey: 'historyLog.type.pushDocumentNotaryFailed',
        messageValues: item => ({
            name: item.data.name,
        }),
    },
    PUSH_DOCUMENT_NOTARY: {
        customIcon: 'push',
        messageKey: 'historyLog.type.pushDocumentNotary',
        messageValues: item => ({
            documentName: item.data.documentName,
            // @ts-expect-error api types are incorrect
            name: item.data.name,
        }),
        infoValues: item => ({
            labels: item.data.labels,
            size: item.data.size,
            message: item.data.pushMessage,
        }),
    },
    PUSH_DOCUMENT_RECEIVED: {
        customIcon: 'user-share',
        messageKey: 'historyLog.type.pushDocumentReceived',
        messageValues: item => ({
            documentName: item.data.documentName,
            studyName: item.data.studyName,
        }),
        infoValues: item => ({
            labels: item.data.labels,
            size: item.data.size,
            message: item.data.pushMessage,
        }),
    },
    RECOVER_DOCUMENT: {
        customIcon: 'document',
        messageKey: 'historyLog.type.recoverDocument',
        messageValues: item => ({
            documentName: item.data.name,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            shareable: item.data.shareable,
            size: item.data.size,
        }),
    },
    REFUSE_NOTARY_UPLOAD: {
        customIcon: 'glasses',
        messageKey: 'historyLog.type.refuseNotaryUpload',
        messageValues: item => ({
            user: `${item.user.firstName} ${item.user.lastName}`,
            notary: item.data.notaryName,
        }),
    },
    REGISTER_USER: {
        customIcon: 'user-add',
        messageKey: 'historyLog.type.registerUser',
        messageValues: item => ({
            user: `${item.user.firstName} ${item.user.lastName}`,
        }),
    },
    REJECTED_DOCUMENT: {
        customIcon: 'user',
        messageKey: 'historyLog.type.rejectedDocument',
        messageValues: item => ({
            documentName: item.data.name,
        }),
    },
    SET_ADDRESS: {
        customIcon: 'user',
        messageKey: 'historyLog.type.setAddress',
        messageValues: item => ({
            address: item.data.address,
        }),
    },
    SHARE_DOCUMENT: {
        customIcon: 'user-share',
        messageKey: 'historyLog.type.shareDocument',
        messageValues: item => ({
            documentName: item.data.documentName,
            user: item.data.name,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            shareable: item.data.shareable,
            size: item.data.size,
            message: item.data.shareMessage,
        }),
    },
    SHARE_DOCUMENT_ACCEPTED_OWNER: {
        customIcon: 'user-share-accepted',
        messageKey: 'historyLog.type.shareDocumentAcceptedOwner',
        messageValues: item => ({
            sharedBy: item.data.userName,
            documentName: item.data.documentName,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            shareable: item.data.shareable,
            size: item.data.size,
        }),
    },
    SHARE_DOCUMENT_ACCEPTED_RECEIVER: {
        customIcon: 'user-share-accepted',
        messageKey: 'historyLog.type.shareDocumentAcceptedReceiver',
        messageValues: item => ({
            documentName: item.data.documentName,
            sharedBy: item.data.userName,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            shareable: item.data.shareable,
            size: item.data.size,
        }),
    },
    SHARE_DOCUMENT_DECLINE_AUTOMATICALLY_OWNER: {
        customIcon: 'user-share-declined',
        messageKey: 'historyLog.type.shareDocumentDeclineAutomaticallyOwner',
        messageValues: item => ({
            documentName: item.data.documentName,
            user: item.data.userName,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            shareable: item.data.shareable,
            size: item.data.size,
        }),
    },
    SHARE_DOCUMENT_DECLINED_OWNER: {
        customIcon: 'user-share-declined',
        messageKey: 'historyLog.type.shareDocumentDeclinedOwner',
        messageValues: item => ({
            sharedBy: item.data.userName,
            documentName: item.data.documentName,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            shareable: item.data.shareable,
            size: item.data.size,
        }),
    },
    SHARE_DOCUMENT_DECLINED_RECEIVER: {
        customIcon: 'user-share-declined',
        messageKey: 'historyLog.type.shareDocumentDeclinedReceiver',
        messageValues: item => ({
            documentName: item.data.documentName,
            sharedBy: item.data.userName,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            shareable: item.data.shareable,
            size: item.data.size,
        }),
    },
    SHARE_DOCUMENT_NOTARY_CITIZEN: {
        customIcon: 'user-share',
        messageKey: 'historyLog.type.shareDocumentNotaryCitizen',
        messageValues: item => ({
            documentName: item.data.documentName,
            studyName: item.user.studyName ?? '',
        }),
        infoValues: item => ({
            labels: item.data.labels,
            message: item.data.pushMessage,
        }),
    },
    SHARE_DOCUMENT_RECEIVED: {
        customIcon: 'user-share',
        messageKey: 'historyLog.type.shareDocumentReceived',
        messageValues: item => ({
            documentName: item.data.documentName,
            user: `${item.user.firstName} ${item.user.lastName}`,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            shareable: item.data.shareable,
            size: item.data.size,
            message: item.data.shareMessage,
        }),
    },
    SHARE_ITEM_THIRD_PARTY_STUDY: {
        customIcon: 'user-share',
        featherIconName: 'link',
        messageKey: 'historyLog.type.shareItemThirdPartyStudy',
        messageValues: item => ({
            documentName: item.data.documentName,
            thirdPartyName: item.data.thirdPartyName,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            size: item.data.size,
            message: item.data.pushMessage,
        }),
    },
    SHARE_ITEM_THIRD_PARTY_CITIZEN: {
        customIcon: 'user-share',
        featherIconName: 'link',
        messageKey: 'historyLog.type.shareItemThirdPartyCitizen',
        messageValues: item => ({
            documentName: item.data.documentName,
            thirdPartyName: item.data.thirdPartyName,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            size: item.data.size,
            message: item.data.pushMessage,
        }),
    },
    SHARED_DOCUMENT_COPIED_TO_LOCAL_VAULT: {
        customIcon: 'user-share',
        messageKey: 'historyLog.type.sharedDocumentCopiedToLocalVault',
        messageValues: item => ({
            documentName: item.data.documentName,
            user: item.data.ownerName,
        }),
    },
    SHARED_DOCUMENT_COPIED_TO_REMOTE_VAULT: {
        customIcon: 'user-share',
        messageKey: 'historyLog.type.sharedDocumentCopiedToRemoteVault',
        messageValues: item => ({
            documentName: item.data.documentName,
            user: item.data.userName,
        }),
    },
    TRASH_ACT: {
        customIcon: 'document',
        messageKey: 'historyLog.type.trashAct',
        messageValues: item => ({
            actName: item.data.deedReference,
        }),
    },
    TRASH_DOCUMENT: {
        customIcon: 'document',
        messageKey: 'historyLog.type.trashDocument',
        messageValues: item => ({
            documentName: item.data.name,
        }),
    },
    UNSHARE_DOCUMENT: {
        customIcon: 'user-share-declined',
        messageKey: 'historyLog.type.unshareDocument',
        messageValues: item => ({
            documentName: item.data.documentName,
            user: item.data.name,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            shareable: item.data.shareable,
            size: item.data.size,
        }),
    },
    UNSHARE_DOCUMENT_AUTOMATICALLY_OWNER: {
        customIcon: 'user-share-declined',
        messageKey: 'historyLog.type.unshareDocumentAutomaticallyOwner',
        messageValues: item => ({
            documentName: item.data.documentName,
            user: item.data.userName,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            shareable: item.data.shareable,
            size: item.data.size,
        }),
    },
    UNSHARE_DOCUMENT_AUTOMATICALLY_OWNER_AFTER_DEATH: {
        customIcon: 'user-share-declined',
        messageKey: 'historyLog.type.unshareDocumentAutomaticallyOwnerAfterDeath',
        messageValues: item => ({
            documentName: item.data.documentName,
            user: item.data.userName,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            shareable: item.data.shareable,
            size: item.data.size,
        }),
    },
    UNSHARE_DOCUMENT_AUTOMATICALLY_RECEIVER: {
        customIcon: 'user-share-declined',
        messageKey: 'historyLog.type.unshareDocumentAutomaticallyReceiver',
        messageValues: item => ({
            documentName: item.data.documentName,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            shareable: item.data.shareable,
            size: item.data.size,
        }),
    },
    UNSHARE_DOCUMENT_AUTOMATICALLY_RECEIVER_AFTER_DEATH: {
        customIcon: 'user-share-declined',
        messageKey: 'historyLog.type.unshareDocumentAutomaticallyReceiverAfterDeath',
        messageValues: item => ({
            documentName: item.data.documentName,
            user: item.data.userName,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            shareable: item.data.shareable,
            size: item.data.size,
        }),
    },
    UNSHARE_DOCUMENT_RECEIVED: {
        customIcon: 'user-share-declined',
        messageKey: 'historyLog.type.unshareDocumentReceived',
        messageValues: item => ({
            documentName: item.data.documentName,
            user: `${item.user.firstName} ${item.user.lastName}`,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            shareable: item.data.shareable,
            size: item.data.size,
        }),
    },
    UPLOAD_ACT: {
        customIcon: 'document',
        messageKey: 'historyLog.type.uploadAct',
        messageValues: item => ({
            actName: item.data.deedReference,
        }),
        infoValues: item => ({
            uploadAct: item.data,
        }),
    },
    UPLOAD_DOCUMENT: {
        customIcon: 'document',
        messageKey: 'historyLog.type.uploadDocument',
        messageValues: item => ({
            documentName: item.data.name,
        }),
        infoValues: item => ({
            categories: item.data.categories,
            labels: item.data.labels,
            shareable: item.data.shareable,
            size: item.data.size,
        }),
    },
    USER_DEACTIVATION_REQUESTED: {
        customIcon: 'user',
        messageKey: 'historyLog.type.userDeactivationRequested',
        messageValues: item => ({
            user: `${item.user.firstName} ${item.user.lastName}`,
        }),
    },
    USER_LOGGED_IN: {
        customIcon: 'user',
        messageKey: 'historyLog.type.userLoggedIn',
        messageValues: item => ({
            user: `${item.user.firstName} ${item.user.lastName}`,
        }),
    },
    USER_LOGGED_OUT: {
        customIcon: 'logout',
        messageKey: 'historyLog.type.userLoggedOut',
        messageValues: item => ({
            user: `${item.user.firstName} ${item.user.lastName}`,
        }),
    },
    USER_LOGGED_OUT_AUTOMATICALLY: {
        customIcon: 'logout',
        messageKey: 'historyLog.type.userLoggedOutAutomatically',
    },
    USER_UNREGISTERED: {
        customIcon: 'user',
        messageKey: 'historyLog.type.unregisterUser',
        messageValues: item => ({
            user: `${item.user.firstName} ${item.user.lastName}`,
        }),
    },
    USER_UNSUBSCRIBED: {
        customIcon: 'user',
        messageKey: 'historyLog.type.userUnsubscribed',
        messageValues: item => ({
            user: `${item.user.firstName} ${item.user.lastName}`,
        }),
    },
    VAULT_TRANSFER_EXECUTED_DECEASED: {
        customIcon: 'vault',
        messageKey: 'historyLog.type.vaultTransferExecutedDeceased',
        messageValues: item => ({
            heirName: item.data.heirName,
        }),
    },
    VAULT_TRANSFER_EXECUTED_RECEIVER: {
        customIcon: 'vault',
        messageKey: 'historyLog.type.vaultTransferExecutedReceiver',
        messageValues: item => ({
            vaultName: item.data.deceasedName,
        }),
    },
    VAULT_TRANSFER_EXECUTED_SENDER: {
        customIcon: 'vault',
        messageKey: 'historyLog.type.vaultTransferExecutedSender',
        messageValues: item => ({
            vaultName: item.data.deceasedName,
            heirName: item.data.heirName,
        }),
    },
    AUTOMATICALLY_DELETE_DOCUMENT: {
        customIcon: 'document',
        messageKey: 'historyLog.type.automaticallyDeleteDocument',
        messageValues: item => ({
            documentName: item.data.name,
        }),
    },
    AUTOMATICALLY_TRASH_DOCUMENT: {
        customIcon: 'document',
        messageKey: 'historyLog.type.automaticallyTrashDocument',
        messageValues: item => ({
            documentName: item.data.name,
        }),
    },
    AUTOMATICALLY_TRASH_DOCUMENT_RESET: {
        customIcon: 'document',
        messageKey: 'historyLog.type.automaticallyTrashDocumentReset',
        messageValues: item => ({
            documentName: item.data.name,
        }),
    },
    STUDY_EDIT_MAIL_CONFIGURATION: {
        customIcon: 'mail',
        messageKey: 'historyLog.type.studyEditMailConfiguration',
        infoValues: item => ({
            studyEditMailConfiguration: item.data,
        }),
    },
    CITIZEN_EDIT_MAIL_CONFIGURATION: {
        customIcon: 'mail',
        messageKey: 'historyLog.type.citizenEditMailConfiguration',
        infoValues: item => ({
            citizenEditMailConfiguration: item.data,
        }),
    },
    SHARED_DOCUMENT_DELETED_BY_RECEIVER: undefined,
    CLAIMED_VAULT_PASSED_CITIZEN: undefined,
    ADD_STUDY_CONTACT_RECEIVED: undefined,
    VAULT_TRANSFER_AUTOMATICALLY_REVOKED_SENDER: undefined,
    VAULT_TRANSFER_AUTOMATICALLY_REVOKED_RECEIVER: undefined,
    AUTOMATICALLY_UNCLAIMED_VAULT_NOTARY: undefined,
    AUTOMATICALLY_UNCLAIMED_VAULT_CITIZEN: undefined,
    AFTER_DEATH_TRANSFER_PREFERENCES_RESET_TO_DEFAULT: undefined,
    STUDY_CONTACT_CHANGED: undefined,
    NOTARY_STUDY_CONTACT_CHANGED: undefined,
    SHARE_DOCUMENT_NOTARY: undefined,
    STUDY_MERGE_COMPLETED: undefined,
};
