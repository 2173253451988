export const getConfig = (t, cookieUrl) => ({
    policies: [
        {
            id: 'essential',
            label: t('cookieConsent.policies.essential.label'),
            description: t('cookieConsent.policies.essential.description'),
            category: 'essential',
        },
        {
            id: 'statistics',
            label: t('cookieConsent.policies.statistics.label'),
            description: t('cookieConsent.policies.statistics.description'),
            category: 'statistics',
        },
    ],
    essentialLabel: t('cookieConsent.essentialLabel'),
    permissionLabels: {
        accept: t('cookieConsent.permissionLabels.accept'),
        acceptAll: t('cookieConsent.permissionLabels.acceptAll'),
        decline: t('cookieConsent.permissionLabels.decline'),
    },
    header: {
        title: t('cookieConsent.header.title'),
        subTitle: t('cookieConsent.header.subTitle'),
        description: t('cookieConsent.header.description'),
    },
    cookiePolicy: {
        url: cookieUrl,
        label: t('cookieConsent.cookiePolicy.label'),
    },
    customizeLabel: 'Customize',
});
