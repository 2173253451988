function FamilyIcon({ size = 32 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
    >
      <rect x={2} width={28} height={26} rx={4} fill='#7E039C' />
      <rect y={2} width={32} height={30} rx={4} fill='#AF59C4' />
      <rect
        y={2}
        width={32}
        height={30}
        rx={4}
        fill='url(#a)'
        fillOpacity={0.2}
        style={{
          mixBlendMode: 'multiply',
        }}
      />
      <circle cx={18} cy={11} r={4} fill='#7E039C' />
      <path d='M10 20a4 4 0 0 1 4-4h7a4 4 0 0 1 4 4v4H10v-4Z' fill='#7E039C' />
      <path d='M10 20a4 4 0 0 1 4-4h7a4 4 0 0 1 4 4v4H10v-4Z' fill='#7E039C' />
      <circle cx={13} cy={13} r={3} fill='#fff' />
      <path d='M7 22a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2H7v-2Z' fill='#F1D7F7' />
      <path d='M7 22a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2H7v-2Z' fill='url(#b)' />
      <path
        d='M9.75 23.5v.75h1.5v-.75h-1.5Zm1.5-1a.75.75 0 0 0-1.5 0h1.5Zm0 1v-1h-1.5v1h1.5ZM15.75 23.5v.75h1.5v-.75h-1.5Zm1.5-1a.75.75 0 0 0-1.5 0h1.5Zm0 1v-1h-1.5v1h1.5Z'
        fill='#9D4EB8'
      />
      <defs>
        <linearGradient
          id='a'
          x1={16}
          y1={10}
          x2={16}
          y2={32}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#350D89' stopOpacity={0} />
          <stop offset={1} stopColor='#350D89' />
        </linearGradient>
        <linearGradient
          id='b'
          x1={13}
          y1={18}
          x2={13}
          y2={22}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default FamilyIcon;
