import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RemoveScroll } from 'react-remove-scroll';
import { CloseButton, Divider, Flex, Text, useBreakpointValue, useTheme, } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { useAppHeight, useIsMobile } from '@izimi/hooks';
import { hex2rgba } from '@izimi/utils';
import { useModalStore } from '../modals';
import MotionBox from '../MotionBox/MotionBox';
const SideBar = ({ children, isVisible, title, HeaderRight, HeaderLeft, sideBarWidth = 400, onClose, ...props }) => {
    const appHeight = useAppHeight();
    const theme = useTheme();
    const width = useBreakpointValue({
        base: '90%',
        md: `${sideBarWidth}px`,
        xl: '450px',
    }, { ssr: false });
    const { modalEntity } = useModalStore();
    const isMobile = useIsMobile();
    const shouldDisableScroll = modalEntity ? false : isMobile;
    return (_jsx(AnimatePresence, { children: isVisible && (_jsxs(RemoveScroll, { enabled: shouldDisableScroll, children: [_jsxs(MotionBox, { initial: { width: 0 }, animate: { width }, exit: { width: 0 }, position: { base: 'fixed', lg: 'relative' }, right: 0, top: 0, zIndex: 'modal', height: appHeight, overflow: 'auto', bg: 'white', borderLeftWidth: 1, borderColor: 'neutral100', borderLeftRadius: { base: 'lg', lg: 'none' }, as: 'section', display: 'flex', flexDirection: 'column', ...props, children: [_jsxs(Flex, { alignItems: 'center', justifyContent: 'space-between', p: 4, pos: 'sticky', top: 0, zIndex: 'sticky', bg: 'white', minHeight: 'fit-content', flexDir: 'row', children: [HeaderLeft, _jsx(Text, { as: 'h2', variant: 'heading6', color: 'neutral400', noOfLines: 2, textAlign: 'left', flex: '1', title: title, children: title }), HeaderRight || _jsx(CloseButton, { onClick: onClose })] }), _jsx(Divider, {}), children] }), _jsx(MotionBox, { initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, display: { base: 'block', lg: 'none' }, pos: 'fixed', top: 0, left: 0, zIndex: 'overlay', bg: hex2rgba(theme.colors.neutral600, 0.4), w: '100%', h: '100%', onClick: onClose })] })) }));
};
export default SideBar;
