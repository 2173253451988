export class HistoryLogQueryKeys {
    prefix;
    constructor(prefix) {
        this.prefix = prefix;
    }
    getHistoryLogs(suffix) {
        if (suffix) {
            return [this.prefix, suffix];
        }
        return [this.prefix];
    }
}
