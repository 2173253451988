import inputStyleConfig from './input';
const TextareaComponentStyle = {
    baseStyle: {
        // @ts-expect-error okay
        ...inputStyleConfig.baseStyle.field,
        py: 3,
        lineHeight: 1.5,
    },
    defaultProps: {
        variant: 'empty',
        size: 'lg',
    },
    sizes: {
        ...inputStyleConfig.sizes,
    },
    variants: {
        empty: {},
        invalid: {
            field: {
                borderColor: 'secondary400',
            },
        },
    },
};
export default TextareaComponentStyle;
