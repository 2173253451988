export var ApiDocumentCategory;
(function (ApiDocumentCategory) {
    ApiDocumentCategory["All"] = "all";
    ApiDocumentCategory["Personal"] = "personal";
    ApiDocumentCategory["Household"] = "household";
    ApiDocumentCategory["Business"] = "business";
    ApiDocumentCategory["Medical"] = "medical";
    ApiDocumentCategory["RealEstate"] = "real estate";
    ApiDocumentCategory["Family"] = "family";
    ApiDocumentCategory["Property"] = "property";
    ApiDocumentCategory["Finance"] = "finance";
})(ApiDocumentCategory || (ApiDocumentCategory = {}));
export var ApiDocumentSource;
(function (ApiDocumentSource) {
    ApiDocumentSource["User"] = "USER";
    ApiDocumentSource["Study"] = "STUDY";
    ApiDocumentSource["Naban"] = "NABAN";
    ApiDocumentSource["SSN"] = "ENT_SSN";
    ApiDocumentSource["Shared"] = "SHARED";
    ApiDocumentSource["Tuyeau"] = "FPS_FINANCE";
    ApiDocumentSource["Vlabel"] = "VLABEL";
})(ApiDocumentSource || (ApiDocumentSource = {}));
