import { buildQueryString } from '@izimi/utils';
class ContactsApi {
    fetchService;
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getContacts(params) {
        const qs = buildQueryString(params);
        return this.fetchService.get(`/contacts${qs}`);
    }
    getContactsV2(params) {
        const qs = buildQueryString(params);
        return this.fetchService.get(`/v2/contacts${qs}`);
    }
    inviteContact({ emailAddress }) {
        return this.fetchService.post('/contacts', {
            emailAddress,
        });
    }
    deleteContact({ contactId }) {
        return this.fetchService.delete(`/contacts/${contactId}`);
    }
    acceptContactRequest({ contactId, }) {
        return this.fetchService.put(`/contacts/${contactId}/accept`);
    }
    rejectContactRequest({ contactId, }) {
        return this.fetchService.put(`/contacts/${contactId}/reject`);
    }
}
export default ContactsApi;
