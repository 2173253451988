import { useMutation, useQuery } from '@tanstack/react-query';
import { userQueryKeys, useSetUserCache, useSetUserMailConfiguration, useSetUserPreferencesCache, } from './cache';
const STALE_30_MINUTES = 1800000;
const createUserModule = ({ userApi }) => {
    const useCurrentUser = (enabled = true) => {
        const { data, isLoading, error } = useQuery({
            queryKey: userQueryKeys.getCurrentUser(),
            queryFn: () => userApi.getCurrentUser(),
            enabled,
            staleTime: STALE_30_MINUTES,
        });
        return {
            currentUser: data,
            error,
            isLoading,
        };
    };
    const useRegisterUser = () => {
        const { mutateAsync, isPending, error } = useMutation({ mutationFn: values => userApi.registerUser(values) });
        return {
            registerUser: mutateAsync,
            isPending,
            error,
        };
    };
    const useUpdateUser = () => {
        const setUserCache = useSetUserCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: values => userApi.updateUser(values),
            onSuccess(user) {
                setUserCache(user);
            },
        });
        return {
            updateUser: mutateAsync,
            isPending,
            error,
        };
    };
    const useMarkUserDeceased = () => {
        const { mutateAsync, isPending, error } = useMutation({ mutationFn: values => userApi.markDeceased(values) });
        return {
            markUserDeceased: mutateAsync,
            isPending,
            error,
        };
    };
    const useUnmarkUserDeceased = () => {
        const { mutateAsync, isPending, error } = useMutation({ mutationFn: nrn => userApi.unmarkDeceased(nrn) });
        return {
            unmarkUserDeceased: mutateAsync,
            isPending,
            error,
        };
    };
    const useCancelAccountDeactivation = () => {
        const { mutateAsync, isPending, error } = useMutation({ mutationFn: () => userApi.cancelDeactivation() });
        return {
            cancelAccountDeactivation: mutateAsync,
            isPending,
            error,
        };
    };
    const useStartAccountDeactivation = () => {
        const { mutateAsync, isPending, error } = useMutation({ mutationFn: () => userApi.startDeactivation() });
        return {
            startAccountDeactivation: mutateAsync,
            isPending,
            error,
        };
    };
    const useCompleteAccountDeactivation = () => {
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: code => userApi.completeDeactivation({ deactivationCode: code }),
        });
        return {
            completeAccountDeactivation: mutateAsync,
            isPending,
            error,
        };
    };
    const useUserPreferences = () => {
        const { data, isLoading, error } = useQuery({
            queryKey: userQueryKeys.getUserPreferences(),
            queryFn: () => userApi.getUserPreferences(),
        });
        return {
            userPreferences: data,
            error,
            isLoading,
        };
    };
    const useUpdateUserPreferences = () => {
        const setUserPreferencesCache = useSetUserPreferencesCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: values => userApi.setUserPreferences(values),
            onSuccess(user) {
                setUserPreferencesCache(user);
            },
        });
        return {
            updateUserPreferences: mutateAsync,
            isPending,
            error,
        };
    };
    const useMailConfiguration = () => {
        const { data, isLoading, error } = useQuery({
            queryKey: userQueryKeys.getMailConfiguration(),
            queryFn: () => userApi.getUserMailConfiguration(),
        });
        return {
            data: data ?? {},
            totalActive: data?.subscriptions?.filter(sub => sub.active).length ?? 0,
            error,
            isLoading,
        };
    };
    const useUpdateMailConfiguration = () => {
        const setUserMailConfiguration = useSetUserMailConfiguration();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: values => userApi.setUserMailConfiguration(values),
            onSuccess: data => setUserMailConfiguration(data),
        });
        return {
            updateMailConfiguration: mutateAsync,
            isPending,
            error,
        };
    };
    return {
        useCurrentUser,
        useRegisterUser,
        useUpdateUser,
        useMarkUserDeceased,
        useUnmarkUserDeceased,
        useCancelAccountDeactivation,
        useStartAccountDeactivation,
        useCompleteAccountDeactivation,
        useUserPreferences,
        useUpdateUserPreferences,
        useMailConfiguration,
        useUpdateMailConfiguration,
    };
};
export default createUserModule;
