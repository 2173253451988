import { create } from 'zustand';
import { persist } from 'zustand/middleware';
const initialState = {
    hideDeleteDocumentConfirmation: false,
};
export const useDocumentDeleteStore = create()(persist(set => ({
    ...initialState,
    setHideDeleteDocumentConfirmation: hideDeleteDocumentConfirmation => set({ hideDeleteDocumentConfirmation }),
}), {
    name: 'documentDeleteStore',
    partialize(state) {
        return {
            hideDeleteDocumentConfirmation: state.hideDeleteDocumentConfirmation,
        };
    },
}));
