import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useState } from 'react';
import { MenuButton, Portal, useEventListener } from '@chakra-ui/react';
const ContextMenu = forwardRef(({ Menu }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [position, setPosition] = useState([0, 0]);
    useEventListener('contextmenu', e => {
        if (ref.current?.contains(e.target) ||
            e.target === ref.current) {
            e.preventDefault();
            if (isOpen) {
                setIsOpen(false);
                setTimeout(() => {
                    setIsOpen(true);
                }, 0);
            }
            else {
                setIsOpen(true);
            }
            setPosition([e.pageX, e.pageY]);
        }
        else {
            setIsOpen(false);
        }
    });
    if (!isOpen) {
        return null;
    }
    return (_jsx(Portal, { children: _jsx(Menu, { isOpen: isOpen, onClose: () => {
                setIsOpen(false);
            }, isLazy: false, placement: 'right-start', offset: [0, 0], gutter: 0, children: _jsx(MenuButton, { "aria-hidden": true, w: 1, h: 1, style: {
                    position: 'absolute',
                    left: position[0],
                    top: position[1],
                    cursor: 'default',
                } }) }) }));
});
export default ContextMenu;
