import { useQuery } from '@tanstack/react-query';
const STALE_30_MINUTES = 1800000;
const createTermsAndConditionsModule = ({ termsAndConditionsApi, }) => {
    const useTermsAndConditions = (language) => {
        const { data, isLoading, error, isSuccess } = useQuery({
            queryKey: ['termsAndConditions', { language }],
            queryFn: () => termsAndConditionsApi.getTermsAndConditions({ language }),
            staleTime: STALE_30_MINUTES,
        });
        return {
            termsAndConditions: data,
            error,
            isSuccess,
            isLoading,
        };
    };
    return { useTermsAndConditions };
};
export default createTermsAndConditionsModule;
