import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, HStack } from '@chakra-ui/react';
import { useModules } from '@izimi/modules';
export const CitizenDocumentsReceivedActions = memo(({ notification, navigateTo }) => {
    const { t } = useTranslation('notifications');
    const navigate = useNavigate();
    const { notifications: { useDeleteNotification }, } = useModules();
    const { deleteNotification, isPending: isLoadingDeleteNotification } = useDeleteNotification();
    const handleClick = async () => {
        await deleteNotification(notification.id);
        navigate(navigateTo, { replace: true });
    };
    return (_jsx(HStack, { mt: 2, children: _jsx(Button, { variant: 'primary500', flex: 1, onClick: handleClick, isLoading: isLoadingDeleteNotification, children: t('watch') }) }));
});
