function AllIcon({ size = 32 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
    >
      <rect x={2} width={28} height={26} rx={4} fill='#2092AC' />
      <rect y={2} width={32} height={30} rx={4} fill='#50B2C2' />
      <rect
        y={2}
        width={32}
        height={30}
        rx={4}
        fill='url(#a)'
        fillOpacity={0.25}
        style={{
          mixBlendMode: 'multiply',
        }}
      />
      <g clipPath='url(#b)'>
        <path
          d='M18.55 11.26a2.55 2.55 0 1 1-5.1.003 2.55 2.55 0 0 1 5.1-.004Z'
          fill='#fff'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.348 16.363v-3.729c.021-.137.107-.117.19-.02L16 19.55l.006-.004 7.456-6.934h.004c.083-.094.166-.113.187.018v3.732l-3.694 3.511-1.524 1.455.002.001-.09.084-.054.05h-.001l-2.315 2.123-2.247-2.113-.171-.162-.029-.026-5.181-4.922Z'
          fill='#fff'
        />
        <path
          d='m12.174 19.99-.017 4.911a.37.37 0 0 1-.13.275.47.47 0 0 1-.31.114h-2.93a.47.47 0 0 1-.31-.114.37.37 0 0 1-.13-.275v-8.539l3.827 3.627ZM23.652 16.362V24.9a.346.346 0 0 1-.034.15.391.391 0 0 1-.098.127.464.464 0 0 1-.147.085.51.51 0 0 1-.173.029h-2.924a.49.49 0 0 1-.317-.115.368.368 0 0 1-.133-.276v-4.905l3.826-3.632Z'
          fill='#BDDEE1'
        />
      </g>
      <defs>
        <linearGradient
          id='a'
          x1={16}
          y1={10}
          x2={16}
          y2={32}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#005E86' stopOpacity={0} />
          <stop offset={1} stopColor='#006686' />
        </linearGradient>
        <clipPath id='b'>
          <path
            fill='#fff'
            transform='translate(8.348 8.71)'
            d='M0 0h15.304v16.58H0z'
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AllIcon;
