import { paragraphSemiBold } from '../typography';
const AvatarComponentStyle = {
    baseStyle: {
        label: {
            ...paragraphSemiBold(),
            color: 'white',
        },
        container: {
            bg: 'primary400',
        },
    },
};
export default AvatarComponentStyle;
