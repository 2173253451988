export var NewsCategory;
(function (NewsCategory) {
    NewsCategory[NewsCategory["Home"] = 1] = "Home";
    NewsCategory[NewsCategory["Professional"] = 2] = "Professional";
    NewsCategory[NewsCategory["Family"] = 3] = "Family";
    NewsCategory[NewsCategory["Health"] = 4] = "Health";
    NewsCategory[NewsCategory["PersonalData"] = 5] = "PersonalData";
    NewsCategory[NewsCategory["GoodToKnow"] = 6] = "GoodToKnow";
})(NewsCategory || (NewsCategory = {}));
export const newsCategories = [
    {
        id: NewsCategory.Home,
        name: 'newsFeed.categories.myHome',
        color100: '#FAD4D4',
        color200: '#E72A2A',
        color300: '#B70B0B',
    },
    {
        id: NewsCategory.Professional,
        name: 'newsFeed.categories.myProfessionalLife',
        color100: '#D7DAF7',
        color200: '#606DE4',
        color300: '#03139C',
    },
    {
        id: NewsCategory.Family,
        name: 'newsFeed.categories.myFamily',
        color100: '#F1D7F7',
        color200: '#AF59C4',
        color300: '#7E039C',
    },
    {
        id: NewsCategory.Health,
        name: 'newsFeed.categories.myHealth',
        color100: '#CCF0E8',
        color200: '#29C19D',
        color300: '#038164',
    },
    {
        id: NewsCategory.PersonalData,
        name: 'newsFeed.categories.myPersonalData',
        color100: '#FCE9D4',
        color200: '#EE9226',
        color300: '#A04600',
    },
    {
        id: NewsCategory.GoodToKnow,
        name: 'newsFeed.categories.goodToKnow',
        color100: '#F9E9BE',
        color200: '#EFB100',
        color300: '#965808',
    },
];
export const shouldShowNews = (currentLanguage) => ['nl', 'fr'].includes(currentLanguage);
