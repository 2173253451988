class NotaryCertificationApi {
    fetchService;
    baseUrl = `/notary/certification`;
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getActivationToken() {
        return this.fetchService.get(`${this.baseUrl}/plugin/token`);
    }
    getHashToSign({ sessionId }) {
        return this.fetchService.get(`${this.baseUrl}/signature/sessions/${sessionId}/hashToSign`);
    }
    initiateSigningFlow(params) {
        return this.fetchService.post(`${this.baseUrl}/signature/sessions`, params, undefined, { resAsBlob: true, withResponseHeaders: true });
    }
    validateCertificate(sessionId, params) {
        return this.fetchService.post(`${this.baseUrl}/signature/sessions/${sessionId}/certificateValidation`, params);
    }
    sign(sessionId, params) {
        return this.fetchService.post(`${this.baseUrl}/signature/sessions/${sessionId}/sign`, params);
    }
}
export default NotaryCertificationApi;
