import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { init, reactRouterV6BrowserTracingIntegration } from '@sentry/react';

import { appEnv } from '@izimi/core';

if (import.meta.env.PROD) {
  init({
    dsn: 'https://ce5dcca0f95f408e89cb8594a73e56ee@o548562.ingest.sentry.io/6391934',
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    environment: appEnv,
  });
}
