import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import { differenceInDays, differenceInWeeks, format, formatDistance, formatRelative, } from 'date-fns';
import { useDateLocale } from '@izimi/hooks';
export const NotificationTime = ({ timestamp }) => {
    const { t, i18n } = useTranslation();
    const locale = useDateLocale(i18n.language);
    const today = new Date();
    const time = new Date(timestamp);
    let notificationTime;
    if (differenceInDays(today, time) === 0) {
        notificationTime = formatDistance(time, today, {
            addSuffix: true,
            locale,
        });
    }
    else if (differenceInWeeks(today, time) === 0) {
        notificationTime = formatRelative(time, today, {
            locale,
        });
    }
    else {
        notificationTime = `${format(time, 'dd/MM/yyyy')} ${t('shared:date.at')} ${format(time, 'HH:mm')}`;
    }
    return (_jsx(Text, { variant: 'smallSemibold', color: 'neutral400', as: 'time', dateTime: `${format(time, 'dd/MM/yyyy')} - ${format(time, 'HH:mm')}`, children: notificationTime }));
};
