import { useCallback, useMemo } from 'react';
import useQueryParams from './useQueryParams';
export const useDocumentMultiSelect = () => {
    const { params, updateQueryParams } = useQueryParams();
    const selectedDocuments = useMemo(() => {
        const ids = params.id?.split(',') ?? [];
        return ids.reduce((acc, curr) => {
            acc[curr] = true;
            return acc;
        }, {});
    }, [params.id]);
    const handleDocumentSelection = useCallback((selection) => {
        if (typeof selection === 'string') {
            const isAlreadySelected = Object.keys(selectedDocuments).includes(selection);
            if (isAlreadySelected) {
                return undefined;
            }
            return updateQueryParams([{ key: 'id', value: selection }]);
        }
        return updateQueryParams([
            { key: 'id', value: Object.keys(selection).join(',') },
        ]);
    }, [updateQueryParams, selectedDocuments]);
    const isSelected = (id) => Object.keys(selectedDocuments).includes(id);
    const numberOfDocumentsSelected = Object.keys(selectedDocuments).length;
    const hasMultipleSelected = numberOfDocumentsSelected > 1;
    const clearDocumentSelection = useCallback(() => {
        updateQueryParams([{ key: 'id', value: null }]);
    }, [updateQueryParams]);
    return {
        handleDocumentSelection,
        clearDocumentSelection,
        selectedDocuments,
        selectedDocumentIds: Object.keys(selectedDocuments),
        hasMultipleSelected,
        numberOfDocumentsSelected,
        isSelected,
    };
};
