import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
const ModulesContext = createContext(null);
export const useModules = () => {
    const context = useContext(ModulesContext);
    if (!context) {
        throw new Error('Did you forget to wrap with ModulesProvider?');
    }
    return context;
};
export const ModulesProvider = ({ children, modules, }) => {
    return (_jsx(ModulesContext.Provider, { value: modules, children: children }));
};
