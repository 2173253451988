import { ApiDocumentSource } from '@izimi/api';
import { createNabanModule, useModules } from '@izimi/modules';
import { documentsApi, nabanApi } from 'core/di';

const hooks = createNabanModule({ nabanApi, documentsApi });

const useLinkedNabanDocuments = ({ enabled = true } = {}) => {
  const {
    documents: { usePagedDocuments },
  } = useModules();
  const { documents, isLoading, error } = usePagedDocuments({
    enabled,
    type: 'nabanList',
    params: { sources: ApiDocumentSource.Naban },
  });

  return {
    documents,
    isLoading,
    error,
  };
};

export const nabanModule = {
  ...hooks,
  useLinkedNabanDocuments,
};

export type NabanModule = typeof nabanModule;
