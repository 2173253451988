import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, isValidElement } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, chakra, Flex, MenuItem, Text } from '@chakra-ui/react';
import { Dot } from '../Dot/Dot';
import { Link } from '../Link/Link';
const NavigationItem = ({ label, to = '#', icon, type = 'navbarItem', badge = 0, ...rest }) => {
    const { pathname } = useLocation();
    const isActive = pathname === to || pathname.includes(`${to}/`);
    const shouldDisplayBadge = badge > 0;
    const iconWithColors = isValidElement(icon)
        ? cloneElement(icon, {
            color: isActive ? 'primary200' : 'neutral200',
            secondaryColor: isActive ? 'primary500' : 'neutral400',
            solid: isActive || type !== 'navbarItem',
        })
        : undefined;
    if (type === 'menuItem') {
        return (_jsx(Link, { to: to, variant: 'heading6', activeStyle: { color: 'primary500' }, ...rest, children: _jsx(MenuItem, { icon: iconWithColors, children: label }) }));
    }
    if (type === 'mobile') {
        return (_jsx(Link, { to: to, variant: 'heading6', activeStyle: { color: 'primary500' }, ...rest, children: _jsxs(Flex, { align: 'center', children: [iconWithColors, _jsx(Box, { as: 'span', ml: 4, children: label })] }) }));
    }
    return (_jsxs(Link, { to: to, display: 'flex', flexDirection: 'column', py: 1, mt: 0, justifyContent: 'center', alignItems: 'center', textAlign: 'center', gap: 1, color: 'neutral400', activeStyle: {
            color: 'primary500',
            borderLeftColor: 'primary400',
        }, pos: 'relative', borderWidth: '4px', borderColor: 'transparent', _hover: {
            transition: 'all 0.2s ease-in-out',
            color: 'primary500',
            borderLeftColor: 'primary400',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
        }, tabIndex: 0, title: label, ...rest, children: [_jsx(Dot, { variant: 'secondary', display: shouldDisplayBadge ? 'flex' : 'none', px: 1, pos: 'absolute', right: badge > 9 ? '30%' : '35%', bottom: '45%', children: _jsx(Text, { variant: 'badge', children: badge > 99 ? '99+' : badge }) }), iconWithColors, _jsx(Text, { noOfLines: 1, maxW: '100%', variant: 'smallSemibold', children: label })] }));
};
export default chakra(NavigationItem);
