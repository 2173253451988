export enum Routes {
  Hash = '#',
  Login = '/',
  Home = '/home',
  Vault = '/vault',
  VaultPreview = '/vault/preview',
  VaultSearch = '/vault/search',
  Contacts = '/contacts',
  Sharing = '/sharing',
  SharingFromContacts = '/sharing/from-contacts',
  SharingByYou = '/sharing/by-you',
  Registration = '/registration',
  Profile = '/profile',
  Deactivation = '/profile/deactivate',
  DeceasedUser = '/profile/deceased',
  Connections = '/profile/connections',
  EmailNotifications = '/profile/email-notifications',
  Debug = '/debug',
  Trash = '/trash',
  HistoryLog = '/history-log',
  HelpInfo = '/help/info',
  HelpFeedback = '/help/feedback',
  HelpReportProblem = '/help/report-problem',
  About = '/about',
  TermsOfUse = '/about/terms-of-use',
  Services = '/services',
  VaultTransferOverview = '/services/vault-transfer-overview',
  VaultTransferPreferences = '/services/vault-transfer-preferences',
  Naban = '/services/naban',
  NabanPreview = '/services/naban/preview',
  CertificationInfo = '/services/certification',
  NewsDetail = '/news/:type/:id',
  Itsme = '/mobile/redirect/itsme',
  Unsubscribe = '/unsubscribe',
}

export const PUBLIC_ROUTES = [Routes.Login, Routes.DeceasedUser] as const;
