export var EventCategory;
(function (EventCategory) {
    EventCategory["Filtering"] = "Filtering";
    EventCategory["Uploading"] = "Uploading";
    EventCategory["Sharing"] = "Sharing";
    EventCategory["Vault"] = "Vault";
    EventCategory["VaultTransfer"] = "Vault Transfer";
    EventCategory["Links"] = "Links";
    EventCategory["Notifications"] = "Notifications";
    EventCategory["Contacts"] = "Contacts";
    EventCategory["NabanActs"] = "Naban Acts";
    EventCategory["Profile"] = "Profile";
    EventCategory["Onboarding"] = "Onboarding";
    EventCategory["Inheritance"] = "Inheritance";
    EventCategory["Home"] = "Home";
    EventCategory["CertificationSupportingDocuments"] = "Certification Supporting Documents";
    EventCategory["CertificationNotary"] = "Certification Notary";
    EventCategory["PartnerConnections"] = "Partner Connections";
    EventCategory["InheritedVault"] = "Inherited Vault";
    EventCategory["InheritedDocuments"] = "Inherited Documents";
})(EventCategory || (EventCategory = {}));
