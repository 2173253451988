import { buildQueryString } from '@izimi/utils';
class NotificationsApi {
    fetchService;
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getNotifications({ page, pageSize, from }) {
        const qs = buildQueryString({ from, page, pageSize });
        return this.fetchService.get(`/events/notifications${qs}`);
    }
    deleteNotification(notificationId) {
        return this.fetchService.delete(`/notifications/${notificationId}`);
    }
    markNotificationsAsViewed(notificationIds) {
        return this.fetchService.put('/notifications/view', notificationIds);
    }
}
export default NotificationsApi;
