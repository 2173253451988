import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Menu, MenuButton, MenuList, Portal } from '@chakra-ui/react';
import CustomIcon from '../icon/CustomIcon';
import NavigationItem from './NavigationItem';
const NavigationUserMenu = ({ items, userName, renderUserMenuHeader, }) => {
    return (_jsxs(Menu, { isLazy: true, placement: 'right-end', offset: [0, -10], children: [_jsx(MenuButton, { display: { base: 'none', md: 'inline-flex' }, children: _jsx(Avatar, { name: userName }) }), _jsx(Portal, { children: _jsxs(MenuList, { rootProps: {
                        top: 0,
                        left: 0,
                    }, children: [renderUserMenuHeader, items.map(item => (_jsx(NavigationItem, { type: 'menuItem', to: item.href, label: item.label, icon: _jsx(CustomIcon, { name: item.customIconName }), onClick: item.onClick }, item.label)))] }) })] }));
};
export default NavigationUserMenu;
