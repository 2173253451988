class NabanQueryKeys {
    prefix;
    constructor(prefix) {
        this.prefix = prefix;
    }
    getNabanDocuments() {
        return [this.prefix];
    }
    getNabanDocument(nabanId) {
        return [this.prefix, { nabanId }];
    }
    downloadNabanDocument(nabanId) {
        return [this.prefix, { nabanId, download: true }];
    }
}
export default NabanQueryKeys;
