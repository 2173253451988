import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { chakra } from '@chakra-ui/react';
const Colgroup = chakra('colgroup');
const Col = chakra('col');
const variants = {
    notary: (_jsxs(_Fragment, { children: [_jsx(Col, { width: '225px', display: { base: 'none', md: 'revert' } }), _jsx(Col, { width: '150px', display: { base: 'none', md: 'revert' } })] })),
    citizen: null,
};
const TrashDocumentsTableColGroup = ({ variant }) => (_jsxs(Colgroup, { children: [_jsx(Col, { width: '50px', display: { base: 'none', md: 'revert' } }), _jsx(Col, { width: '100%' }), variants[variant], _jsx(Col, { width: '80px' })] }));
export default TrashDocumentsTableColGroup;
