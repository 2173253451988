import { ApiNotificationType } from '@izimi/api';
/**
 * Generics used to type the icons of the notification types.
 */
export function getNotificationItems() {
    return notificationItems;
}
const notificationItems = {
    [ApiNotificationType.DocumentSuccessfullyUploaded]: {
        icon: {
            name: 'arrow-up-circle',
        },
        messageKey: 'notifications:documentUploaded.message',
        messageValues: notification => ({
            documentName: notification.data.documentName,
        }),
    },
    [ApiNotificationType.DocumentUploadFailedDueToVirus]: {
        icon: {
            name: 'frown',
            color: 'secondary200',
            secondaryColor: 'secondary400',
            size: 24,
        },
        messageKey: 'notifications:documentUploadFailedDueToAntivirus.message',
        messageValues: notification => ({
            documentName: notification.data.documentName,
        }),
    },
    [ApiNotificationType.EmailReused]: {
        icon: {
            name: 'mail',
            size: 24,
        },
        messageKey: 'notifications:emailReused.message',
        messageValues: notification => ({
            name: notification.data.name,
            email: notification.data.email,
        }),
    },
    [ApiNotificationType.SharedDocumentsReceived]: {
        icon: {
            name: 'user-share',
        },
        messageKey: 'notifications:acceptSharedDocs.message',
        messageValues: notification => ({
            documentOwnerName: notification.data.documentOwnerName,
            numberOfDocumentsShared: notification.data.documentIds.length.toString(),
        }),
    },
    [ApiNotificationType.SharedDocumentsAccepted]: {
        icon: {
            name: 'user-share',
        },
        messageKey: 'notifications:sharedDocumentsAccepted.message',
        messageValues: notification => ({
            recipientName: notification.data.recipientName,
            numberOfDocumentsAccepted: notification.data.numberOfDocumentsAccepted.toString(),
            numberOfDocumentsRejected: notification.data.numberOfDocumentsRejected.toString(),
        }),
    },
    [ApiNotificationType.ContactRequestAccepted]: {
        icon: {
            name: 'user-add',
        },
        messageKey: 'notifications:contactAccepted.message',
        messageValues: notification => ({
            contactName: notification.data.contactName,
        }),
    },
    [ApiNotificationType.SharedDocumentUnshared]: {
        icon: {
            name: 'user-share-declined',
        },
        messageKey: 'notifications:documentUnshared.message',
        messageValues: notification => ({
            documentName: notification.data.documentName,
        }),
    },
    [ApiNotificationType.ContactRemoveReceived]: {
        icon: {
            name: 'user-minus',
        },
        messageKey: 'notifications:contactRemove.message',
        messageValues: notification => ({
            contactName: notification.data.contactName,
        }),
    },
    [ApiNotificationType.SharedDocumentUnsharedByRecipient]: {
        icon: {
            name: 'user-share-declined',
        },
        messageKey: 'notifications:sharedDocumentRemovedByRecipient.message',
        messageValues: notification => ({
            documentName: notification.data.documentName,
        }),
    },
    [ApiNotificationType.ContactRequestReceived]: {
        icon: {
            name: 'user-add',
        },
        messageKey: 'notifications:addContact.message',
        messageValues: notification => ({
            contactName: notification.data.contactName,
        }),
    },
    [ApiNotificationType.SharedDocumentByNotaryCitizen]: {
        icon: {
            name: 'user-share',
        },
        messageKey: 'notifications:sharedDocumentByNotaryCitizen.message',
        messageValues: notification => ({
            documentName: notification.data.documentName,
            studyName: notification.data.studyName,
        }),
    },
    [ApiNotificationType.SharedDocumentByNotaryCitizenFailed]: {
        icon: {
            name: 'frown',
        },
        messageKey: 'notifications:sharedDocumentByNotaryCitizenFailed.message',
        messageValues: notification => ({
            studyName: notification.data.employeeName,
        }),
    },
    [ApiNotificationType.DocumentsToBeRemovedAfterDeath]: {
        icon: {
            name: 'vault',
            solid: true,
        },
        messageKey: 'notifications:documentsToBeRemovedAfterDeath.message',
        messageValues: notification => ({
            user: notification.data.deceasedName,
            date: notification.data.removalDate,
        }),
    },
    [ApiNotificationType.DocumentUploadedByThirdParty]: {
        icon: {
            name: 'link',
        },
        messageKey: 'notifications:documentUploadedByThirdParty.message',
        messageValues: notification => ({
            documentName: notification.data.documentName,
            thirdPartyName: notification.data.thirdPartyName,
        }),
    },
    [ApiNotificationType.VaultTransferReceived]: {
        icon: {
            name: 'vault',
            solid: true,
        },
        messageKey: 'notifications:vaultTransferReceived.message',
        messageValues: notification => ({
            deceasedName: notification.data.deceasedName,
        }),
    },
    [ApiNotificationType.ExpiringInheritedVault]: {
        icon: {
            name: 'vault',
            solid: true,
        },
        messageKey: 'notifications:expiringInheritedVault.message',
        messageValues: notification => ({
            deceasedName: notification.data.deceasedName,
            expiryDate: notification.data.expiryDate,
        }),
    },
    [ApiNotificationType.PushedDocumentsReceived]: {
        icon: {
            name: 'user-share',
        },
        messageKey: 'notifications:pushDocumentsReceived.message',
        messageValues: notification => ({
            pusherName: notification.data.pusherName,
            documentName: notification.data.documentNames[0],
            count: notification.data.documentIds.length,
            andOthers: (notification.data.documentIds.length - 1).toString(),
        }),
    },
    [ApiNotificationType.PushedCertifiedDocumentReceived]: {
        icon: {
            name: 'push',
        },
        messageKey: 'notifications:pushCertifiedDocumentReceived.message',
        messageValues: notification => ({
            documentName: notification.data.documentName,
            pusherName: notification.data.pusherName,
        }),
    },
    [ApiNotificationType.NotaryDocumentsReceived]: {
        icon: {
            name: 'user-share',
            solid: true,
        },
        messageKey: 'notifications:notaryDocumentsReceived.message',
        messageValues: notification => ({
            documentOwnerName: notification.data.documentOwnerName,
            documentName: notification.data.documentNames[0],
            count: notification.data.documentNames.length,
            andOthers: (notification.data.documentNames.length - 1).toString(),
        }),
    },
    [ApiNotificationType.PushDocumentsAccepted]: {
        icon: {
            name: 'push',
        },
        messageKey: 'notifications:pushDocumentsAccepted.message',
        messageValues: notification => ({
            name: notification.data.documentReceiverName,
            numberOfDocumentsAccepted: notification.data.numberOfDocumentsAccepted,
            numberOfDocumentsRejected: notification.data.numberOfDocumentsRejected,
        }),
    },
    [ApiNotificationType.NotaryContactRemoved]: {
        icon: {
            name: 'user-minus',
        },
        messageKey: 'notifications:contactRemove.message',
        messageValues: notification => ({
            contactName: notification.data.contactName,
        }),
    },
    [ApiNotificationType.NotaryContactAdded]: {
        icon: {
            name: 'user-add',
        },
        messageKey: 'notifications:notaryContactAdded.message',
        messageValues: notification => ({
            contactName: notification.data.contactName,
        }),
    },
    [ApiNotificationType.StudyContactAdded]: {
        icon: {
            name: 'user-add',
        },
        messageKey: 'notifications:notaryContactAdded.message',
        messageValues: notification => ({
            contactName: notification.data.contactName,
        }),
    },
    [ApiNotificationType.NotaryDocumentUnshared]: {
        icon: {
            name: 'user-share-declined',
        },
        messageKey: 'notifications:notaryDocumentUnshared.message',
        messageValues: notification => ({
            documentName: notification.data.documentName,
            name: notification.data.documentOwnerName,
        }),
    },
    [ApiNotificationType.StudyPushedDocumentsReceived]: {
        icon: {
            name: 'push',
        },
        messageKey: 'notifications:pushDocumentsReceived.message',
        messageValues: notification => ({
            pusherName: notification.data.pusherName,
            documentName: notification.data.documentNames[0],
            count: notification.data.documentIds.length,
            andOthers: (notification.data.documentIds.length - 1).toString(),
        }),
    },
    [ApiNotificationType.NotaryAutomaticallyClaimedVault]: {
        icon: {
            name: 'vault',
            solid: true,
        },
        messageKey: 'notifications:notaryAutomaticallyClaimedVault.message',
        messageValues: notification => ({
            vaultName: notification.data.vaultOwnerName,
        }),
    },
    [ApiNotificationType.NotaryPassedClaimedVaultReceived]: {
        icon: {
            name: 'vault',
            solid: true,
        },
        messageKey: 'notifications:notaryPassedClaimedVaultReceived.message',
        messageValues: notification => ({
            notaryName: notification.data.studyName,
            vaultName: notification.data.vaultOwnerName,
        }),
    },
};
