import { useMemo } from 'react';
const useFontSize = () => useMemo(() => {
    if (typeof window !== 'undefined') {
        const element = document.createElement('div');
        element.style.width = '1rem';
        element.style.display = 'none';
        document.body.append(element);
        const widthMatch = window
            .getComputedStyle(element)
            .getPropertyValue('width')
            .match(/\d+/);
        element.remove();
        if (!widthMatch || widthMatch.length < 1) {
            return null;
        }
        const result = Number(widthMatch[0]);
        return !Number.isNaN(result) ? result : null;
    }
    return 16;
}, []);
export default useFontSize;
