import { buildQueryString } from '@izimi/utils';
class HistoryLogApi {
    fetchService;
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getHistoryLogsV3(params) {
        const qs = buildQueryString(params);
        return this.fetchService.get(`/v3/history${qs}`);
    }
}
export default HistoryLogApi;
