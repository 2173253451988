import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from 'auth/helpers';
import type { MotionConfigProps } from 'framer-motion';
import { MotionConfig } from 'framer-motion';
import i18next from 'i18next';

import {
  DocumentFiltersProvider,
  ErrorBoundary,
  ModalsRoot,
} from '@izimi/components';
import { useWatchAppHeight } from '@izimi/hooks';
import { ModulesProvider } from '@izimi/modules';
import theme, { Fonts } from '@izimi/theme';
import { modalEntities } from 'common/components/modals/modalEntities';
import { CitizenTrackingProvider } from 'common/components/providers';
import { CookieProvider } from 'core/tracking/cookies/CookieProvider';
import { Routes } from 'navigation/types';

import { modules } from '../core/modules';
import CustomRouter from './CustomRouter';
import Router from './Router';
import '@izimi/components/src/tracking/cookies/styles.css';
import '@izimi/components/src/DatePicker/chakra-react-datepicker.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const motionConfig: MotionConfigProps = {
  reducedMotion: 'user',
  transition: { type: 'tween', duration: 0.15 },
};

// Must be loaded in for the document detail components, otherwise causes re-render due to "lazy loading" the translation file(s)
i18next.loadNamespaces('common');

const App = () => {
  useWatchAppHeight();
  const { t } = useTranslation('errors');

  return (
    <Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <CustomRouter>
            <Fonts />
            <ErrorBoundary t={t} rerouteTo={Routes.Home}>
              <ModulesProvider modules={modules}>
                <AuthProvider>
                  <CookieProvider>
                    <MotionConfig {...motionConfig}>
                      <DocumentFiltersProvider>
                        <CitizenTrackingProvider>
                          <Suspense>
                            <ModalsRoot entities={modalEntities} />
                          </Suspense>
                          <Router />
                        </CitizenTrackingProvider>
                      </DocumentFiltersProvider>
                    </MotionConfig>
                  </CookieProvider>
                </AuthProvider>
              </ModulesProvider>
            </ErrorBoundary>
          </CustomRouter>
        </ChakraProvider>
      </QueryClientProvider>
    </Suspense>
  );
};

export default App;
