class NotaryQueryKeys {
    prefix;
    constructor(prefix) {
        this.prefix = prefix;
    }
    getPreferredNotary() {
        return [this.prefix, 'preferred'];
    }
    getNotaries(queryParams) {
        return [this.prefix, 'notaries', queryParams];
    }
    getNotary(notaryId) {
        return [this.prefix, 'notary', notaryId];
    }
    getNotaryClaimedVault(nrn) {
        return [this.prefix, 'notary', 'claimed-vault', nrn];
    }
    getNotaryClaimedVaults(params) {
        return [this.prefix, 'notary', 'claimed-vaults', params];
    }
    getClaimedVaultHistory(claimedVaultId) {
        return [this.prefix, 'notary', 'claimed-vault', 'history', claimedVaultId];
    }
    claimableVault(nrn) {
        return [this.prefix, 'notary', 'claimable-vault', nrn];
    }
    getNotaryHeirs(params) {
        return [this.prefix, 'notary', 'heirs', params];
    }
    getVaultOwner(params) {
        return [this.prefix, 'notary', 'vault-owner', params];
    }
    getNotaryContacts(params) {
        return [this.prefix, 'notary', 'contacts', params];
    }
    getNotaryContact(contactId) {
        return [this.prefix, 'notary', 'contact', contactId];
    }
    getNotaryDocuments(params) {
        return [this.prefix, 'notary', 'documents', params];
    }
    getNotaryDocument(documentId) {
        return [this.prefix, 'notary', 'document', documentId];
    }
    getNotaryMailConfiguration() {
        return [this.prefix, 'notary', 'mail-configuration'];
    }
}
export default NotaryQueryKeys;
