import { buildQueryString } from '@izimi/utils';
class PublicApi {
    fetchService;
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getConfiguration() {
        return this.fetchService.get('/public/configuration/citizen-mobile');
    }
    getWebConfiguration() {
        return this.fetchService.get('/public/configuration/citizen-web');
    }
    getNotaryConfiguration() {
        return this.fetchService.get('/public/configuration/notary-web');
    }
    getMailUnsubscribeInfo(token) {
        const query = buildQueryString({ token });
        return this.fetchService.get(`/public/mail/unsubscribe/info${query}`);
    }
    mailUnsubscribe(token) {
        return this.fetchService.post(`/public/mail/unsubscribe`, { token });
    }
}
export default PublicApi;
