import type { MessagesType } from '@izimi/modules';
import { documentsMessages } from '@izimi/modules';

const messages = {
  ...documentsMessages,
  downloadDocument(fileName: string): MessagesType {
    return {
      errorMessage: {
        message: [
          'messages.documents.downloadDocumentFailed',
          {
            fileName,
          },
        ],
      },
    };
  },
};

export default messages;
