class UserApi {
    fetchService;
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getCurrentUser() {
        return this.fetchService.get('/user');
    }
    registerUser(values) {
        return this.fetchService.post('/user/register', values);
    }
    updateUser(values) {
        return this.fetchService.put('/user', values);
    }
    markDeceased(values) {
        return this.fetchService.post('/public/mark-deceased', {
            nrn: values.nrn,
            deceasedNotificationDate: values.deceasedNotificationDate,
            deceasedDate: values.deceasedDate,
        });
    }
    unmarkDeceased(nrn) {
        return this.fetchService.post('/public/unmark-deceased', {
            nrn,
        });
    }
    startDeactivation() {
        return this.fetchService.post('/user/initiate-deactivation');
    }
    completeDeactivation({ deactivationCode, }) {
        return this.fetchService.post(`/user/finalize-deactivation/${deactivationCode}`);
    }
    cancelDeactivation() {
        return this.fetchService.post('/user/cancel-deactivation');
    }
    getUserPreferences() {
        return this.fetchService.get('/user/preferences');
    }
    setUserPreferences(preferences) {
        return this.fetchService.put('/user/preferences', preferences);
    }
    getUserMailConfiguration() {
        return this.fetchService.get('/user/mail-configuration');
    }
    setUserMailConfiguration(mailConfiguration) {
        return this.fetchService.put('/user/mail-configuration', mailConfiguration);
    }
}
export default UserApi;
