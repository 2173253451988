export var ApiRole;
(function (ApiRole) {
    ApiRole["Citizen"] = "CITIZEN";
    ApiRole["Notary"] = "NOTARY";
    ApiRole["Collaborator"] = "COLLABORATOR";
})(ApiRole || (ApiRole = {}));
export var ApiAccessLevel;
(function (ApiAccessLevel) {
    ApiAccessLevel[ApiAccessLevel["NotRegistered"] = 0] = "NotRegistered";
    ApiAccessLevel[ApiAccessLevel["NotConfirmed"] = 1] = "NotConfirmed";
    ApiAccessLevel[ApiAccessLevel["Confirmed"] = 2] = "Confirmed";
})(ApiAccessLevel || (ApiAccessLevel = {}));
export var ApiPushNotificationsStatus;
(function (ApiPushNotificationsStatus) {
    ApiPushNotificationsStatus["NotDetermined"] = "NOT_DETERMINED";
    ApiPushNotificationsStatus["Authorized"] = "AUTHORIZED";
    ApiPushNotificationsStatus["Denied"] = "DENIED";
})(ApiPushNotificationsStatus || (ApiPushNotificationsStatus = {}));
