import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import ContactsQueryKeys from './ContactsQueryKeys';
export const contactsQueryKeys = new ContactsQueryKeys('contacts');
const invalidateContactsCache = (type) => () => {
    const queryClient = useQueryClient();
    const invalidateContactsKeyCache = useCallback(() => {
        return queryClient.invalidateQueries({
            queryKey: contactsQueryKeys.getContacts(type),
        });
    }, [queryClient]);
    return invalidateContactsKeyCache;
};
export const useInvalidateContactListCache = invalidateContactsCache('contactList');
export const useInvalidateContactRequestsCache = invalidateContactsCache('contactRequests');
