import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import VaultQueryKeys from './VaultQueryKeys';
export const vaultQueryKeys = new VaultQueryKeys('vault');
export const useSetVaultPreferencesCache = () => {
    const queryClient = useQueryClient();
    const setVaultPreferencesCache = useCallback((preferences) => queryClient.setQueryData(vaultQueryKeys.getPreferences(), preferences), [queryClient]);
    return setVaultPreferencesCache;
};
export const useInvalidateVaultPreferencesCache = () => {
    const queryClient = useQueryClient();
    const invalidateVaultPreferencesCache = useCallback(() => queryClient.invalidateQueries({
        queryKey: vaultQueryKeys.getPreferences(),
    }), [queryClient]);
    return invalidateVaultPreferencesCache;
};
export const useInvalidateVaultInfoCache = () => {
    const queryClient = useQueryClient();
    const invalidateVaultInfoCache = useCallback(() => queryClient.invalidateQueries({
        queryKey: vaultQueryKeys.getVaultInfo(),
        exact: true,
    }), [queryClient]);
    return invalidateVaultInfoCache;
};
