function BusinessIcon({ size = 32 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
    >
      <rect x={2} width={28} height={26} rx={4} fill='#03139C' />
      <rect y={2} width={32} height={30} rx={4} fill='#606DE4' />
      <rect
        y={2}
        width={32}
        height={30}
        rx={4}
        fill='url(#a)'
        fillOpacity={0.2}
        style={{
          mixBlendMode: 'multiply',
        }}
      />
      <rect x={6} y={13} width={20} height={13} rx={2} fill='#03139C' />
      <path d='M6 15a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v4H6v-4Z' fill='#D7DAF7' />
      <path d='M6 15a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v4H6v-4Z' fill='url(#b)' />
      <rect x={14} y={18} width={4} height={2} rx={1} fill='#606DE4' />
      <path
        d='M12 13v-1a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1'
        stroke='#03139C'
        strokeWidth={1.5}
      />
      <path opacity={0.45} fill='url(#c)' d='M15 13h2v5h-2z' />
      <defs>
        <linearGradient
          id='a'
          x1={16}
          y1={10}
          x2={16}
          y2={32}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#350D89' stopOpacity={0} />
          <stop offset={1} stopColor='#350D89' />
        </linearGradient>
        <linearGradient
          id='b'
          x1={16}
          y1={13}
          x2={16}
          y2={17}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id='c'
          x1={16}
          y1={13}
          x2={16}
          y2={18}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#03139C' />
          <stop offset={1} stopColor='#03139C' stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default BusinessIcon;
