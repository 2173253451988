import { useCallback } from 'react';
import { documentsMessages, Tracker, useModules } from '@izimi/modules';
import { withMessages } from '../../messages';
import { useModalStore } from '../../modals';
import { useTracking } from '../../tracking';
export const useDocumentTrashOptions = () => {
    const { showModal } = useModalStore();
    const { documents: { useUnTrashDocuments, useDocumentExtendTrashDate }, } = useModules();
    const { unTrashDocuments, isPending } = useUnTrashDocuments();
    const { documentExtendTrashDate } = useDocumentExtendTrashDate();
    const { trackEvent } = useTracking();
    const handlePermanentlyDeleteDocument = useCallback((document) => {
        showModal({
            type: 'confirmTrashDocument',
            document,
        });
    }, [showModal]);
    const handleRestoreDocument = useCallback(async (document) => {
        trackEvent({
            category: Tracker.Category.Vault,
            action: Tracker.Action.RecoveredDocument,
            value: 1,
        });
        const restore = async () => {
            await unTrashDocuments([document.id]);
            if (document.isCloseToDeletion) {
                await documentExtendTrashDate([document.id]);
            }
        };
        await withMessages(restore())(documentsMessages.unTrashDocument(1));
    }, [trackEvent, unTrashDocuments, documentExtendTrashDate]);
    return {
        handlePermanentlyDeleteDocument,
        handleRestoreDocument,
        isPending,
    };
};
