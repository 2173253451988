import { formatBytes } from '@izimi/utils';
class VaultInfoEntity {
    constructor(vaultInfo) {
        Object.assign(this, vaultInfo);
    }
    vaultId;
    maxSize;
    vaultSize;
    trashSize;
    documentCount;
    inheritedVaults;
    get isVaultFull() {
        return this.vaultSize + this.trashSize >= this.maxSize;
    }
    get usedSizeFormatted() {
        return formatBytes(this.vaultSize + this.trashSize);
    }
    get maxSizeFormatted() {
        return formatBytes(this.maxSize);
    }
    get remainingSizePercentage() {
        const used = (this.vaultSize + this.trashSize) / this.maxSize;
        return Math.round((1 - used) * 100);
    }
    get usedSizePercentage() {
        const used = (this.vaultSize + this.trashSize) / this.maxSize;
        return Math.round(used * 100);
    }
    get usedVaultSizePercentage() {
        const used = this.vaultSize / this.maxSize;
        return Math.round(used * 100);
    }
    get usedTrashSizePercentage() {
        const used = this.trashSize / this.maxSize;
        return Math.round(used * 100);
    }
}
export default VaultInfoEntity;
