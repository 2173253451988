import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, IconButton, Table, Tbody, Text, } from '@chakra-ui/react';
import { useDocumentUploadEntities, useResetDocumentUploadEntities, } from '@izimi/core';
import { useModules } from '@izimi/modules';
import { FeatherIcon } from '../icon';
import { useModalStore } from '../modals';
import { Spinner } from '../Spinner';
import { DocumentTableRowUpload } from '../Table';
export const UploadZone = () => {
    const { vault: { useVaultInfo }, } = useModules();
    const { t } = useTranslation('shared');
    const { vaultInfo } = useVaultInfo();
    const documentUploadEntities = useDocumentUploadEntities();
    const resetDocumentUploadEntities = useResetDocumentUploadEntities();
    const { showModal } = useModalStore();
    const abortionConfirmation = () => showModal({ type: 'abortUpload' });
    const noOfUploads = documentUploadEntities.length;
    const noOfUploadsComplete = documentUploadEntities.filter(e => e.uploadSuccess === true).length;
    const noOfErrors = documentUploadEntities.filter(e => e.isValid === false || e.uploadAborted === true).length;
    const canCloseWithoutWarning = noOfUploads === noOfUploadsComplete + noOfErrors;
    const icon = useMemo(() => {
        if (noOfErrors > 0) {
            return _jsx(FeatherIcon, { name: 'alert-octagon', color: 'secondary400' });
        }
        if (noOfUploads > 0 && noOfUploadsComplete === noOfUploads) {
            return _jsx(FeatherIcon, { name: 'check', color: 'primary400' });
        }
        return _jsx(Spinner, {});
    }, [noOfErrors, noOfUploads, noOfUploadsComplete]);
    const titleColor = useMemo(() => {
        if (noOfErrors > 0) {
            return 'secondary600';
        }
        if (noOfUploads > 0 && noOfUploadsComplete === noOfUploads) {
            return 'primary600';
        }
        return 'neutral600';
    }, [noOfErrors, noOfUploads, noOfUploadsComplete]);
    const title = useMemo(() => {
        if (noOfUploads === noOfUploadsComplete) {
            return t('uploadZone.uploadComplete', { count: noOfUploadsComplete });
        }
        if (noOfErrors > 0 && noOfErrors === noOfUploads) {
            return t('uploadZone.uploadFailed');
        }
        if (noOfErrors > 0 && noOfErrors + noOfUploadsComplete === noOfUploads) {
            return t('uploadZone.partiallyUploaded');
        }
        return t('uploadZone.uploading', {
            count: noOfUploads - noOfErrors - noOfUploadsComplete,
        });
    }, [noOfErrors, noOfUploads, noOfUploadsComplete, t]);
    if (noOfUploads === 0) {
        return null;
    }
    return (_jsx(Box, { bg: 'white', borderRadius: 'lg', boxShadow: 'card', children: _jsx(Accordion, { allowToggle: true, defaultIndex: 0, children: _jsx(AccordionItem, { children: ({ isExpanded }) => (_jsxs(_Fragment, { children: [_jsxs(Flex, { bg: titleColor, borderTopRadius: 'lg', borderBottomRadius: isExpanded ? 'none' : 'lg', py: 1, children: [_jsxs(AccordionButton, { _hover: { background: 'initial' }, _focus: { background: 'initial' }, pr: 0, children: [icon, _jsx(Text, { as: 'span', flex: '1', textAlign: 'left', variant: 'label', color: 'white', ml: 3, children: title })] }), _jsx(IconButton, { icon: _jsx(FeatherIcon, { name: 'x', color: 'white' }), "aria-label": t('action.close'), onClick: canCloseWithoutWarning
                                        ? resetDocumentUploadEntities
                                        : abortionConfirmation })] }), _jsx(AccordionPanel, { maxH: 'md', overflow: 'auto', py: 2, children: _jsx(Table, { variant: 'simple', children: _jsx(Tbody, { children: documentUploadEntities.length > 0 &&
                                        documentUploadEntities.map(documentUploadEntity => (_jsx(DocumentTableRowUpload, { documentUploadEntity: documentUploadEntity, maxSizeFormatted: vaultInfo?.maxSizeFormatted }, documentUploadEntity.fileId))) }) }) })] })) }) }) }));
};
