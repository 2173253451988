import { DocumentsApi } from '@izimi/api';
import { buildQueryString } from '@izimi/utils';
class ExtendedDocumentsApi extends DocumentsApi {
    fetchService;
    constructor(fetchService) {
        super(fetchService);
        this.fetchService = fetchService;
    }
    downloadDocument({ documentId }, params) {
        const qs = buildQueryString(params);
        return this.fetchService.get(`${this.baseUrl}/${documentId}/download${qs}`, { resAsBlob: true });
    }
    uploadDocument(values, onProgress, signal) {
        const formData = new FormData();
        formData.append('file', values.file);
        formData.append('documentName', values.file.name);
        formData.append('labels', values.labels.join(','));
        formData.append('shareable', values.isShareable.toString());
        if (values.categories) {
            formData.append('categories', values.categories.join(','));
        }
        return this.fetchService.uploadFile(this.baseUrl, formData, onProgress, { signal });
    }
}
export default ExtendedDocumentsApi;
