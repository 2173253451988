import { useMutation, useQuery } from '@tanstack/react-query';
import PublicQueryKeys from './PublicQueryKeys';
const STALE_30_MINUTES = 1800000;
const publicQueryKeys = new PublicQueryKeys();
const createPublicModule = ({ publicApi }) => {
    const getConfiguration = (fetcher) => () => {
        const { data, isLoading, error, refetch } = useQuery({
            queryKey: publicQueryKeys.getConfiguration(),
            queryFn: fetcher,
            staleTime: STALE_30_MINUTES,
        });
        return {
            configuration: data,
            error,
            isLoading,
            refetch,
        };
    };
    const useWebConfiguration = getConfiguration(publicApi.getWebConfiguration.bind(publicApi));
    const useNotaryConfiguration = getConfiguration(publicApi.getNotaryConfiguration.bind(publicApi));
    const useMobileConfiguration = getConfiguration(publicApi.getConfiguration.bind(publicApi));
    const useMailUnsubscribeInfo = (token) => {
        const { data, error, isLoading } = useQuery({
            queryKey: publicQueryKeys.getMailUnsubscribeInfo(token),
            queryFn: () => publicApi.getMailUnsubscribeInfo(token),
        });
        return {
            data,
            error,
            isLoading,
        };
    };
    const useMailUnsubscribe = () => {
        const { mutateAsync, isPending } = useMutation({
            mutationFn: token => publicApi.mailUnsubscribe(token),
        });
        return {
            unsubscribe: mutateAsync,
            isPending,
        };
    };
    return {
        useWebConfiguration,
        useNotaryConfiguration,
        useMobileConfiguration,
        useMailUnsubscribeInfo,
        useMailUnsubscribe,
    };
};
export default createPublicModule;
