import { useCallback } from 'react';
import format from 'date-fns/format';
import { useLocaleStore } from '@izimi/core';
export const useDateLocale = (language) => {
    const { locale, setLocale } = useLocaleStore();
    setLocale(language);
    return locale;
};
export const useFormatDate = (language) => {
    const locale = useDateLocale(language);
    return useCallback((...args) => {
        return format(args[0], args[1], { locale, ...args[2] });
    }, [locale]);
};
