import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Skeleton, SkeletonCircle, Tbody, Td, Tr, } from '@chakra-ui/react';
import { useActiveDocumentId } from '@izimi/hooks';
const emptyRows = Array.from(Array(20), (_, i) => ({ id: i }));
export const DocumentTableSkeleton = () => {
    const { activeDocumentId } = useActiveDocumentId();
    return (_jsx(Tbody, { children: emptyRows.map(row => {
            return (_jsxs(Tr, { children: [_jsx(Td, {}), _jsx(Td, { children: _jsxs(Flex, { py: 2, flexDirection: 'row', alignItems: 'center', children: [_jsx(SkeletonCircle, { mr: 3, borderRadius: 0 }), _jsx(Skeleton, { height: '10px', width: { base: '100%', md: '80%' } })] }) }), _jsx(Td, { display: activeDocumentId ? 'none' : { base: 'none', lg: 'table-cell' }, children: _jsx(Skeleton, { height: '10px', width: '80%' }) }), _jsx(Td, { display: { base: 'none', xl: 'table-cell' }, children: _jsx(Skeleton, { height: '10px', width: '80%' }) })] }, row.id));
        }) }));
};
