import type { ReactNode } from 'react';

import { CookiesConfig } from '@izimi/core';
import { useConfigurationLinks } from 'core/modules/public';

type Props = {
  children: ReactNode;
};

export const CookieProvider = ({ children }: Props) => {
  const { cookieUrl } = useConfigurationLinks();

  return <CookiesConfig cookieUrl={cookieUrl}>{children}</CookiesConfig>;
};
