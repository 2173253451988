import { lazy } from 'react';

export const modalEntities = {
  changeAddress: lazy(() => import('./ChangeAddressModal')),
  deactivateAccount: lazy(() => import('./DeactivateAccountModal')),
  editDocumentCategories: lazy(() => import('./EditDocumentCategoriesModal')),
  documentOptionsBottomSheet: lazy(
    () => import('../bottomSheets/DocumentOptionsBottomSheet'),
  ),
  documentInheritedOptionsBottomSheet: lazy(
    () => import('../bottomSheets/DocumentInheritedOptionsBottomSheet'),
  ),
  addContact: lazy(() => import('./AddContactModal')),
  contactsListOptions: lazy(() => import('./ContactsListOptionsModal')),
  noContacts: lazy(() => import('./noContacts/NoContactsModal')),
  documentShare: lazy(() => import('./documentShare/DocumentShareModal')),
  manageSSNConnection: lazy(
    () => import('./manageSSNConnection/ManageSSNConnectionModal'),
  ),
  selectPreferredNotary: lazy(() => import('./SelectPreferredNotaryModal')),
  citizenListOptionsBottomSheet: lazy(
    () => import('../bottomSheets/CitizenListOptionsBottomSheet'),
  ),
  deceasedContact: lazy(() => import('./DeceasedContactModal')),
  userMessage: lazy(() => import('./userMessage/UserMessageModal')),
  preferredNotaryDetails: lazy(() => import('./PreferredNotaryDetailsModal')),
  discardEmailSubscriptions: lazy(
    () => import('./DiscardEmailSubscriptionsModal'),
  ),
  disableVaultTransfer: lazy(
    () => import('./vaultTransferPreferences/DisableVaultTransferModal'),
  ),
  discardVaultTransfer: lazy(
    () => import('./vaultTransferPreferences/DiscardVaultTransferModal'),
  ),
  deleteVaultTransfer: lazy(
    () => import('./vaultTransferPreferences/DeleteVaultTransferModal'),
  ),
};
