import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Flex, IconButton, Tab, TabList, TabPanel, TabPanels, Tabs, } from '@chakra-ui/react';
import { useIsMobile } from '@izimi/hooks';
import { HistoryLogList } from '../../historyLog';
import { CustomIcon, FeatherIcon } from '../../icon';
import { NabanInfoMessage } from '../../naban';
import SideBar from '../../SideBar/SideBar';
export const DocumentPreviewSideBar = ({ document, isVisible, handleEditDocumentName, setIsVisible, children, }) => {
    const { t } = useTranslation('shared');
    const isMobile = useIsMobile();
    return (_jsxs(SideBar, { isVisible: isVisible, title: document.fileNameWithoutExtension, onClose: () => setIsVisible(false), HeaderRight: 
        // 81px header height - padding - 1px divider
        _jsxs(Flex, { height: '55px', children: [document.isOwner && handleEditDocumentName && (_jsx(IconButton, { "aria-label": t('action.edit'), icon: _jsx(FeatherIcon, { name: 'edit-2', size: 20, color: 'neutral500' }), onClick: () => handleEditDocumentName(document) })), isMobile && (_jsx(IconButton, { "aria-label": t('action.close'), icon: _jsx(CustomIcon, { name: 'slide-out', color: 'neutral500' }), onClick: () => setIsVisible(false) }))] }), children: [document.shouldShowNabanInfoMessage ? (_jsx(NabanInfoMessage, { document: document, message: t('sideBarInfo.preview'), m: 4 })) : null, children] }));
};
const SideBarTabs = ({ renderAccordion, document, }) => {
    const { t } = useTranslation('shared');
    return (_jsxs(Tabs, { flex: 1, display: 'flex', flexDir: 'column', children: [_jsxs(TabList, { pos: 'sticky', top: '88px', zIndex: 'sticky', bg: 'white', children: [_jsx(Tab, { children: t('navigation.document') }), _jsx(Tab, { children: t('navigation.history') })] }), _jsxs(TabPanels, { flex: 1, display: 'flex', children: [_jsx(TabPanel, { flex: 1, display: 'flex', children: renderAccordion }), _jsx(TabPanel, { children: _jsx(HistoryLogList, { documentId: document.id, useBodyScroll: false }) })] })] }));
};
DocumentPreviewSideBar.Tabs = SideBarTabs;
