function MedicalIcon({ size = 32 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
    >
      <rect x={2} width={28} height={26} rx={4} fill='#038164' />
      <rect y={2} width={32} height={30} rx={4} fill='#29C19D' />
      <rect
        y={2}
        width={32}
        height={30}
        rx={4}
        fill='url(#a)'
        fillOpacity={0.2}
        style={{
          mixBlendMode: 'multiply',
        }}
      />
      <circle
        opacity={0.45}
        cx={16}
        cy={17}
        r={6.25}
        fill='#038164'
        stroke='#038164'
        strokeWidth={1.5}
      />
      <circle
        opacity={0.3}
        cx={16}
        cy={17}
        r={9.25}
        fill='#038164'
        stroke='#038164'
        strokeWidth={1.5}
      />
      <circle
        opacity={0.2}
        cx={16}
        cy={17}
        r={12.25}
        fill='#038164'
        stroke='#038164'
        strokeWidth={1.5}
      />
      <path
        d='M8.5 15.053c0-3.698 5-5.955 7.501-1.887 2.5-4.068 7.499-1.811 7.499 1.887 0 3.385-4.187 7.047-6.814 9.345l-.02.017a1.01 1.01 0 0 1-1.33 0l-.023-.02C12.686 22.098 8.5 18.436 8.5 15.053Z'
        fill='#CCF0E8'
      />
      <path
        d='M8.5 15.053c0-3.698 5-5.955 7.501-1.887 2.5-4.068 7.499-1.811 7.499 1.887 0 3.385-4.187 7.047-6.814 9.345l-.02.017a1.01 1.01 0 0 1-1.33 0l-.023-.02C12.686 22.098 8.5 18.436 8.5 15.053Z'
        fill='url(#b)'
      />
      <defs>
        <linearGradient
          id='a'
          x1={16}
          y1={10}
          x2={16}
          y2={32}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0D897A' stopOpacity={0} />
          <stop offset={1} stopColor='#0D8989' />
        </linearGradient>
        <linearGradient
          id='b'
          x1={16}
          y1={11}
          x2={16}
          y2={25}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default MedicalIcon;
