class NotificationsQueryKeys {
    prefix;
    constructor(prefix) {
        this.prefix = prefix;
    }
    getPagedNotifications() {
        return [this.prefix];
    }
    hasUnreadNotifications() {
        return [this.prefix, 'unread'];
    }
    pollNotifications() {
        return [this.prefix, 'poll'];
    }
}
export default NotificationsQueryKeys;
