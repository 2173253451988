import { useMemo } from 'react';

import {
  Link,
  Navigation,
  NavigationUserMenuHeader,
  NotificationsPopOver,
} from '@izimi/components';
import { useModules } from '@izimi/modules';

import NavigationSearchIcon from './components/NavigationSearchIcon';
import IzimiLogoSmall from './IzimiLogoSmall';
import { Routes } from './types';
import useNavigationMenu from './useNavigationMenu';

const Nav = () => {
  const { mainMenu, userMenu, helpMenu } = useNavigationMenu();
  const {
    user: { useCurrentUser },
  } = useModules();
  const { currentUser } = useCurrentUser();
  const userName = currentUser
    ? `${currentUser.firstName} ${currentUser.lastName}`
    : '';

  const renderUserMenuHeader = useMemo(() => {
    const emailAddress = currentUser ? currentUser.emailAddress : '';
    return (
      <NavigationUserMenuHeader title={userName} subTitle={emailAddress} />
    );
  }, [currentUser, userName]);

  return (
    <Navigation<Routes>
      renderLogo={onClick => (
        <Link to={Routes.Home} onClick={onClick}>
          <IzimiLogoSmall />
        </Link>
      )}
      mainMenu={mainMenu}
      helpMenu={helpMenu}
      userMenu={userMenu}
      userName={userName}
      renderUserMenuHeader={renderUserMenuHeader}
    >
      <NotificationsPopOver<typeof Routes> routes={Routes} />
      <NavigationSearchIcon />
    </Navigation>
  );
};

export default Nav;
