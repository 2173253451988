import { useCallback } from 'react';
import useQueryParams from './useQueryParams';
const useActiveDocumentId = () => {
    const { params, updateQueryParams } = useQueryParams();
    const clearActiveDocumentId = useCallback(() => {
        updateQueryParams([{ key: 'id', value: null }]);
    }, [updateQueryParams]);
    return {
        activeDocumentId: params.id,
        clearActiveDocumentId,
    };
};
export default useActiveDocumentId;
