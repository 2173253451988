import { small } from '../typography';
const FormComponentStyle = {
    baseStyle: {
        helperText: {
            ...small,
            color: 'secondary400',
        },
        requiredIndicator: {
            color: 'error400',
        },
    },
};
export default FormComponentStyle;
