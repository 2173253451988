import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
export * from './helpers';
export const locales = ['en', 'fr', 'nl', 'de'];
export const namespaces = [
    'errors',
    'historyLog',
    'shared',
    'trash',
    'naban',
    'notifications',
    'vaultTransfer',
    'sharing',
    'emailNotifications',
    'unsubscribe',
];
export const initializeI18n = (read, { debug = false, logMissing = false } = {}) => i18n
    .use(LanguageDetector)
    .use({
    type: 'backend',
    init() { },
    read,
})
    .use(initReactI18next)
    .init({
    partialBundledLanguages: true,
    ns: ['shared'],
    supportedLngs: locales,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    react: { useSuspense: true },
    debug,
    saveMissing: logMissing,
    missingKeyHandler(_, ns, key) {
        // eslint-disable-next-line no-console
        console.log('missing translation', { ns, key });
    },
});
