import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Box, Divider, HStack, IconButton, SimpleGrid, SkeletonText, Text, } from '@chakra-ui/react';
import { FeatherIcon } from '../icon';
import { Link } from '../Link/Link';
const MenuSection = ({ title, children, }) => (_jsxs(Box, { as: 'section', borderColor: 'neutral100', borderWidth: '1px', borderRadius: '3xl', px: 7, mt: 8, boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.06)', alignSelf: 'stretch', children: [_jsx(Text, { variant: 'heading3', mb: 5, mt: 7, as: 'h2', children: title }), _jsx(Divider, {}), children] }));
const MenuItem = ({ icon, label, value, onClickEdit, href, isLoading, }) => {
    const { t } = useTranslation('shared');
    const renderAction = () => {
        if (onClickEdit) {
            return (_jsx(IconButton, { onClick: onClickEdit, variant: 'solid', "aria-label": t('action.edit'), bg: 'neutral100', size: 'md', icon: _jsx(FeatherIcon, { name: 'edit-3', color: 'neutral500' }) }));
        }
        if (href) {
            return _jsx(FeatherIcon, { name: 'arrow-right', color: 'neutral500' });
        }
        return null;
    };
    const menuItem = (_jsx(HStack, { justify: 'space-between', align: 'center', py: 6, children: _jsxs(SimpleGrid, { templateColumns: value ? { base: '1fr', md: '1fr 2fr 40px' } : '1fr 40px', as: 'dl', flex: 1, rowGap: 2, columnGap: 4, alignItems: 'center', children: [_jsxs(HStack, { as: 'dt', gap: { base: 1, md: 4 }, children: [icon, _jsx(Text, { variant: 'heading5', as: 'span', children: label })] }), isLoading && (_jsx(SkeletonText, { noOfLines: 1, as: 'dd', skeletonHeight: { base: 4, md: 5 }, w: '50%' })), !isLoading && Boolean(value) && (_jsx(Text, { variant: 'paragraph', as: 'dd', noOfLines: 1, children: value })), _jsx(HStack, { as: 'dt', display: 'flex', justifyContent: 'flex-end', children: renderAction() })] }) }));
    return href ? (_jsx(Link, { to: href, display: 'block', children: menuItem })) : (menuItem);
};
MenuSection.Item = MenuItem;
export default MenuSection;
