export var ApiNotificationType;
(function (ApiNotificationType) {
    // v2 types
    ApiNotificationType["DocumentSuccessfullyUploaded"] = "DOCUMENT_UPLOADED";
    ApiNotificationType["DocumentUploadFailedDueToVirus"] = "DOCUMENT_UPLOAD_FAILED_VIRUS";
    ApiNotificationType["SharedDocumentsReceived"] = "DOCUMENTS_RECEIVED";
    ApiNotificationType["SharedDocumentUnshared"] = "DOCUMENT_UNSHARED";
    ApiNotificationType["SharedDocumentUnsharedByRecipient"] = "DOCUMENT_UNSHARED_BY_RECIPIENT";
    ApiNotificationType["SharedDocumentsAccepted"] = "DOCUMENTS_ACCEPTED";
    ApiNotificationType["ContactRequestReceived"] = "CONTACT_REQUEST";
    ApiNotificationType["ContactRemoveReceived"] = "CONTACT_REMOVED";
    ApiNotificationType["ContactRequestAccepted"] = "CONTACT_ACCEPTED";
    ApiNotificationType["EmailReused"] = "EMAIL_REUSED";
    ApiNotificationType["DocumentsToBeRemovedAfterDeath"] = "DOCUMENTS_TO_BE_REMOVED_AFTER_DEATH";
    ApiNotificationType["PushedDocumentsReceived"] = "PUSHED_DOCUMENTS_RECEIVED";
    ApiNotificationType["PushedCertifiedDocumentReceived"] = "PUSHED_CERTIFIED_DOCUMENT_RECEIVED";
    ApiNotificationType["SharedDocumentByNotaryCitizen"] = "DOCUMENT_UPLOADED_BY_NOTARY";
    ApiNotificationType["SharedDocumentByNotaryCitizenFailed"] = "DOCUMENT_UPLOADED_BY_NOTARY_FAILED";
    ApiNotificationType["DocumentUploadedByThirdParty"] = "DOCUMENT_UPLOADED_BY_THIRD_PARTY";
    ApiNotificationType["VaultTransferReceived"] = "VAULT_TRANSFER_RECEIVED";
    ApiNotificationType["ExpiringInheritedVault"] = "EXPIRING_INHERITED_VAULT";
    ApiNotificationType["NotaryDocumentsReceived"] = "NOTARY_DOCUMENTS_RECEIVED";
    ApiNotificationType["PushDocumentsAccepted"] = "PUSHED_DOCUMENTS_ACCEPTED";
    ApiNotificationType["NotaryContactRemoved"] = "NOTARY_CONTACT_REMOVED";
    ApiNotificationType["NotaryDocumentUnshared"] = "NOTARY_DOCUMENT_UNSHARED";
    ApiNotificationType["NotaryContactAdded"] = "NOTARY_CONTACT_ADDED";
    ApiNotificationType["StudyContactAdded"] = "STUDY_CONTACT_ADDED";
    ApiNotificationType["StudyPushedDocumentsReceived"] = "STUDY_PUSHED_DOCUMENTS_RECEIVED";
    ApiNotificationType["NotaryAutomaticallyClaimedVault"] = "NOTARY_AUTOMATICALLY_CLAIMED_VAULT";
    ApiNotificationType["NotaryPassedClaimedVaultReceived"] = "NOTARY_PASSED_CLAIMED_VAULT_RECEIVED";
})(ApiNotificationType || (ApiNotificationType = {}));
