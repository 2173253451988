import type { ComponentProps, ElementType } from 'react';
import { useMemo } from 'react';
import { useTheme } from '@chakra-ui/react';

import { ApiDocumentCategory } from 'api';

import {
  AllIcon,
  BusinessIcon,
  FamilyIcon,
  FinanceIcon,
  HouseholdIcon,
  MedicalIcon,
  PersonalIcon,
  PropertyIcon,
  RealestateIcon,
} from './category-icons';

import imageCategoryAll from '/src/images/category-all@2x.jpg';
import imageCategoryBusiness from '/src/images/category-business@2x.jpg';
import imageCategoryFamily from '/src/images/category-family@2x.jpg';
import imageCategoryFinance from '/src/images/category-finance@2x.jpg';
import imageCategoryHealth from '/src/images/category-health@2x.jpg';
import imageCategoryHousehold from '/src/images/category-household@2x.jpg';
import imageCategoryPersonal from '/src/images/category-personal@2x.jpg';
import imageCategoryProperty from '/src/images/category-property@2x.jpg';
import imageCategoryRealEstate from '/src/images/category-realestate@2x.jpg';

export interface CategoryItem {
  id: ApiDocumentCategory;
  icon: ElementType<ComponentProps<typeof AllIcon>>;
  image: string;
  color100: string;
  color200: string;
  color300: string;
}

const useCategoryItems = () => {
  const theme = useTheme();

  const categoryItems: Record<ApiDocumentCategory, CategoryItem> = useMemo(
    () => ({
      [ApiDocumentCategory.All]: {
        id: ApiDocumentCategory.All,
        icon: AllIcon,
        image: imageCategoryAll,
        color100: theme.colors.categoryAll100,
        color200: theme.colors.categoryAll200,
        color300: theme.colors.categoryAll300,
      },
      [ApiDocumentCategory.Personal]: {
        id: ApiDocumentCategory.Personal,
        icon: PersonalIcon,
        image: imageCategoryPersonal,
        color100: theme.colors.categoryPersonal100,
        color200: theme.colors.categoryPersonal200,
        color300: theme.colors.categoryPersonal300,
      },
      [ApiDocumentCategory.Finance]: {
        id: ApiDocumentCategory.Finance,
        icon: FinanceIcon,
        image: imageCategoryFinance,
        color100: theme.colors.categoryFinance100,
        color200: theme.colors.categoryFinance200,
        color300: theme.colors.categoryFinance300,
      },
      [ApiDocumentCategory.Property]: {
        id: ApiDocumentCategory.Property,
        icon: PropertyIcon,
        image: imageCategoryProperty,
        color100: theme.colors.categoryProperty100,
        color200: theme.colors.categoryProperty200,
        color300: theme.colors.categoryProperty300,
      },

      [ApiDocumentCategory.Household]: {
        id: ApiDocumentCategory.Household,
        icon: HouseholdIcon,
        image: imageCategoryHousehold,
        color100: theme.colors.categoryHousehold100,
        color200: theme.colors.categoryHousehold200,
        color300: theme.colors.categoryHousehold300,
      },
      [ApiDocumentCategory.RealEstate]: {
        id: ApiDocumentCategory.RealEstate,
        icon: RealestateIcon,
        image: imageCategoryRealEstate,
        color100: theme.colors.categoryRealEstate100,
        color200: theme.colors.categoryRealEstate200,
        color300: theme.colors.categoryRealEstate300,
      },
      [ApiDocumentCategory.Family]: {
        id: ApiDocumentCategory.Family,
        icon: FamilyIcon,
        image: imageCategoryFamily,
        color100: theme.colors.categoryFamily100,
        color200: theme.colors.categoryFamily200,
        color300: theme.colors.categoryFamily300,
      },
      [ApiDocumentCategory.Business]: {
        id: ApiDocumentCategory.Business,
        icon: BusinessIcon,
        image: imageCategoryBusiness,
        color100: theme.colors.categoryBusiness100,
        color200: theme.colors.categoryBusiness200,
        color300: theme.colors.categoryBusiness300,
      },
      [ApiDocumentCategory.Medical]: {
        id: ApiDocumentCategory.Medical,
        icon: MedicalIcon,
        image: imageCategoryHealth,
        color100: theme.colors.categoryMedical100,
        color200: theme.colors.categoryMedical200,
        color300: theme.colors.categoryMedical300,
      },
    }),
    [theme.colors],
  );

  const categoryItemsWithoutAll = useMemo(() => {
    return Object.values(categoryItems).filter(
      c => c.id !== ApiDocumentCategory.All,
    );
  }, [categoryItems]);

  return { categoryItems, categoryItemsWithoutAll };
};

export default useCategoryItems;
