const colors = {
    primary000: '#F2F7F8',
    primary100: '#DCECED',
    primary200: '#BDDEE1',
    primary300: '#82C5CB',
    primary400: '#50B2C2',
    primary500: '#006D86',
    primary600: '#014558',
    secondary100: '#F9EFE2',
    secondary200: '#F0D4B2',
    secondary300: '#E9BC9B',
    secondary400: '#D8601E',
    secondary500: '#A43900',
    secondary600: '#6E2600',
    neutral000: '#FAF7F5',
    neutral100: '#F3F0EE',
    neutral200: '#E3DFDC',
    neutral300: '#BAB5B2',
    neutral400: '#76706D',
    neutral500: '#3D3836',
    neutral600: '#231F1C',
    error100: '#FCE0E0',
    error200: '#FDB4B4',
    error300: '#EE8E8E',
    error400: '#DC0505',
    error500: '#990000',
    error600: '#730000',
    success100: '#DDF3ED',
    success200: '#BCEBDD',
    success300: '#80D8BD',
    success400: '#15C590',
    success500: '#007F59',
    success600: '#034C36',
    black: '#000000',
    white: '#FFFFFF',
    transparent: 'transparent',
    overlay: 'rgba(1,69,88,0.35)',
    itsmeBrand: '#FF4612',
    categoryAll100: '#DCECED',
    categoryAll200: '#50B2C2',
    categoryAll300: '#006D86',
    categoryPersonal100: '#E0F0D3',
    categoryPersonal200: '#62B621',
    categoryPersonal300: '#347304',
    categoryFinance100: '#F9E9BE',
    categoryFinance200: '#FBBA00',
    categoryFinance300: '#965808',
    categoryProperty100: '#FCE9D4',
    categoryProperty200: '#EE9226',
    categoryProperty300: '#A04600',
    categoryHousehold100: '#FAD4D4',
    categoryHousehold200: '#E72A2A',
    categoryHousehold300: '#B70B0B',
    categoryRealEstate100: '#F7D7DD',
    categoryRealEstate200: '#EE4B68',
    categoryRealEstate300: '#B42561',
    categoryFamily100: '#F1D7F7',
    categoryFamily200: '#AF59C4',
    categoryFamily300: '#7E039C',
    categoryBusiness100: '#D7DAF7',
    categoryBusiness200: '#606DE4',
    categoryBusiness300: '#03139C',
    categoryMedical100: '#D7F7F0',
    categoryMedical200: '#29C19D',
    categoryMedical300: '#038164',
};
export default colors;
