import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Flex, IconButton, Popover, PopoverContent, PopoverTrigger, Portal, useBreakpointValue, } from '@chakra-ui/react';
import { useModules } from '@izimi/modules';
import { NavigationNotificationsIcon } from '../navigation';
import { NotificationPopOverBody } from './NotificationPopOverBody';
export const NotificationsPopOver = ({ routes, }) => {
    const { t } = useTranslation('shared');
    const { notifications: { usePagedNotifications, useMarkNotificationsAsViewed }, } = useModules();
    const { notifications } = usePagedNotifications({
        pageSize: 10,
        dontMarkFirstPageAsViewed: true,
    });
    const { markNotificationsAsViewed } = useMarkNotificationsAsViewed();
    const popoverPlacement = useBreakpointValue({
        base: 'bottom',
        md: 'right-end',
    }, { ssr: false });
    const onOpen = () => {
        if (!notifications) {
            return;
        }
        const notViewedNotifications = notifications
            .filter(n => !n.viewed)
            .map(n => n.id);
        markNotificationsAsViewed({ ids: notViewedNotifications, pageIndex: 0 });
    };
    return (
    // extra flex wrapper is needed: https://github.com/chakra-ui/chakra-ui/issues/3173
    _jsx(Flex, { flex: 1, justifyContent: 'center', children: _jsxs(Popover, { placement: popoverPlacement, closeOnBlur: true, closeOnEsc: true, onOpen: onOpen, preventOverflow: true, modifiers: [
                {
                    name: 'preventOverflow',
                    options: {
                        altAxis: true,
                        padding: 30,
                    },
                },
            ], children: [_jsx(PopoverTrigger, { children: _jsx(IconButton, { "aria-label": t('navigation.notifications'), backgroundColor: 'transparent', variant: 'menuIcon', icon: _jsx(NavigationNotificationsIcon, {}), title: t('navigation.notifications') }) }), _jsx(Portal, { children: _jsx(PopoverContent, { rootProps: {
                            zIndex: 9999,
                        }, maxHeight: { base: '75vh', md: '80vh' }, width: { base: 'calc(100vw - 30px)', md: 'md' }, maxWidth: 'calc(100% - 30px)', boxShadow: '0px 4px 8px rgba(61, 56, 54, 0.04), 0px 8px 16px rgba(61, 56, 54, 0.04), 0px 12px 32px rgba(61, 56, 54, 0.12)', children: _jsx(NotificationPopOverBody, { routes: routes }) }) })] }) }));
};
