import { paragraph } from '../typography';
const RadioComponentStyle = {
    baseStyle: {
        label: {
            ...paragraph(),
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        control: {
            borderWidth: 1.5,
            borderRadius: 'xl',
            borderColor: 'neutral200',
            color: 'white',
            bg: 'neutral200',
            _checked: {
                bg: 'primary400',
                borderColor: 'rgba(0, 109, 134, 0.2)',
                _hover: {
                    bg: 'primary400',
                    borderColor: 'rgba(0, 109, 134, 0.6)',
                },
            },
            _hover: {
                borderColor: 'neutral300',
            },
        },
    },
    sizes: {
        lg: {
            label: { fontSize: 'md' },
        },
    },
    defaultProps: {
        size: 'lg',
    },
};
export default RadioComponentStyle;
