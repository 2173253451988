class UserQueryKeys {
    prefix;
    constructor(prefix) {
        this.prefix = prefix;
    }
    getCurrentUser() {
        return [this.prefix, 'current'];
    }
    getUserPreferences() {
        return [this.prefix, 'preferences'];
    }
    getMailConfiguration() {
        return [this.prefix, 'mail-configuration'];
    }
}
export default UserQueryKeys;
