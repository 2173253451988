class NewsQueryKeys {
    prefix;
    constructor(prefix) {
        this.prefix = prefix;
    }
    getNewsFeed(categoryId) {
        return [this.prefix, 'id', categoryId];
    }
    getNewsItem({ type, id }) {
        return [this.prefix, 'type', type, 'id', id];
    }
}
export default NewsQueryKeys;
