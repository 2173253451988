import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, FormLabel, Grid, IconButton, SlideFade, Text, } from '@chakra-ui/react';
import { useAppHeight, useIsMobile } from '@izimi/hooks';
import { FeatherIcon } from '../icon';
export const WizardStep = forwardRef(({ title, subTitle, children, renderButtons, Illustration, step, onCancel }, ref) => {
    const [hasScrollableContent, setHasScrollableContent] = useState(false);
    const appHeight = useAppHeight();
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    useEffect(() => {
        if (typeof ref !== 'function' && ref?.current) {
            setHasScrollableContent(ref.current.scrollHeight > ref.current.clientHeight);
        }
    }, [ref]);
    const handleButtons = () => (_jsx(Flex, { position: 'sticky', zIndex: 'sticky', bottom: 0, width: '90%', borderTopWidth: 1, borderColor: 'neutral200', mx: '5%', backgroundColor: 'white', justifyContent: 'center', p: 2, boxShadow: hasScrollableContent ? '0 -15px 15px -20px rgba(0,0,0,0.3)' : 'none', children: _jsx(Flex, { justifyContent: 'center', alignItems: 'center', flex: 1, children: renderButtons() }) }));
    const currentStep = step;
    return (_jsxs(Grid, { templateColumns: { base: '1fr', md: '1.5fr 1fr' }, as: 'main', height: appHeight, overflow: 'hidden', children: [_jsxs(Flex, { position: 'relative', direction: 'column', overflowY: 'auto', as: 'section', ref: ref, children: [_jsxs(Text, { fontFamily: 'Yorkten Slab', fontSize: { base: 64, md: 96 }, position: { base: 'absolute', md: 'fixed' }, top: '75px', left: { base: -4, md: '-28px' }, color: 'primary100', zIndex: -1, "aria-hidden": 'true', children: ["0", currentStep] }), _jsxs(Flex, { p: 8, alignItems: 'center', position: 'fixed', children: [_jsx(IconButton, { backgroundColor: 'neutral100', variant: 'icon', "aria-label": 'logout', onClick: onCancel, icon: _jsx(FeatherIcon, { name: 'x' }), title: t('action.cancel'), id: 'cancelButton' }), _jsx(FormLabel, { m: 0, ml: 1, p: 1, fontFamily: 'Yorkten Slab', fontWeight: 600, fontSize: '16px', color: 'neutral400', htmlFor: 'cancelButton', cursor: 'pointer', background: 'rgba(255,255,255, 0.5)', children: t('action.cancel') })] }), _jsx(Flex, { width: { base: '90%', md: '70%' }, maxWidth: '550px', margin: { base: '105px auto 0 auto', md: '135px auto 0 auto' }, direction: 'column', flexGrow: 1, children: _jsxs(SlideFade, { in: true, style: {
                                position: 'initial',
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                            }, offsetY: 100, children: [_jsx(Text, { variant: 'heading1', mb: { base: 0, md: 2 }, ml: { base: '40px', md: 0 }, as: 'h2', children: title }), cloneElement(Illustration, {
                                    location: 'mobile',
                                }), _jsx(Text, { variant: 'paragraph', color: 'neutral500', mb: 6, as: 'h3', children: subTitle }), _jsx(Flex, { pb: 5, flexDirection: 'column', flex: 1, children: children })] }) }), !isMobile && handleButtons()] }), isMobile && handleButtons(), cloneElement(Illustration, { location: 'desktop' })] }));
});
