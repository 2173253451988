const CloseButtonComponentStyle = {
    baseStyle: {
        _focus: {
            boxShadow: 'none',
        },
        _focusVisible: {
            boxShadow: 'outline',
        },
    },
};
export default CloseButtonComponentStyle;
