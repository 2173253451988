import { input } from '../typography';
const sizeProps = {
    fontSize: input().fontSize,
    borderRadius: 'lg',
    px: 3,
};
const InputComponentStyle = {
    baseStyle: {
        field: {
            ...input(),
            color: 'neutral500',
            backgroundColor: 'neutral100',
            borderWidth: 1.5,
            borderColor: 'transparent',
            _placeholder: {
                opacity: 1,
                color: 'neutral400',
            },
            _hover: {
                borderColor: 'neutral200',
                _disabled: {
                    backgroundColor: 'neutral300',
                },
            },
            _focus: {
                color: 'primary500',
                backgroundColor: 'white',
                borderColor: 'primary400',
                boxShadow: '0px 0px 0px 4px rgba(80, 178, 194, 0.1)',
            },
            _disabled: {
                backgroundColor: 'neutral300',
                borderColor: 'neutral300',
                _placeholder: {
                    color: 'white',
                },
            },
            _invalid: {
                borderColor: 'secondary400',
            },
        },
    },
    defaultProps: {
        variant: 'empty',
        size: 'lg',
        errorBorderColor: 'secondary400',
    },
    // https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/input.ts#L21
    sizes: {
        xs: {
            field: sizeProps,
        },
        sm: {
            field: sizeProps,
        },
        md: {
            field: sizeProps,
        },
        lg: {
            field: sizeProps,
        },
    },
    variants: {
        empty: {},
        invalid: {
            field: {
                borderColor: 'secondary400',
            },
        },
        selectInputOpen: {
            field: {
                borderBottomRadius: 0,
                borderBottom: 'none',
            },
        },
    },
};
export default InputComponentStyle;
