import { buildQueryString } from '@izimi/utils';
class PostalCodesApi {
    fetchService;
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getPostalCodes(params) {
        const qs = buildQueryString(params);
        return this.fetchService.get(`/postal-codes${qs}`);
    }
}
export default PostalCodesApi;
