import { createRoot } from 'react-dom/client';

import { ToastContainer } from '@izimi/components';

import './core/i18n';
import './core/tracking/sentry';

import App from './app/App';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <>
    <ToastContainer />
    <App />
  </>,
);
