function FinanceIcon({ size = 32 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
    >
      <rect x={2} width={28} height={26} rx={4} fill='#BC7313' />
      <rect y={2} width={32} height={30} rx={4} fill='#EFB100' />
      <rect
        y={2}
        width={32}
        height={30}
        rx={4}
        fill='url(#a)'
        fillOpacity={0.2}
        style={{
          mixBlendMode: 'multiply',
        }}
      />
      <path
        d='M6 11.8a2 2 0 0 1 1.79-1.988l16.105-1.696A1 1 0 0 1 25 9.111V15H6v-3.2Z'
        fill='#BC7313'
      />
      <path
        d='M26 22v-8a2 2 0 0 0-2-2H6v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2Z'
        fill='#F0D9AF'
      />
      <path
        d='M26 22v-8a2 2 0 0 0-2-2H6v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2Z'
        fill='url(#b)'
      />
      <circle opacity={0.8} cx={21.5} cy={18.5} r={1.5} fill='#EE9226' />
      <path opacity={0.45} d='M23 10 6 12h17v-2Z' fill='#fff' />
      <defs>
        <linearGradient
          id='a'
          x1={16}
          y1={10}
          x2={16}
          y2={32}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A06F0F' stopOpacity={0} />
          <stop offset={1} stopColor='#A6680A' />
        </linearGradient>
        <linearGradient
          id='b'
          x1={16}
          y1={12}
          x2={16}
          y2={24}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default FinanceIcon;
