export const downloadBlobToFileSystem = (blob, fileName) => {
    const objUrl = URL.createObjectURL(blob);
    const link = window.document.createElement('a');
    link.href = objUrl;
    link.setAttribute('download', fileName);
    link.id = 'download-link';
    window.document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
};
