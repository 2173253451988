import { buildHeadersObject, buildQueryString } from '@izimi/utils';
class UserMessagesApi {
    fetchService;
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getUserMessages(language) {
        const headers = buildHeadersObject({ 'Accept-Language': language });
        return this.fetchService.get('/message', undefined, headers);
    }
    acknowledgeUserMessage(query) {
        const qs = buildQueryString(query);
        return this.fetchService.post(`/message/acknowledge${qs}`);
    }
}
export default UserMessagesApi;
