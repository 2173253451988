import { label } from '../typography';
const TabsComponentStyle = {
    baseStyle: ({ theme }) => ({
        tab: {
            ...label(),
            color: 'neutral500',
            backgroundColor: 'none',
            flex: 1,
            _selected: {
                color: 'primary500',
                backgroundColor: 'none',
                borderBottomWidth: 2,
                borderBottomColor: 'primary400',
            },
            _focusVisible: {
                borderColor: 'primary300',
                boxShadow: theme.shadows.outlinePrimary400,
            },
        },
        tablist: {
            borderBottomWidth: 1,
            borderBottomColor: 'neutral100',
        },
        tabpanel: {
            p: 0,
        },
    }),
    defaultProps: {
        variant: 'unstyled',
    },
};
export default TabsComponentStyle;
