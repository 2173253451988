import { buildQueryString } from '@izimi/utils';
class NabanApi {
    fetchService;
    BASE_URL = '/naban/deeds';
    BASE_URL_V2 = '/v2/naban/deeds';
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getNabanDocument({ nabanDocumentId, }) {
        return this.fetchService.get(`${this.BASE_URL}/${nabanDocumentId}`);
    }
    getNabanDocuments(params) {
        const qs = buildQueryString(params);
        return this.fetchService.get(`${this.BASE_URL}${qs}`);
    }
    importNabanDocuments(documentIds) {
        return this.fetchService.post(`${this.BASE_URL_V2}/import`, documentIds);
    }
    copyNabanDocuments(nabanDocumentIds) {
        return this.fetchService.post(`${this.BASE_URL}/copy`, nabanDocumentIds);
    }
    downloadNabanDocument({ nabanId }, params) {
        const qs = buildQueryString(params);
        return this.fetchService.get(`${this.BASE_URL}/${nabanId}/download${qs}`, { resAsBlob: true });
    }
}
export default NabanApi;
