import { useEffect } from 'react';
const useWatchAppHeight = () => {
    useEffect(() => {
        const appHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty('--app-height', `${window.innerHeight}px`);
        };
        window.addEventListener('resize', appHeight);
        appHeight();
    }, []);
};
export default useWatchAppHeight;
