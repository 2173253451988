class ConnectionsApi {
    fetchService;
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getConnections() {
        return this.fetchService.get('/connections');
    }
    createConnection(values) {
        return this.fetchService.post('/connections', values);
    }
    deleteConnection({ connectionId, }) {
        return this.fetchService.delete(`/connections/${connectionId}`);
    }
}
export default ConnectionsApi;
