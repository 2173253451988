import { jsx as _jsx } from "react/jsx-runtime";
import FileAudioIcon from './FileAudioIcon';
import FileCertifiedIcon from './FileCertifiedIcon';
import FileDocumentIcon from './FileDocumentIcon';
import FileFodFinIcon from './FileFodFinIcon';
import FileImageIcon from './FileImageIcon';
import FileNotaryIcon from './FileNotaryIcon';
import FileThirdPartyIcon from './FileThirdPartyIcon';
import FileVideoIcon from './FileVideoIcon';
// extensions based on allowed file types from api
const imageExtensions = [
    'bmp',
    'dwg',
    'dxf',
    'emf',
    'gif',
    'jpg',
    'jpeg',
    'png',
    'heic',
    'tiff',
    'svg',
    'ai',
    'eps',
    'psd',
    'raw',
    'tif',
    'ttf',
    'vsd',
    'vsdx',
    'wmf',
];
const videoExtensions = ['avi', 'mov', 'mpeg', 'mpg'];
const audioExtensions = ['mp3', 'mp4', 'wav'];
const FileIcon = ({ withNotaryIcon, isThirdParty, isCertified, withFodFinIcon, ...props }) => {
    if (isCertified) {
        return _jsx(FileCertifiedIcon, { ...props });
    }
    if (isThirdParty) {
        return _jsx(FileThirdPartyIcon, { ...props });
    }
    if (withNotaryIcon) {
        return _jsx(FileNotaryIcon, { ...props });
    }
    if (withFodFinIcon) {
        return _jsx(FileFodFinIcon, { ...props });
    }
    // eslint-disable-next-line react/destructuring-assignment
    if (imageExtensions.includes(props.extension)) {
        return _jsx(FileImageIcon, { ...props });
    }
    if (videoExtensions.includes(props.extension)) {
        return _jsx(FileVideoIcon, { ...props });
    }
    if (audioExtensions.includes(props.extension)) {
        return _jsx(FileAudioIcon, { ...props });
    }
    return _jsx(FileDocumentIcon, { ...props });
};
export default FileIcon;
