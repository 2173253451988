import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Text, } from '@chakra-ui/react';
import { SortId, useDocumentSorting } from '@izimi/core';
import { CustomIcon, FeatherIcon } from '../icon';
const sortOptions = [
    {
        id: SortId.ModificationDate,
        label: 'sortingPicker.modificationDate',
    },
    {
        id: SortId.Name,
        label: 'sortingPicker.name',
    },
];
export const VaultSortingPicker = () => {
    const { t } = useTranslation('shared');
    const { activeSorting, setActiveSorting } = useDocumentSorting('vault');
    return (_jsxs(Menu, { isLazy: true, children: [_jsx(Box, { children: _jsx(MenuButton, { size: 'sm', as: Button, rightIcon: _jsx(CustomIcon, { name: 'chevron-down', color: 'neutral400', size: 14 }), bg: 'neutral100', children: _jsx(Text, { children: _jsx(Text, { as: 'span', display: 'inline-block', children: t(`sortingPicker.${activeSorting.id}`) }) }) }) }), _jsx(MenuList, { children: sortOptions.map(sortOption => (_jsx(MenuItem, { onClick: () => setActiveSorting({ id: sortOption.id }), icon: activeSorting.id === sortOption.id ? (_jsx(FeatherIcon, { name: 'check', size: 18 })) : undefined, children: t(sortOption.label) }, sortOption.id))) })] }));
};
