import { getUserFromStorage } from './utils';
export const getInitialState = () => {
    const user = getUserFromStorage();
    return {
        isLoading: user ? Boolean(user.expired) : true,
        isAuthenticated: user ? !user.expired : false,
        user,
        error: undefined,
        sessionExpired: false,
    };
};
export const authReducer = (state, action) => {
    switch (action.type) {
        case 'INITIALISED':
        case 'USER_LOADED':
            return {
                ...state,
                user: action.user,
                isLoading: false,
                isAuthenticated: action.user ? !action.user.expired : false,
                error: undefined,
                sessionExpired: false,
            };
        case 'USER_UNLOADED':
            return {
                ...state,
                user: undefined,
                isAuthenticated: false,
            };
        case 'SESSION_EXPIRED':
            localStorage.setItem('auth:sessionExpired', 'true');
            return {
                ...state,
                sessionExpired: true,
            };
        case 'ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return {
                ...state,
                isLoading: false,
                error: new Error(`unknown action ${action}`),
            };
    }
};
