import { buildQueryString } from '@izimi/utils';
import NotaryCertificationApi from './NotaryCertificationApi';
class NotaryApi extends NotaryCertificationApi {
    fetchService;
    constructor(fetchService) {
        super(fetchService);
        this.fetchService = fetchService;
    }
    getPreferredNotary() {
        return this.fetchService.get('/preferred-notary');
    }
    getNotaries(params) {
        const qs = buildQueryString(params);
        return this.fetchService.get(`/notaries${qs}`);
    }
    getNotary({ notaryId }) {
        return this.fetchService.get(`/notaries/${notaryId}`);
    }
    createNotary({ notaryId }) {
        return this.fetchService.post('/notary/contacts', { notaryId });
    }
    pushDocument(params, onProgress, signal) {
        const formData = new FormData();
        formData.append('file', params.file);
        formData.append('fileName', params.fileName);
        if (params.destinationUserIds) {
            formData.append('destinationUserIds', params.destinationUserIds.join(','));
        }
        if (params.pushMessage) {
            formData.append('pushMessage', params.pushMessage);
        }
        return this.fetchService.uploadFile('/notary/push-document', formData, onProgress, { signal });
    }
    pushDocuments(params) {
        return this.fetchService.post('/notary/push-documents', params);
    }
    getClaimedVault(nrn) {
        return this.fetchService.get('/notary/claimed-vault', undefined, {
            'x-nrn': nrn,
        });
    }
    getClaimedVaults(params) {
        const qs = buildQueryString(params);
        return this.fetchService.get(`/notary/claimed-vaults${qs}`);
    }
    getClaimedVaultHistory({ claimedVaultId, }) {
        return this.fetchService.get(`/notary/claimed-vault/history/${claimedVaultId}`);
    }
    claimableVault(nrn) {
        return this.fetchService.get(`/notary/vault/claim`, undefined, {
            'x-nrn': nrn,
        });
    }
    claimVault(nrn) {
        return this.fetchService.post('/notary/vault/claim', undefined, {
            'x-nrn': nrn,
        });
    }
    /**
     * Pass a claimed vault to another study
     */
    passClaimedVault(params) {
        const qs = buildQueryString(params);
        return this.fetchService.post(`/notary/vault/pass${qs}`);
    }
    /**
     * Transfer a claimed citizen vault to his heirs
     */
    transferClaimedVault(params) {
        const qs = buildQueryString(params);
        return this.fetchService.post(`/notary/vault/transfer${qs}`);
    }
    getHeirs(params) {
        const qs = buildQueryString(params);
        return this.fetchService.get(`/notary/vault/heirs${qs}`);
    }
    updateHeir(params) {
        return this.fetchService.put('/notary/vault/heirs', params);
    }
    addHeir(params) {
        return this.fetchService.post('/notary/vault/heirs', params);
    }
    deleteHeir({ heirId }) {
        return this.fetchService.delete(`/notary/vault/heirs/${heirId}`);
    }
    generateVaultArchiveUrl({ heirId, }) {
        return this.fetchService.post(`/notary/vault/heirs/${heirId}`);
    }
    getVaultOwner(nrn) {
        return this.fetchService.get('/notary/vault-owner', undefined, {
            'x-nrn': nrn,
        });
    }
    getNotaryContacts(params) {
        const qs = buildQueryString(params);
        return this.fetchService.get(`/notary/contacts${qs}`);
    }
    getNotaryContact({ contactId, }) {
        return this.fetchService.get(`/notary/contacts/${contactId}`);
    }
    /**
     * Add a study contact for current user based on notary id
     */
    addNotaryStudyContact(params) {
        return this.fetchService.post('/notary/contacts', params);
    }
    getNotaryDocuments(params) {
        const qs = buildQueryString(params);
        return this.fetchService.get(`/v2/notary/documents${qs}`);
    }
    getNotaryDocument({ documentId }) {
        return this.fetchService.get(`/notary/document/${documentId}`);
    }
    getNotaryMailConfiguration() {
        return this.fetchService.get('/notary/mail-configuration');
    }
    setNotaryMailConfiguration(mailConfiguration) {
        return this.fetchService.put('/notary/mail-configuration', mailConfiguration);
    }
}
export default NotaryApi;
