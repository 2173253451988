import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { Box, cssVar, List, ListItem, Text, useTheme, VStack, } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { typography } from '@izimi/theme';
const AnimatedList = motion(List);
const BaseSelect = ({ items, idProperty = 'id', labelProperty = 'label', size = 'lg', isOpen, getMenuProps, getItemProps, inputValue, children, isInvalid, noItemsMessage, highlightedIndex, }) => {
    const { t } = useTranslation('shared');
    const theme = useTheme();
    const hasSubLabel = items.some(e => typeof e.subLabel !== 'undefined');
    const itemHeight = theme.components.Input.sizes[size].field.h * (hasSubLabel ? 6 : 4);
    const animateListVariants = useMemo(() => {
        return {
            expanded: {
                height: items.length === 0
                    ? itemHeight
                    : itemHeight * Math.min(items.length, 4),
                borderWidth: 1.5,
            },
            collapsed: {
                height: 0,
                borderWidth: 0,
            },
        };
    }, [items, itemHeight]);
    return (_jsxs(Box, { w: '100%', position: 'relative', children: [children, _jsx(AnimatedList, { variants: animateListVariants, animate: isOpen ? 'expanded' : 'collapsed', variant: 'selectList', borderColor: 
                // eslint-disable-next-line no-nested-ternary
                isOpen ? (isInvalid ? 'secondary400' : 'primary400') : 'transparent', boxShadow: isOpen ? '0px 0px 0px 4px rgba(80, 178, 194, 0.1)' : 'none', ...getMenuProps(), children: isOpen &&
                    (items.length > 0 ? (items.map((item, index) => {
                        const itemProps = getItemProps({
                            item,
                            index,
                        });
                        return (_jsx(ListItem, { height: `${itemHeight - 2}px`, backgroundColor: highlightedIndex === index ? 'primary100' : 'transparent', ...itemProps, children: _jsx(VStack, { alignItems: 'start', children: _jsxs(_Fragment, { children: [_jsx(Highlighter, { autoEscape: true, searchWords: [inputValue || ''], textToHighlight: String(item[labelProperty]), highlightStyle: {
                                                backgroundColor: 'transparent',
                                                color: cssVar('chakra-colors-neutral500').reference,
                                                ...typography.input(),
                                                fontWeight: '700',
                                            } }), item.subLabel && (_jsx(Box, { mt: '0 !important', children: _jsx(Text, { variant: 'smallSemibold', color: 'neutral400', children: String(item.subLabel) }) }))] }) }) }, String(item[idProperty])));
                    })) : (_jsx(ListItem, { _hover: { bg: 'transparent' }, height: `${itemHeight - 2}px`, children: noItemsMessage ?? t('select.noItemsFound') }))) })] }));
};
export default BaseSelect;
