import { useCallback, useMemo } from 'react';
import { useOrientation } from 'react-use';
import { usePrefersReducedMotion } from '@chakra-ui/react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { Tracker } from '@izimi/modules';
import useFontSize from './useFontSize';
const useTracking = () => {
    const matomo = useMatomo();
    const orientation = useOrientation();
    const prefersReducedMotion = usePrefersReducedMotion();
    const fontSize = useFontSize();
    const customDimensions = useMemo(() => [
        {
            id: Tracker.CustomDimensionId.PrefersReducedMotion,
            value: String(prefersReducedMotion),
        },
        {
            id: Tracker.CustomDimensionId.DynamicFontSize,
            value: String(fontSize),
        },
        {
            id: Tracker.CustomDimensionId.ColorScheme,
            value: typeof window !== 'undefined'
                ? window.document.documentElement.getAttribute('data-theme') ??
                    'light'
                : 'light',
        },
        {
            id: Tracker.CustomDimensionId.DeviceOrientation,
            value: orientation.type,
        },
    ], [fontSize, orientation.type, prefersReducedMotion]);
    const trackPageView = useCallback((url) => {
        // addUserNavigateBreadcrumb(url);
        matomo.trackPageView({ href: url, customDimensions });
    }, [matomo, customDimensions]);
    const trackEvent = useCallback(({ category, action, name = '', value = undefined }) => {
        // addUserPressBreadcrumb(action);
        matomo.trackEvent({
            category: category,
            action: action,
            name,
            value,
        });
    }, [matomo]);
    return {
        trackPageView,
        trackEvent,
    };
};
export default useTracking;
