import { getExtension, getNameWithoutExtension } from '@izimi/utils';
export const createDocumentUploadEntity = (document) => ({
    uploadSuccess: false,
    uploadStarted: false,
    uploadAborted: false,
    get fileName() {
        return this.file.name;
    },
    get fileNameWithoutExtension() {
        return getNameWithoutExtension(this.fileName);
    },
    get extension() {
        return getExtension(this.fileName);
    },
    get isValid() {
        return this.fileSizeAllowed && this.fileTypeAllowed;
    },
    ...document,
});
