import { useCallback } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isEmptyObject } from '@izimi/utils';
import { useInvalidateContactListCache } from '../contacts';
import { createUserModule } from '../user';
import { notaryQueryKeys, useInvalidateNotaryClaimedVaultsCache, useInvalidateNotaryHeirsCache, useInvalidatePreferredNotaryCache, useSetNotaryMailConfigurationCache, } from './cache';
const createNotaryModule = ({ notaryApi, userApi, }) => {
    const { useUpdateUser } = createUserModule({ userApi });
    const usePreferredNotary = () => {
        const { data, isLoading, error, isSuccess } = useQuery({
            queryKey: notaryQueryKeys.getPreferredNotary(),
            queryFn: () => notaryApi.getPreferredNotary(),
        });
        return {
            preferredNotary: isEmptyObject(data) ? undefined : data,
            error,
            isLoading,
            isSuccess,
        };
    };
    const useUpdatePreferredNotary = () => {
        const invalidatePreferredNotaryCache = useInvalidatePreferredNotaryCache();
        const invalidateContactsCache = useInvalidateContactListCache();
        const { updateUser, isPending, error } = useUpdateUser();
        const updatePreferredNotary = useCallback(async (notaryId) => {
            await updateUser({
                preferredNotaryId: notaryId,
            });
            return Promise.all([
                invalidatePreferredNotaryCache(),
                invalidateContactsCache(),
            ]);
        }, [updateUser, invalidatePreferredNotaryCache, invalidateContactsCache]);
        return {
            updatePreferredNotary,
            isPending,
            error,
        };
    };
    const useNotaries = (queryParams, enabled = true) => {
        const { data, isLoading, error, isSuccess } = useQuery({
            queryKey: notaryQueryKeys.getNotaries(queryParams),
            queryFn: () => notaryApi.getNotaries(queryParams),
            enabled,
        });
        return {
            notaries: data,
            error,
            isSuccess,
            isLoading,
        };
    };
    const useNotary = ({ notaryId }) => {
        const { data, isLoading, error } = useQuery({
            queryKey: notaryQueryKeys.getNotary(notaryId),
            queryFn: () => notaryApi.getNotary({ notaryId }),
        });
        return {
            notary: data,
            error,
            isLoading,
        };
    };
    const useNotaryPushDocuments = () => {
        const { mutateAsync, isPending, error } = useMutation({ mutationFn: params => notaryApi.pushDocuments(params) });
        return {
            pushNotaryDocuments: mutateAsync,
            error,
            isPending,
        };
    };
    const useNotaryClaimedVault = ({ params, enabled = true, }) => {
        const { data, isLoading, error } = useQuery({
            queryKey: notaryQueryKeys.getNotaryClaimedVault(params.nrn),
            queryFn: () => notaryApi.getClaimedVault(params.nrn),
            enabled,
        });
        return {
            notaryClaimedVault: data,
            error,
            isLoading,
        };
    };
    const useNotaryClaimedVaults = (params) => {
        const { data, isLoading, error } = useQuery({
            queryKey: notaryQueryKeys.getNotaryClaimedVaults(params),
            queryFn: () => notaryApi.getClaimedVaults(params),
        });
        return {
            notaryClaimedVaults: data,
            error,
            isLoading,
        };
    };
    const useNotaryClaimedVaultHistory = (claimedVaultId) => {
        const { data, isLoading, error } = useQuery({
            queryKey: notaryQueryKeys.getClaimedVaultHistory(claimedVaultId),
            queryFn: () => notaryApi.getClaimedVaultHistory({ claimedVaultId }),
        });
        return {
            notaryClaimedVaultHistory: data,
            error,
            isLoading,
        };
    };
    const useNotaryClaimableVault = ({ params, enabled = true, }) => {
        const { data, isLoading, error } = useQuery({
            queryKey: notaryQueryKeys.claimableVault(params.nrn),
            queryFn: () => notaryApi.claimableVault(params.nrn),
            enabled,
        });
        return {
            notaryClaimableVault: data,
            error,
            isLoading,
        };
    };
    const useNotaryClaimVault = () => {
        const invalidateNotaryClaimedVaultsCache = useInvalidateNotaryClaimedVaultsCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: params => notaryApi.claimVault(params),
            onSuccess() {
                invalidateNotaryClaimedVaultsCache();
            },
        });
        return {
            notaryClaimVault: mutateAsync,
            error,
            isPending,
        };
    };
    const useNotaryPassClaimedVault = () => {
        const invalidateNotaryClaimedVaultsCache = useInvalidateNotaryClaimedVaultsCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: params => notaryApi.passClaimedVault(params),
            onSuccess() {
                invalidateNotaryClaimedVaultsCache();
            },
        });
        return {
            notaryPassClaimedVault: mutateAsync,
            error,
            isPending,
        };
    };
    const useNotaryTransferClaimedVault = () => {
        const invalidateNotaryHeirsCache = useInvalidateNotaryHeirsCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: params => notaryApi.transferClaimedVault(params),
            onSuccess(params) {
                invalidateNotaryHeirsCache(params.claimedVault.vaultId);
            },
        });
        return {
            notaryTransferClaimedVault: mutateAsync,
            error,
            isPending,
        };
    };
    const useNotaryHeirs = (params) => {
        const { data, isLoading, error } = useQuery({
            queryKey: notaryQueryKeys.getNotaryHeirs(params),
            queryFn: () => notaryApi.getHeirs(params),
        });
        return {
            notaryHeirs: data,
            error,
            isLoading,
        };
    };
    const useNotaryUpdateHeir = () => {
        const invalidateNotaryHeirsCache = useInvalidateNotaryHeirsCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: params => notaryApi.updateHeir(params),
            onSuccess(params) {
                invalidateNotaryHeirsCache(params.vaultId);
            },
        });
        return {
            notaryUpdateHeir: mutateAsync,
            error,
            isPending,
        };
    };
    const useNotaryAddHeir = () => {
        const invalidateNotaryHeirsCache = useInvalidateNotaryHeirsCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: params => notaryApi.addHeir(params),
            onSuccess(params) {
                invalidateNotaryHeirsCache(params.vaultId);
            },
        });
        return {
            notaryAddHeir: mutateAsync,
            error,
            isPending,
        };
    };
    const useNotaryDeleteHeir = (vaultId) => {
        const invalidateNotaryHeirsCache = useInvalidateNotaryHeirsCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: params => notaryApi.deleteHeir(params),
            onSuccess() {
                invalidateNotaryHeirsCache(vaultId);
            },
        });
        return {
            notaryDeleteHeir: mutateAsync,
            error,
            isPending,
        };
    };
    const useNotaryGenerateVaultArchiveUrl = () => {
        const { mutateAsync, isPending, error } = useMutation({ mutationFn: params => notaryApi.generateVaultArchiveUrl(params) });
        return {
            notaryGenerateVaultArchiveUrl: mutateAsync,
            error,
            isPending,
        };
    };
    const useNotaryVaultOwner = ({ params, enabled = true, }) => {
        const { data, isLoading, error } = useQuery({
            queryKey: notaryQueryKeys.getVaultOwner(params.nrn),
            queryFn: () => notaryApi.getVaultOwner(params.nrn),
            enabled,
        });
        return {
            notaryVaultOwner: data,
            error,
            isLoading,
        };
    };
    const useNotaryContacts = (params) => {
        const { data, isLoading, error } = useQuery({
            queryKey: notaryQueryKeys.getNotaryContacts(params),
            queryFn: () => notaryApi.getNotaryContacts(params),
        });
        return {
            notaryContacts: data,
            error,
            isLoading,
        };
    };
    const useGetNotaryContact = () => {
        const { mutateAsync, isPending, error } = useMutation({ mutationFn: params => notaryApi.getNotaryContact(params) });
        return {
            getNotaryContact: mutateAsync,
            error,
            isPending,
        };
    };
    const useNotaryContact = (contactId) => {
        const { data, isLoading, error } = useQuery({
            queryKey: notaryQueryKeys.getNotaryContact(contactId),
            queryFn: () => notaryApi.getNotaryContact({ contactId }),
        });
        return {
            notaryContact: data,
            error,
            isLoading,
        };
    };
    const useNotaryAddNotaryStudyContact = () => {
        const invalidateContactsCache = useInvalidateContactListCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: params => notaryApi.addNotaryStudyContact(params),
            onSuccess() {
                invalidateContactsCache();
            },
        });
        return {
            notaryAddNotaryStudyContact: mutateAsync,
            error,
            isPending,
        };
    };
    const useNotaryDocuments = (params) => {
        const { data, isLoading, error } = useQuery({
            queryKey: notaryQueryKeys.getNotaryDocuments(params),
            queryFn: () => notaryApi.getNotaryDocuments(params),
        });
        return {
            notaryDocuments: data,
            error,
            isLoading,
        };
    };
    const useNotaryDocument = (documentId) => {
        const { data, isLoading, error } = useQuery({
            queryKey: notaryQueryKeys.getNotaryDocument(documentId),
            queryFn: () => notaryApi.getNotaryDocument({ documentId }),
        });
        return {
            notaryDocuments: data,
            error,
            isLoading,
        };
    };
    const useNotaryMailConfiguration = () => {
        const { data, isLoading, error } = useQuery({
            queryKey: notaryQueryKeys.getNotaryMailConfiguration(),
            queryFn: () => notaryApi.getNotaryMailConfiguration(),
        });
        return {
            data: data ?? [],
            isLoading,
            error,
        };
    };
    const useNotaryUpdateMailConfiguration = () => {
        const setNotaryMailConfigurationCache = useSetNotaryMailConfigurationCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: values => notaryApi.setNotaryMailConfiguration(values),
            onSuccess: data => setNotaryMailConfigurationCache(data),
        });
        return {
            updateMailConfiguration: mutateAsync,
            isPending,
            error,
        };
    };
    return {
        usePreferredNotary,
        useUpdatePreferredNotary,
        useNotaries,
        useNotary,
        useNotaryPushDocuments,
        useNotaryClaimedVault,
        useNotaryClaimedVaults,
        useNotaryClaimedVaultHistory,
        useNotaryClaimableVault,
        useNotaryClaimVault,
        useNotaryPassClaimedVault,
        useNotaryTransferClaimedVault,
        useNotaryHeirs,
        useNotaryUpdateHeir,
        useNotaryAddHeir,
        useNotaryDeleteHeir,
        useNotaryGenerateVaultArchiveUrl,
        useNotaryVaultOwner,
        useNotaryContacts,
        useNotaryContact,
        useGetNotaryContact,
        useNotaryAddNotaryStudyContact,
        useNotaryDocuments,
        useNotaryDocument,
        useNotaryMailConfiguration,
        useNotaryUpdateMailConfiguration,
    };
};
export default createNotaryModule;
