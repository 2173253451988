import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type { ApiInheritedVault } from '@izimi/api';

interface AppSettingsStore {
  activeVault: ApiInheritedVault | undefined;
  setActiveVault: (activeVault: AppSettingsStore['activeVault']) => void;
  showVaultTransferBadge: boolean;
  setShowVaultTransferBadge: (showVaultTransferBadge: boolean) => void;
  didShowHomeOnboardingConfetti: boolean;
  setDidShowHomeOnboardingConfetti: (
    didShowHomeOnboardingConfetti: boolean,
  ) => void;
  didHideCompletedTasks: boolean;
  setDidHideCompletedTasks: (didHideCompletedTasks: boolean) => void;
  didHideCompletedIntroduction: boolean;
  setDidHideCompletedIntroduction: (
    didHideCompletedIntroduction: boolean,
  ) => void;
}

const initialState = {
  activeVault: undefined as AppSettingsStore['activeVault'],
  showVaultTransferBadge: false,
  didShowHomeOnboardingConfetti: false,
  didHideCompletedTasks: false,
  didHideCompletedIntroduction: false,
};

export const useAppSettingsStore = create<AppSettingsStore>()(
  persist(
    set => ({
      ...initialState,
      setActiveVault: activeVault => set({ activeVault }),
      setShowVaultTransferBadge: showVaultTransferBadge =>
        set({ showVaultTransferBadge }),
      setDidShowHomeOnboardingConfetti: didShowHomeOnboardingConfetti =>
        set({ didShowHomeOnboardingConfetti }),
      setDidHideCompletedTasks: didHideCompletedTasks =>
        set({ didHideCompletedTasks }),
      setDidHideCompletedIntroduction: didHideCompletedIntroduction =>
        set({ didHideCompletedIntroduction }),
    }),
    {
      name: 'appSettingsStore',
      partialize(state) {
        return {
          showVaultTransferBadge: state.showVaultTransferBadge,
          didShowHomeOnboardingConfetti: state.didShowHomeOnboardingConfetti,
          didHideCompletedTasks: state.didHideCompletedTasks,
          didHideCompletedIntroduction: state.didHideCompletedIntroduction,
        };
      },
    },
  ),
);

const selectors = {
  getActiveVault: (state: AppSettingsStore) => state.activeVault,
  setActiveVault: (state: AppSettingsStore) => state.setActiveVault,
  getShowVaultTransferBadge: (state: AppSettingsStore) =>
    state.showVaultTransferBadge,
  setShowVaultTransferBadge: (state: AppSettingsStore) =>
    state.setShowVaultTransferBadge,
  getDidShowHomeOnboardingConfetti: (state: AppSettingsStore) =>
    state.didShowHomeOnboardingConfetti,
  setDidShowHomeOnboardingConfetti: (state: AppSettingsStore) =>
    state.setDidShowHomeOnboardingConfetti,
  getDidHideCompletedTasks: (state: AppSettingsStore) =>
    state.didHideCompletedTasks,
  setDidHideCompletedTasks: (state: AppSettingsStore) =>
    state.setDidHideCompletedTasks,
  getDidHideCompletedIntroduction: (state: AppSettingsStore) =>
    state.didHideCompletedIntroduction,
  setDidHideCompletedIntroduction: (state: AppSettingsStore) =>
    state.setDidHideCompletedIntroduction,
};

export const useActiveVault = () => {
  const activeVault = useAppSettingsStore(selectors.getActiveVault);
  const setActiveVault = useAppSettingsStore(selectors.setActiveVault);

  return {
    activeVault,
    setActiveVault,
  };
};

export const useDidShowHomeOnboardingConfetti = () => {
  const didShowHomeOnboardingConfetti = useAppSettingsStore(
    selectors.getDidShowHomeOnboardingConfetti,
  );
  const setDidShowHomeOnboardingConfetti = useAppSettingsStore(
    selectors.setDidShowHomeOnboardingConfetti,
  );

  return {
    didShowHomeOnboardingConfetti,
    setDidShowHomeOnboardingConfetti,
  };
};

export const useDidHideCompletedTasks = () => {
  const didHideCompletedTasks = useAppSettingsStore(
    selectors.getDidHideCompletedTasks,
  );
  const setDidHideCompletedTasks = useAppSettingsStore(
    selectors.setDidHideCompletedTasks,
  );

  return {
    didHideCompletedTasks,
    setDidHideCompletedTasks,
  };
};

export const useDidHideCompletedIntroduction = () => {
  const didHideCompletedIntroduction = useAppSettingsStore(
    selectors.getDidHideCompletedIntroduction,
  );
  const setDidHideCompletedIntroduction = useAppSettingsStore(
    selectors.setDidHideCompletedIntroduction,
  );

  return {
    didHideCompletedIntroduction,
    setDidHideCompletedIntroduction,
  };
};

export const useShowVaultTransferBadge = () => {
  const showVaultTransferBadge = useAppSettingsStore(
    selectors.getShowVaultTransferBadge,
  );
  const setShowVaultTransferBadge = useAppSettingsStore(
    selectors.setShowVaultTransferBadge,
  );

  return {
    showVaultTransferBadge,
    setShowVaultTransferBadge,
  };
};
