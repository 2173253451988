import { getElementsNotInSecondArray } from '@izimi/utils';
import VaultInfoEntity from './VaultInfoEntity';
import VaultPreferences from './VaultPreferences';
export const mapVaultInfoToEntity = (vaultInfo) => new VaultInfoEntity(vaultInfo);
export const mapVaultPreferencesToEntity = (vaultPreferences) => new VaultPreferences(vaultPreferences);
export const mapVaultPreferencesContactsToUpdate = (contacts) => contacts.map(c => {
    return {
        userId: c.contact.userId,
        selectionType: c.documentSelectionType,
        documents: c.documents.map(d => d.documentId),
    };
});
export const getAffectedContactsAndTheirDeselectedDocuments = (contactsDocumentSelection, selectedDocumentIds) => {
    return contactsDocumentSelection.reduce((acc, contact) => {
        const contactDocumentIds = contact.documents.map(d => d.documentId);
        const deselectedDocumentIds = getElementsNotInSecondArray(contactDocumentIds, selectedDocumentIds);
        if (deselectedDocumentIds.length > 0) {
            acc.affectedContacts.push(contact);
            deselectedDocumentIds.forEach(id => acc.deselectedDocumentIds.add(id));
        }
        return acc;
    }, {
        affectedContacts: [],
        deselectedDocumentIds: new Set(),
    });
};
