import shadows from '../shadows';
import { tableCell, tableHead } from '../typography';
export const tableRowActiveStyle = {
    backgroundColor: 'primary000',
    outline: '1px solid #82C5CB',
    outlineOffset: '-2px',
    boxShadow: shadows.outlinePrimary400,
};
const simple = () => ({
    th: {
        px: 2,
        py: 3,
        textTransform: 'none',
        letterSpacing: 0,
        ...tableHead(),
    },
    td: {
        px: 2,
        py: 3,
    },
    tr: {
        _last: {
            th: {
                borderBottom: 'none',
            },
            td: {
                borderBottom: 'none',
            },
        },
    },
});
const TableComponentStyle = {
    variants: {
        vaultTable: ({ theme }) => ({
            table: {
                position: 'relative',
                userSelect: 'none',
                tableLayout: 'fixed',
            },
            thead: {
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
            th: {
                backgroundColor: 'white',
                // borders do not work with sticky headers
                boxShadow: `inset 0px -3px 0px 0px ${theme.colors.neutral100}`,
                pt: 0,
                px: 2,
                textTransform: 'none',
                letterSpacing: 0,
                ...tableCell(),
                _first: {
                    pl: { base: 5, md: 7 },
                },
            },
            tbody: {
                tr: {
                    borderBottom: '1px solid',
                    borderBottomColor: 'neutral100',
                    height: '68px',
                    _hover: {
                        backgroundColor: tableRowActiveStyle.backgroundColor,
                    },
                    _focusWithin: {
                        backgroundColor: tableRowActiveStyle.backgroundColor,
                    },
                    td: {
                        px: 2,
                        py: 3,
                        _first: {
                            pl: { base: 5, md: 7 },
                        },
                        _last: {
                            pr: { base: 3, md: 7 },
                        },
                    },
                },
            },
        }),
        simple,
        modalList: () => ({
            ...simple(),
            td: {
                maxWidth: '240px',
            },
        }),
    },
};
export default TableComponentStyle;
