class DocumentsQueryKeys {
    prefix;
    constructor(prefix) {
        this.prefix = prefix;
    }
    getDocuments(type, params) {
        if (params) {
            return [this.prefix, type, params];
        }
        return [this.prefix, type];
    }
    getDocument(documentId) {
        return [this.prefix, 'id', documentId];
    }
    downloadDocument(documentId) {
        return [this.prefix, 'download', documentId];
    }
    getLabels() {
        return [this.prefix, 'labels'];
    }
    getFilters() {
        return [this.prefix, 'filters'];
    }
}
export default DocumentsQueryKeys;
