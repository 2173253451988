import * as variants from '../typography';
const TextComponentStyle = {
    variants: {
        ...variants,
    },
    defaultProps: {
        variant: 'paragraph',
    },
};
export default TextComponentStyle;
