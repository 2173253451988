import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import UserQueryKeys from './UserQueryKeys';
export const userQueryKeys = new UserQueryKeys('user');
export const useSetUserCache = () => {
    const queryClient = useQueryClient();
    const setUserCache = useCallback((user) => queryClient.setQueryData(userQueryKeys.getCurrentUser(), user), [queryClient]);
    return setUserCache;
};
export const useInvalidateCurrentUserCache = () => {
    const queryClient = useQueryClient();
    const invalidateCurrentUserCache = useCallback(() => queryClient.invalidateQueries({
        queryKey: userQueryKeys.getCurrentUser(),
    }), [queryClient]);
    return invalidateCurrentUserCache;
};
export const useSetUserMailConfiguration = () => {
    const queryClient = useQueryClient();
    const setUserMailConfiguration = useCallback((mailConfiguration) => queryClient.setQueryData(userQueryKeys.getMailConfiguration(), mailConfiguration), [queryClient]);
    return setUserMailConfiguration;
};
export const useSetUserPreferencesCache = () => {
    const queryClient = useQueryClient();
    const setUserPreferencesCache = useCallback((preferences) => queryClient.setQueryData(userQueryKeys.getUserPreferences(), preferences), [queryClient]);
    return setUserPreferencesCache;
};
