export var Event;
(function (Event) {
    Event["CallUnauthorized"] = "callUnauthorized";
    Event["CallForbidden"] = "callForbidden";
    Event["ConfigChange"] = "configChange";
    Event["ExternalFileShare"] = "externalFileShare";
    Event["NavigationReady"] = "NavigationReady";
    Event["PushNotificationOpened"] = "PushNotificationOpened";
    Event["Logout"] = "logout";
})(Event || (Event = {}));
class EventRegister {
    static _Listeners = {
        count: 0,
        refs: {},
    };
    static addEventListener(eventName, callback) {
        EventRegister._Listeners.count++;
        const eventId = `l${EventRegister._Listeners.count}`;
        EventRegister._Listeners.refs[eventId] = {
            name: eventName,
            callback,
        };
        return eventId;
    }
    static addEventListeners(eventNames, callback) {
        eventNames.forEach(eventName => this.addEventListener(eventName, callback));
    }
    static removeEventListener(id) {
        delete EventRegister._Listeners.refs[id];
    }
    static removeEventListeners(ids) {
        ids.forEach(id => this.removeEventListener(id));
    }
    static emit(eventName, data) {
        Object.keys(EventRegister._Listeners.refs).forEach(_id => {
            if (EventRegister._Listeners.refs[_id] &&
                eventName === EventRegister._Listeners.refs[_id].name)
                EventRegister._Listeners.refs[_id].callback(data);
        });
    }
}
export default EventRegister;
