function PersonalIcon({ size = 32 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
    >
      <rect x={2} width={28} height={26} rx={4} fill='#347304' />
      <rect y={2} width={32} height={30} rx={4} fill='#60AE24' />
      <rect
        y={2}
        width={32}
        height={30}
        rx={4}
        fill='url(#a)'
        fillOpacity={0.2}
        style={{
          mixBlendMode: 'multiply',
        }}
      />
      <circle cx={16} cy={13} r={3} fill='#fff' />
      <path
        opacity={0.6}
        d='M10 22a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2H10v-2Z'
        fill='#E0F0D3'
      />
      <path d='M10 22a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2H10v-2Z' fill='url(#b)' />
      <path
        d='M11.5 23.25V24H13v-.75h-1.5Zm1.5-.5a.75.75 0 0 0-1.5 0H13Zm0 .5v-.5h-1.5v.5H13ZM17.5 23.25V24H19v-.75h-1.5Zm1.5-.5a.75.75 0 0 0-1.5 0H19Zm0 .5v-.5h-1.5v.5H19Z'
        fill='#60AE24'
      />
      <defs>
        <linearGradient
          id='a'
          x1={16}
          y1={10}
          x2={16}
          y2={32}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#276A08' stopOpacity={0} />
          <stop offset={1} stopColor='#116909' />
        </linearGradient>
        <linearGradient
          id='b'
          x1={16}
          y1={18}
          x2={16}
          y2={24}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PersonalIcon;
