import type { PropsWithChildren } from 'react';

import { TrackingProvider } from '@izimi/components';
import { useModules } from '@izimi/modules';
import config from 'core/config';
import { useConfigurationLinks } from 'core/modules/public';

export const CitizenTrackingProvider = ({ children }: PropsWithChildren) => {
  const { cookieUrl } = useConfigurationLinks();
  const {
    public: { useWebConfiguration },
  } = useModules();
  const { configuration } = useWebConfiguration();

  const tracking = configuration?.analytics.id
    ? {
        siteId: configuration?.analytics.id,
        url: config.apiUrl,
        trackerUrl: `${config.apiUrl}/matomo/piwik.php`,
        srcUrl: `${config.apiUrl}/matomo/piwik.js`,
      }
    : null;

  return (
    <TrackingProvider config={tracking} cookieUrl={cookieUrl}>
      {children}
    </TrackingProvider>
  );
};
