import type { PropsWithChildren } from 'react';
import type { ChakraProps } from '@chakra-ui/react';

import { BaseLayout } from '@izimi/components';
import Nav from 'navigation/Nav';

type Props = PropsWithChildren<Omit<ChakraProps, 'transition'>>;

const Layout = ({ children, ...chakraProps }: Props) => {
  return (
    <BaseLayout navigation={<Nav />} {...chakraProps}>
      {children}
    </BaseLayout>
  );
};

export default Layout;
