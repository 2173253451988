import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import ConnectionsQueryKeys from './ConnectionsQueryKeys';
export const connectionsQueryKeys = new ConnectionsQueryKeys();
export const useRemoveConnectionFromCache = () => {
    const queryClient = useQueryClient();
    const removeConnectionFromCache = useCallback((connectionId) => {
        const queryKey = connectionsQueryKeys.getConnections();
        const connections = queryClient.getQueryData(queryKey);
        if (connections) {
            return queryClient.setQueryData(queryKey, connections.filter(c => c.connectionId !== connectionId));
        }
        return undefined;
    }, [queryClient]);
    return removeConnectionFromCache;
};
export const useAddConnectionToCache = () => {
    const queryClient = useQueryClient();
    const addConnectionToCache = useCallback((connection) => {
        const queryKey = connectionsQueryKeys.getConnections();
        const connections = queryClient.getQueryData(queryKey);
        if (connections) {
            return queryClient.setQueryData(queryKey, [...connections, connection]);
        }
        return undefined;
    }, [queryClient]);
    return addConnectionToCache;
};
