import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex } from '@chakra-ui/react';
import MotionBox from '../MotionBox/MotionBox';
export const Spinner = ({ size = 12, ...rest }) => {
    const getPxValue = (value) => `${value}px`;
    const stylingProps = {
        width: getPxValue(size),
        height: getPxValue(size),
        borderRadius: 'full',
        pos: 'absolute',
        transition: {
            duration: 3,
            repeat: Infinity,
            easings: 'ease',
        },
    };
    return (_jsxs(Flex, { flexDirection: 'row', pos: 'relative', width: getPxValue(size * 3), height: getPxValue(size), ...rest, children: [_jsx(MotionBox, { backgroundColor: 'secondary400', ...stylingProps, animate: {
                    scale: [1, 0, 1, 1.4, 1],
                    translateX: [
                        getPxValue(0),
                        getPxValue(size),
                        getPxValue(size * 2),
                        getPxValue(size),
                        getPxValue(0),
                    ],
                } }), _jsx(MotionBox, { backgroundColor: 'primary400', ...stylingProps, zIndex: 10, animate: {
                    scale: [1, 1.4, 1, 0, 1],
                    translateX: [
                        getPxValue(size * 2),
                        getPxValue(size),
                        getPxValue(0),
                        getPxValue(size),
                        getPxValue(size * 2),
                    ],
                } })] }));
};
