const baseHeadingStyles = {
    fontFamily: 'Yorkten Slab',
    fontStyle: 'normal',
    fontWeight: '600',
};
export const heading1 = () => ({
    ...baseHeadingStyles,
    fontSize: { base: '28px', md: '40px' },
    lineHeight: 1.25,
    letterSpacing: '-0.5px',
});
export const heading2 = () => ({
    ...baseHeadingStyles,
    fontSize: { base: '24px', md: '28px' },
    lineHeight: 1.25,
    letterSpacing: '-0.5px',
});
export const heading3 = () => ({
    ...baseHeadingStyles,
    fontSize: { base: '20px', md: '24px' },
    lineHeight: 1.5,
});
export const heading4 = () => ({
    ...baseHeadingStyles,
    fontSize: { base: '18px', md: '21px' },
    lineHeight: 1.5,
});
export const heading5 = () => ({
    ...baseHeadingStyles,
    fontSize: { base: '16px', md: '18px' },
    lineHeight: 1.5,
});
export const heading6 = () => ({
    ...baseHeadingStyles,
    fontSize: { base: '14px', md: '16px' },
    lineHeight: 1.5,
});
const baseBodyStyles = {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '400',
};
export const badge = () => ({
    ...baseBodyStyles,
    fontSize: '11px',
    lineHeight: 1.25,
    fontWeight: '400',
    color: 'white',
});
export const input = () => ({
    ...baseBodyStyles,
    fontSize: '16px',
    lineHeight: '16px',
});
export const paragraph = () => ({
    ...baseBodyStyles,
    fontSize: { base: '16px', md: '18px' },
    lineHeight: 1.5,
});
export const paragraphSemiBold = () => ({
    ...paragraph(),
    fontWeight: '600',
});
export const lead = () => ({
    ...baseBodyStyles,
    fontSize: { base: '18px', md: '21px' },
    lineHeight: 1.5,
});
export const tableCell = () => ({
    ...baseBodyStyles,
    fontSize: '16px',
    lineHeight: '24px',
});
export const tableHead = () => ({
    ...tableCell(),
    fontWeight: '600',
});
export const tableHeadBold = () => ({
    ...tableHead(),
    fontWeight: '700',
});
export const small = () => ({
    ...baseBodyStyles,
    fontSize: '14px',
    lineHeight: '21px',
});
export const smallSemibold = () => ({
    ...small(),
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '600',
});
export const label = () => ({
    ...baseBodyStyles,
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
});
export const link = () => ({
    ...baseBodyStyles,
    fontWeight: '600',
    textDecoration: 'underline',
    cursor: 'pointer',
});
export const largeLink = () => ({
    ...link(),
    fontSize: { base: '14px', md: '18px' },
    lineHeight: '27px',
});
export const caption = () => ({
    ...smallSemibold(),
    color: 'neutral400',
});
