import { useWithMessages } from '@izimi/components';
import { createNotaryModule, notaryMessages } from '@izimi/modules';
import { notaryApi, userApi } from 'core/di';

export { notaryMessages } from '@izimi/modules';

const hooks = createNotaryModule({ notaryApi, userApi });

const useNotaries = (...args: Parameters<typeof hooks.useNotaries>) =>
  useWithMessages(hooks.useNotaries(...args))(notaryMessages.getNotaries());

export const notaryModule = {
  ...hooks,
  useNotaries,
};

export type NotaryModule = typeof notaryModule;
