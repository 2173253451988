import { jsx as _jsx } from "react/jsx-runtime";
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { de, enGB, fr, nlBE } from 'date-fns/locale';
import { useFormatDate } from '@izimi/hooks';
import { DatePickerHeader } from './components/DatePickerHeader';
import { DatePickerInput } from './components/DatePickerInput';
import 'react-datepicker/dist/react-datepicker.css';
registerLocale('fr', fr);
registerLocale('en', enGB);
registerLocale('nl', nlBE);
registerLocale('de', de);
export const DatePicker = ({ onClear, clearable, label, maxDate = new Date(), ...props }) => {
    const { i18n } = useTranslation();
    const formatDate = useFormatDate(i18n.language);
    return (_jsx(ReactDatePicker, { customInput: _jsx(DatePickerInput, { onClear: onClear, clearable: clearable, label: label }), renderCustomHeader: ({ ...rest }) => (_jsx(DatePickerHeader, { formatDate: formatDate, ...rest })), maxDate: maxDate, dateFormat: 'dd/MM/yyyy', showPopperArrow: false, locale: i18n.language, ...props }));
};
