import { jsx as _jsx } from "react/jsx-runtime";
import { Children, memo } from 'react';
import { Flex } from '@chakra-ui/react';
const variants = {
    primary: {
        backgroundColor: 'primary400',
        boxShadow: 'dotPrimary400',
    },
    secondary: {
        backgroundColor: 'secondary400',
        boxShadow: 'dotSecondary400',
    },
};
export const Dot = memo(({ variant = 'primary', children, ...props }) => {
    const childrenCount = Children.count(children);
    return (_jsx(Flex, { justifyContent: 'center', alignItems: 'center', borderRadius: 'full', px: 1, py: childrenCount > 0 ? 0 : 1, ...variants[variant], ...props, children: children }));
});
