import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { contactsQueryKeys, useInvalidateContactListCache, useInvalidateContactRequestsCache, } from './cache';
import { getCitizenContacts } from './helpers';
const pageSize = 10;
const createContactsModule = ({ contactsApi, }) => {
    const usePagedContacts = ({ enabled = true, params, type = 'contactList', } = {}) => {
        const { data, error, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isRefetching, isSuccess, refetch, } = useInfiniteQuery({
            queryKey: contactsQueryKeys.getContacts(type, {
                name: params?.name,
                contactType: params?.contactType,
                sort: params?.sort,
                status: params?.status,
            }),
            initialPageParam: 0,
            queryFn: ({ pageParam }) => contactsApi.getContactsV2({
                pageSize,
                page: Number(pageParam),
                ...params,
            }),
            getNextPageParam: (lastPage, allPages) => {
                const totalFetchedContacts = allPages.flatMap(page => page.page?.elements || []).length;
                return totalFetchedContacts < Number(lastPage.page?.totalFilteredSize)
                    ? Number(lastPage.page?.pageIndex) + 1
                    : undefined;
            },
            enabled,
        });
        const allContacts = data?.pages
            ?.flat()
            .map(({ page }) => page?.elements)
            ?.flat() || [];
        return {
            contacts: allContacts,
            citizenContacts: getCitizenContacts(allContacts),
            error,
            fetchNextPage,
            hasNextPage,
            isFetchingNextPage,
            isLoading,
            isRefetching,
            isSuccess,
            refetch,
            total: data?.pages[0].totalSize ?? 0,
            totalFiltered: data?.pages[0].page?.totalFilteredSize,
        };
    };
    const useInviteContact = () => {
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: email => {
                return contactsApi.inviteContact({ emailAddress: email });
            },
        });
        return {
            inviteContact: mutateAsync,
            error,
            isPending,
        };
    };
    const useInviteContacts = () => {
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: emailAddresses => {
                return Promise.all(emailAddresses.map(emailAddress => contactsApi.inviteContact({ emailAddress })));
            },
        });
        return {
            inviteContacts: mutateAsync,
            error,
            isPending,
        };
    };
    const useDeleteContact = () => {
        const invalidateContactsCache = useInvalidateContactListCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: id => contactsApi.deleteContact({ contactId: id }),
            onSuccess() {
                invalidateContactsCache();
            },
        });
        return {
            deleteContact: mutateAsync,
            error,
            isPending,
        };
    };
    const useAcceptContactRequest = () => {
        const invalidateContactRequestsCache = useInvalidateContactRequestsCache();
        const invalidateContactsCache = useInvalidateContactListCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: id => contactsApi.acceptContactRequest({ contactId: id }),
            onSuccess() {
                invalidateContactRequestsCache();
                invalidateContactsCache();
            },
        });
        return {
            acceptContactRequest: mutateAsync,
            error,
            isPending,
        };
    };
    const useRejectContactRequest = () => {
        const invalidateContactRequestsCache = useInvalidateContactRequestsCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: id => contactsApi.rejectContactRequest({ contactId: id }),
            onSuccess() {
                invalidateContactRequestsCache();
            },
        });
        return {
            rejectContactRequest: mutateAsync,
            error,
            isPending,
        };
    };
    return {
        useInviteContact,
        useInviteContacts,
        useDeleteContact,
        useAcceptContactRequest,
        useRejectContactRequest,
        usePagedContacts,
    };
};
export default createContactsModule;
