import { smallSemibold } from '../typography';
const TagComponentStyle = {
    baseStyle: {
        container: {
            ...smallSemibold(),
        },
    },
    variants: {
        empty: {},
    },
    defaultProps: {
        variant: 'empty',
    },
};
export default TagComponentStyle;
