import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiDocumentSource } from '@izimi/api';
import { useAuth } from '@izimi/auth';
import { useDocumentFiltersStore } from '@izimi/core';
const DocumentFiltersContext = createContext(null);
export const useDocumentFiltering = () => {
    const context = useContext(DocumentFiltersContext);
    if (!context) {
        throw new Error('useDocumentFiltering must be used within a DocumentFiltersProvider');
    }
    return context;
};
const citizenFilters = {
    vault: ['sources', 'receiverIds', 'labels'],
    byYou: ['sources', 'receiverIds', 'labels'],
    fromContacts: ['ownerIds', 'labels'],
};
const citizenDocumentArgs = {
    vault: {
        type: 'vaultList',
    },
    byYou: {
        params: {
            isSharedByMe: true,
            states: 'ACTIVE',
        },
        type: 'sharedByYouList',
    },
    fromContacts: {
        params: {
            sources: ApiDocumentSource.Shared,
            states: ['ACTIVE', 'PENDING'],
        },
        type: 'sharedFromContactsList',
    },
};
export const DocumentFiltersProvider = ({ children }) => {
    const [currentFilter, setCurrentFilter] = useState('vault');
    const filters = useDocumentFiltersStore(currentFilter);
    const { user } = useAuth();
    const { pathname } = useLocation();
    citizenDocumentArgs.vault = {
        type: 'vaultList',
        params: {
            ownerId: user?.profile.id,
        },
    };
    useEffect(() => {
        switch (pathname) {
            case '/sharing/from-contacts':
                setCurrentFilter('fromContacts');
                break;
            case '/sharing/by-you':
                setCurrentFilter('byYou');
                break;
            case '/vault':
                setCurrentFilter('vault');
                break;
            default:
                break;
        }
    }, [pathname]);
    const value = useMemo(() => ({
        ...filters,
        citizenSupportedFilters: citizenFilters[currentFilter],
        citizenDocumentArgs: citizenDocumentArgs[currentFilter],
        currentFilter,
    }), [filters, currentFilter]);
    return (_jsx(DocumentFiltersContext.Provider, { value: value, children: children }));
};
