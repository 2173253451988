export const isEmptyObject = (obj) => !obj || Object.keys(obj).length === 0 || Object.values(obj).every(x => !x);
export const groupBy = (list, getKey) => list.reduce((previous, currentItem, index) => {
    const group = getKey(currentItem, index);
    // eslint-disable-next-line no-param-reassign
    if (!previous[group])
        previous[group] = [];
    previous[group].push(currentItem);
    return previous;
}, {});
export const chunkArray = (arr, chunckSize) => {
    const chunks = [];
    for (let i = 0; i < Math.ceil(arr.length / chunckSize); i++) {
        chunks.push(arr.slice(i * chunckSize, (i + 1) * chunckSize));
    }
    return chunks;
};
export const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g)?.map(x => parseInt(x, 16)) ?? [];
    return `rgba(${r},${g},${b},${alpha})`;
};
export function nonNullable(value) {
    return value !== null && value !== undefined;
}
