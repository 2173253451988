const ProgressComponentStyle = {
    baseStyle: {
        track: {
            backgroundColor: 'primary100',
        },
        filledTrack: {
            backgroundColor: 'primary300',
        },
    },
};
export default ProgressComponentStyle;
