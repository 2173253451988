import { useQuery } from '@tanstack/react-query';
import { newsQueryKeys } from './cache';
const STALE_30_MINUTES = 1800000;
const createNewsModule = ({ newsApi }) => {
    const useGetNewsFeed = ({ categoryId, pageSize = 6, }) => {
        const { data, isLoading, error, isSuccess } = useQuery({
            queryKey: newsQueryKeys.getNewsFeed(categoryId || 'all'),
            queryFn: () => newsApi.getNewsFeed({
                category: categoryId,
                per_page: pageSize,
                sort_by: 'created_datetime',
                order: 'desc',
            }),
            staleTime: STALE_30_MINUTES,
            refetchOnWindowFocus: false,
        });
        return {
            newsFeed: data?.data,
            metadata: data?._metadata,
            error,
            isLoading,
            isSuccess,
        };
    };
    const useGetNewsItem = ({ type, id }) => {
        const { data, isLoading, error, isSuccess } = useQuery({
            queryKey: newsQueryKeys.getNewsItem({ type, id }),
            queryFn: () => newsApi.getNewsItem({ type, id }),
            staleTime: STALE_30_MINUTES,
            refetchOnWindowFocus: false,
        });
        return {
            article: data?.data,
            error,
            isLoading,
            isSuccess,
        };
    };
    return {
        useGetNewsFeed,
        useGetNewsItem,
    };
};
export default createNewsModule;
