import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import { useAppHeight } from '@izimi/hooks';
import { MotionBox } from '../MotionBox';
const pageVariants = {
    initial: {
        opacity: 0,
    },
    in: {
        opacity: 1,
    },
    out: {
        opacity: 0,
    },
};
const pageTransition = {
    type: 'tween',
    ease: 'easeOut',
    duration: 0.25,
};
const pageExceptions = ['/sharing/by-you', '/sharing/from-contacts'];
export const BaseLayout = ({ children, navigation, ...chakraProps }) => {
    const appHeight = useAppHeight();
    const { pathname } = useLocation();
    const shouldAnimate = !pageExceptions.includes(pathname);
    return (_jsxs(Flex, { direction: { base: 'column', md: 'row' }, height: appHeight, overflow: 'auto', children: [navigation, _jsx(MotionBox, { initial: shouldAnimate ? 'initial' : false, animate: 'in', variants: pageVariants, transition: pageTransition, flex: 1, ...chakraProps, children: children })] }));
};
