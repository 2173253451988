import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { Box, Divider, Flex, Stack } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import CustomIcon from '../icon/CustomIcon';
import MotionBox from '../MotionBox/MotionBox';
import NavigationHelpMenu from './NavigationHelpMenu';
import NavigationItem from './NavigationItem';
import NavigationMenuToggle from './NavigationMenuToggle';
import NavigationUserMenu from './NavigationUserMenu';
const Navigation = ({ renderLogo, renderQuickActions, renderUserMenuHeader, mainMenu, userMenu, helpMenu, children, userName, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const closeMobileMenu = () => setIsOpen(false);
    return (_jsxs(Flex, { as: 'nav', direction: { base: 'row', md: 'column' }, bg: 'neutral100', height: { base: '70px', md: '100%' }, minHeight: { base: '70px', md: '100%' }, minW: { md: '100px' }, maxW: { md: '100px' }, py: { base: 4, md: 6 }, px: { base: 5, md: 0 }, paddingInlineEnd: { base: 2 }, position: 'sticky', top: 0, zIndex: 'sticky', justifyContent: 'space-between', alignItems: { base: 'center', md: 'revert' }, overflowY: { base: 'hidden', md: 'auto' }, children: [_jsxs(Flex, { direction: { base: 'row', md: 'column' }, alignItems: 'center', children: [renderLogo(closeMobileMenu), renderQuickActions && (_jsx(Box, { mt: 8, display: { base: 'none', md: 'flex' }, children: renderQuickActions })), _jsx(Stack, { width: '100%', display: { base: 'none', md: 'flex' }, mt: 8, children: mainMenu.map(item => (_jsx(NavigationItem, { to: item.href, label: item.label, icon: _jsx(CustomIcon, { name: item.customIconName }), badge: item.badge }, item.label))) })] }), _jsxs(Stack, { direction: { base: 'row', md: 'column' }, spacing: { base: 0, md: 6 }, children: [_jsx(NavigationHelpMenu, { items: helpMenu }), children, _jsx(NavigationUserMenu, { userName: userName, renderUserMenuHeader: renderUserMenuHeader, items: userMenu }), _jsx(NavigationMenuToggle, { isOpen: isOpen, setIsOpen: setIsOpen }), _jsx(AnimatePresence, { children: isOpen && (_jsx(RemoveScroll, { children: _jsx(MotionBox, { w: '100vw', h: 'calc(100% - 70px)', zIndex: 10, position: 'fixed', top: 70, left: 0, bg: 'white', overflowY: 'auto', initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.3 }, children: _jsxs(Stack, { spacing: 6, py: 6, px: 5, children: [mainMenu.map(item => (_jsx(NavigationItem, { type: 'mobile', to: item.href, label: item.label, icon: _jsx(CustomIcon, { name: item.customIconName }), onClick: () => {
                                                item.onClick?.();
                                                closeMobileMenu();
                                            } }, item.label))), _jsx(Divider, {}), helpMenu.map(item => (_jsx(NavigationItem, { type: 'mobile', to: item.href, label: item.label, icon: _jsx(CustomIcon, { name: item.customIconName }), onClick: () => {
                                                item.onClick?.();
                                                closeMobileMenu();
                                            } }, item.label))), _jsx(Divider, {}), userMenu.map(item => (_jsx(NavigationItem, { type: 'mobile', to: item.href, label: item.label, icon: _jsx(CustomIcon, { name: item.customIconName }), onClick: () => {
                                                item.onClick?.();
                                                closeMobileMenu();
                                            } }, item.label)))] }) }) })) })] })] }));
};
export default Navigation;
