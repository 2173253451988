import type { CitizenModules } from '@izimi/modules';

import { connectionsModule } from './connections';
import { contactsModule } from './contacts';
import { documentsModule } from './documents';
import { historyLogModule } from './historyLog';
import { messageModule } from './message';
import { nabanModule } from './naban';
import { newsModule } from './news';
import { notaryModule } from './notary';
import { notificationsModule } from './notifications';
import { postalCodesModule } from './postalCodes';
import { publicModule } from './public';
import { termsAndConditionsModule } from './termsAndConditions';
import { userModule } from './user';
import { vaultModule } from './vault';

export const modules: CitizenModules = {
  connections: connectionsModule,
  documents: documentsModule,
  contacts: contactsModule,
  historyLog: historyLogModule,
  naban: nabanModule,
  notary: notaryModule,
  notifications: notificationsModule,
  postalCodes: postalCodesModule,
  termsAndConditions: termsAndConditionsModule,
  user: userModule,
  vault: vaultModule,
  public: publicModule,
  news: newsModule,
  userMessages: messageModule,
};
