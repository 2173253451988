import { useMutation, useQuery } from '@tanstack/react-query';
import { connectionsQueryKeys, useAddConnectionToCache, useRemoveConnectionFromCache, } from './cache';
const createConnectionsModule = ({ connectionsApi, }) => {
    const useConnections = () => {
        const { data, isLoading, error, refetch } = useQuery({
            queryKey: connectionsQueryKeys.getConnections(),
            queryFn: () => connectionsApi.getConnections(),
        });
        return {
            connections: data,
            error,
            isLoading,
            refetch,
        };
    };
    const useCreateConnection = () => {
        const addConnectionToCache = useAddConnectionToCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: values => connectionsApi.createConnection(values),
            onSuccess(connection) {
                addConnectionToCache(connection);
            },
        });
        return {
            createConnection: mutateAsync,
            error,
            isPending,
        };
    };
    const useDeleteConnection = () => {
        const removeConnectionFromCache = useRemoveConnectionFromCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: values => connectionsApi.deleteConnection(values),
            onSuccess(_, { connectionId }) {
                removeConnectionFromCache(connectionId);
            },
        });
        return {
            deleteConnection: mutateAsync,
            error,
            isPending,
        };
    };
    return {
        useConnections,
        useCreateConnection,
        useDeleteConnection,
    };
};
export default createConnectionsModule;
