import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Alert, AlertDescription, AlertTitle, Box, CloseButton, } from '@chakra-ui/react';
import FeatherIcon from '../icon/FeatherIcon';
const Message = ({ status, id, title, onClose, description, icon }) => {
    const { t } = useTranslation();
    const alertTitleId = typeof id !== 'undefined' ? `toast-${id}-title` : undefined;
    const isTitleString = typeof title === 'string';
    return (_jsxs(Alert, { status: status, variant: 'solid', id: id, alignItems: 'center', borderRadius: 'md', boxShadow: 'lg', paddingEnd: 8, textAlign: 'start', width: 'auto', "aria-labelledby": alertTitleId, color: 'white', backgroundColor: 'neutral600', children: [_jsx(FeatherIcon, { name: icon, size: 18 }), _jsxs(Box, { flex: '1', maxWidth: '100%', children: [_jsx(AlertTitle, { id: alertTitleId, px: 2, children: t(isTitleString ? title : title[0], isTitleString ? undefined : title?.[1]) }), description && (_jsx(AlertDescription, { display: 'block', children: description }))] }), _jsx(CloseButton, { size: 'sm', onClick: onClose, position: 'absolute', insetEnd: 1, top: 1 })] }));
};
export default Message;
