import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, chakra, Link, Text } from '@chakra-ui/react';
import { ApiNotificationType } from '@izimi/api';
import { buildQueryString } from '@izimi/utils';
import { CitizenDocumentsReceivedActions } from './CitizenDocumentsReceivedActions';
import { ContactRequestReceivedActions } from './ContactRequestReceivedActions';
import { DocumentsReceivedActions } from './DocumentsReceivedActions';
export const notificationActionItems = {
    [ApiNotificationType.SharedDocumentsReceived]: {
        actions: notification => (_jsxs(_Fragment, { children: [notification.data.message && notification.data.message.length > 0 ? (_jsx(Box, { p: 4, bgColor: 'primary100', borderRadius: 'md', mt: 4, mb: 4, children: _jsx(Text, { wordBreak: 'break-word', children: notification.data.message }) })) : null, _jsx(CitizenDocumentsReceivedActions, { notification: notification, navigateTo: '/sharing/from-contacts' })] })),
    },
    [ApiNotificationType.PushedDocumentsReceived]: {
        actions: notification => (_jsxs(_Fragment, { children: [notification.data.message && notification.data.message.length > 0 ? (_jsx(Box, { p: 4, bgColor: 'primary100', borderRadius: 'md', mt: 4, mb: 4, children: _jsx(Text, { wordBreak: 'break-word', children: notification.data.message }) })) : null, _jsx(CitizenDocumentsReceivedActions, { notification: notification, navigateTo: '/vault' })] })),
    },
    [ApiNotificationType.ContactRequestReceived]: {
        actions: notification => (_jsx(ContactRequestReceivedActions, { notification: notification })),
    },
    [ApiNotificationType.NotaryDocumentsReceived]: {
        messageComponents: (notification, routes) => [
            _jsx(chakra.span, { fontWeight: '600' }),
            _jsx(Link, { href: `${routes.VaultPending}${buildQueryString({
                    documentNames: notification.data.documentNames,
                    documentIds: notification.data.documentIds,
                    id: notification.data.documentIds[0],
                    message: notification.data.message,
                    notificationId: notification.id,
                })}`, fontWeight: '600', textDecoration: 'underline' }),
            _jsx(chakra.span, { fontWeight: '600' }),
        ],
        actions: notification => (_jsxs(_Fragment, { children: [notification.data.message && notification.data.message.length > 0 ? (_jsx(Box, { p: 4, bgColor: 'primary100', borderRadius: 'md', mt: 4, mb: 4, children: _jsx(Text, { wordBreak: 'break-word', children: notification.data.message }) })) : null, _jsx(DocumentsReceivedActions, { notification: notification })] })),
    },
    [ApiNotificationType.StudyPushedDocumentsReceived]: {
        messageComponents: (notification, routes) => [
            _jsx(chakra.span, { fontWeight: '600' }),
            _jsx(Link, { href: `${routes.VaultPending}${buildQueryString({
                    documentNames: notification.data.documentNames,
                    documentIds: notification.data.documentIds,
                    id: notification.data.documentIds[0],
                    message: notification.data.message,
                    notificationId: notification.id,
                })}`, fontWeight: '600', textDecoration: 'underline' }),
            _jsx(chakra.span, { fontWeight: '600' }),
        ],
        actions: notification => (_jsxs(_Fragment, { children: [notification.data.message && notification.data.message.length > 0 ? (_jsx(Box, { p: 4, bgColor: 'primary100', borderRadius: 'md', mt: 4, mb: 4, children: _jsx(Text, { wordBreak: 'break-word', children: notification.data.message }) })) : null, _jsx(DocumentsReceivedActions, { notification: notification })] })),
    },
    [ApiNotificationType.NotaryAutomaticallyClaimedVault]: {
        messageComponents: (notification, routes) => [
            _jsx(Link, { href: `${routes.VaultTransfer}${buildQueryString({
                    nrn: notification.data.nrn,
                })}`, fontWeight: '600', textDecoration: 'underline' }),
        ],
    },
};
