import { useCallback, useEffect } from 'react';

import { useModules } from '@izimi/modules';
import { useShowVaultTransferBadge } from 'common/stores/appSettingsStore';

export const useVaultTransferBadge = () => {
  const {
    vault: { useVaultPreferences },
  } = useModules();
  const { vaultPreferences, isLoading } = useVaultPreferences();
  const { setShowVaultTransferBadge, showVaultTransferBadge } =
    useShowVaultTransferBadge();

  useEffect(() => {
    const determineBadgeVisibility = async () => {
      if (!isLoading && !vaultPreferences?.afterDeath.confirmed) {
        setShowVaultTransferBadge(true);
        return;
      }
      setShowVaultTransferBadge(showVaultTransferBadge);
    };

    determineBadgeVisibility();
  }, [
    vaultPreferences,
    isLoading,
    setShowVaultTransferBadge,
    showVaultTransferBadge,
  ]);

  const setVaultTransferBadgeToSeen = useCallback(async () => {
    if (!showVaultTransferBadge) {
      return;
    }
    setShowVaultTransferBadge(false);
  }, [showVaultTransferBadge, setShowVaultTransferBadge]);

  return {
    showVaultTransferBadge,
    setVaultTransferBadgeToSeen,
  };
};
