import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPreferences, init, onPreferencesChanged } from 'cookie-though';
import { getConfig } from './cookieConfig';
export function useCookieConsent(cookieUrl) {
    const { t } = useTranslation('shared');
    const [preferences, setPreferences] = useState();
    useEffect(() => {
        if (cookieUrl) {
            init(getConfig(t, cookieUrl));
            setPreferences(getPreferences());
            // TODO: enable/disable tracking on preferences change
            onPreferencesChanged(setPreferences);
        }
        // t dep causes infinity loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookieUrl]);
    return { preferences };
}
