import { de, enGB, fr, nlBE } from 'date-fns/locale';
import { create } from 'zustand';
const mapLanguageToLocale = {
    en: enGB,
    fr,
    nl: nlBE,
    de,
};
const initialState = {
    locale: enGB,
    setLocale: () => { },
};
export const useLocaleStore = create()((set, get) => ({
    ...initialState,
    setLocale: locale => {
        if (mapLanguageToLocale[locale] !== get().locale) {
            set({ locale: mapLanguageToLocale[locale] });
        }
    },
}));
