export const buildQueryString = (query) => {
    if (query) {
        const qs = Object.entries(query)
            .filter(pair => pair[1] !== undefined)
            .map(pair => pair
            .filter(i => i !== null)
            .map(encodeURIComponent)
            .join('='))
            .join('&');
        return qs && `?${qs}`;
    }
    return '';
};
export const buildHeadersObject = (headers) => {
    if (headers) {
        const headersObject = Object.entries(headers)
            .filter(([, value]) => value !== undefined)
            .reduce((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});
        return headersObject;
    }
    return {};
};
