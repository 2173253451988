import { hex2rgba } from '@izimi/utils';
import { heading5 } from '../typography';
const ModalComponentStyle = {
    baseStyle: ({ theme }) => ({
        header: {
            ...heading5(),
            textAlign: 'center',
            paddingInlineStart: { base: 5, md: 8 },
            py: 5,
            paddingInlineEnd: 10,
            borderBottomWidth: 1,
            borderBottomColor: 'neutral100',
        },
        closeButton: {
            top: 4,
            insetEnd: { base: 2 },
        },
        body: {
            px: { base: 5, md: 8 },
            py: 8,
        },
        footer: {
            px: { base: 5, md: 8 },
            pt: 0,
            pb: { base: 5, md: 8 },
        },
        overlay: {
            bg: hex2rgba(theme.colors.neutral600, 0.4),
        },
        dialog: {
            mt: { base: 2, md: 14 },
            mx: 2,
            borderRadius: '2xl',
            boxShadow: '0px 16px 32px rgba(61, 56, 54, 0.04), 0px 24px 64px rgba(61, 56, 54, 0.2)',
        },
    }),
    defaultProps: {
        size: 'lg',
    },
    variants: {
        bottomSheet({ scrollBehavior }) {
            return {
                dialogContainer: {
                    alignItems: 'flex-end',
                    height: 'auto',
                    bottom: 0,
                    '@supports(min-height: -webkit-fill-available)': {
                        height: 'revert',
                    },
                },
                dialog: {
                    marginBottom: 0,
                    marginTop: 0,
                    mx: 0,
                    maxWidth: 'none',
                    borderBottomRadius: 0,
                    maxH: scrollBehavior === 'inside' ? 'calc(100% - 2rem)' : undefined,
                },
            };
        },
        headerImage: {
            body: {
                paddingInline: { base: 0, md: 0 },
                padding: 0,
            },
            closeButton: {
                zIndex: 'overlay',
                color: 'white',
            },
        },
    },
};
export default ModalComponentStyle;
