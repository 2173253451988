import type { SVGAttributes } from 'react';
import type { ChakraProps } from '@chakra-ui/react';
import { chakra } from '@chakra-ui/react';

type Props = Omit<SVGAttributes<SVGElement>, keyof ChakraProps> & ChakraProps;

const IzimiLogoSmall = (props: Props) => (
  <chakra.svg
    width='29px'
    height='32px'
    viewBox='0 0 29 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M19.582 5.489a5.492 5.492 0 1 1-10.984.008 5.492 5.492 0 0 1 10.984-.008Z'
      fill='#E58F38'
    />
    <path
      d='m14.078 20.618 4.159 3.928-3.966 4.07a.216.216 0 0 1-.17.08.221.221 0 0 1-.17-.08l-3.846-4.305 3.993-3.693ZM7.469 21.414l-.03 9.82a.755.755 0 0 1-.233.54.803.803 0 0 1-.558.222h-5.26a.803.803 0 0 1-.557-.223.755.755 0 0 1-.233-.54v-16.76l6.87 6.941ZM27.958 14.473V31.23a.747.747 0 0 1-.233.543.793.793 0 0 1-.56.223h-5.132a.806.806 0 0 1-.558-.225.759.759 0 0 1-.232-.54v-9.784l6.715-6.974Z'
      fill='#C95C2B'
    />
    <path
      d='M.59 7.34v7.133l6.879 6.94 6.458 7.206-3.842-4.308 4.008-3.693L.927 7.304c-.147-.183-.298-.22-.336.037Z'
      fill='#E58F38'
    />
    <path
      d='m27.618 7.304-13.54 13.314 4.159 3.927 3.025-3.098 6.704-6.967V7.34c-.038-.256-.19-.22-.34-.036'
      fill='#E58F38'
    />
  </chakra.svg>
);

export default IzimiLogoSmall;
