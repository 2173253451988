import { heading6 } from '../typography';
import buttonStyleConfig from './button';
const MenuComponentStyle = {
    baseStyle: {
        list: {
            boxShadow: '0px 4px 8px rgba(61, 56, 54, 0.06), 0px 8px 16px rgba(61, 56, 54, 0.06), 0px 12px 32px rgba(61, 56, 54, 0.12)',
            borderRadius: 'lg',
            border: 'none',
            zIndex: 'popover',
            minWidth: '250px',
        },
        item: {
            ...heading6(),
            px: 5,
            py: 3,
            color: 'neutral500',
            _focus: {
                bg: 'neutral100',
            },
            _active: {
                bg: 'neutral200',
            },
        },
    },
    variants: {
        documentTableMenu: {
            button: {
                ...buttonStyleConfig.variants?.action,
            },
        },
    },
};
export default MenuComponentStyle;
