import { useQuery } from '@tanstack/react-query';
const STALE_30_MINUTES = 1800000;
const createPostalCodesModule = ({ postalCodesApi, }) => {
    const usePostalCodes = (postalCodeOrName, enabled = true) => {
        const { data, isLoading, error, isSuccess } = useQuery({
            queryKey: ['postalCodes', { postalCodeOrName }],
            queryFn: () => postalCodesApi.getPostalCodes({ postalCodeOrName }),
            staleTime: STALE_30_MINUTES,
            enabled,
        });
        return {
            postalCodes: data,
            error,
            isLoading,
            isSuccess,
        };
    };
    return {
        usePostalCodes,
    };
};
export default createPostalCodesModule;
