const userMessages = {
    startAccountDeactivation() {
        return {
            errorMessage: {
                message: 'messages.user.startAccountDeactivationFailed',
            },
        };
    },
    cancelAccountDeactivation() {
        return {
            errorMessage: {
                message: 'messages.user.cancelAccountDeactivationFailed',
            },
        };
    },
    completeAccountDeactivation() {
        return {
            errorMessage: {
                message: 'messages.user.completeAccountDeactivationFailed',
            },
        };
    },
    registerUser() {
        return {
            errorMessage: {
                message: 'messages.user.registerUserFailed',
            },
        };
    },
    markUserDeceased() {
        return {
            errorMessage: {
                message: 'messages.user.markUserDeceasedFailed',
            },
        };
    },
    unmarkUserDeceased() {
        return {
            errorMessage: {
                message: 'messages.user.unmarkUserDeceasedFailed',
            },
        };
    },
    changeUserEmail() {
        return {
            successMessage: {
                message: 'messages.user.changeUserEmailSucceeded',
            },
            errorMessage: {
                message: 'messages.user.changeUserEmailFailed',
            },
        };
    },
    changeUserAddress() {
        return {
            successMessage: {
                message: 'messages.user.changeUserAddressSucceeded',
            },
            errorMessage: {
                message: 'messages.user.changeUserAddressFailed',
            },
        };
    },
    languageChanged() {
        return {
            successMessage: {
                message: 'messages.user.languageChanged',
            },
        };
    },
    mailConfigurationChanged() {
        return {
            errorMessage: {
                message: 'messages.user.mailConfigurationChangedFailed',
            },
            successMessage: {
                message: 'messages.user.mailConfigurationChangedSuccess',
            },
        };
    },
};
export default userMessages;
