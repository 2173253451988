import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import NabanQueryKeys from './NabanQueryKeys';
export const nabanQueryKeys = new NabanQueryKeys('naban');
export const useInvalidateNabanDocumentsCache = () => {
    const queryClient = useQueryClient();
    const invalidateNabanDocumentsCache = useCallback(() => queryClient.invalidateQueries({
        queryKey: nabanQueryKeys.getNabanDocuments(),
    }), [queryClient]);
    return invalidateNabanDocumentsCache;
};
export const useInvalidateNabanDocumentCache = () => {
    const queryClient = useQueryClient();
    const invalidateNabanDocumentCache = useCallback(({ nabanDocumentId, }) => {
        queryClient.invalidateQueries({
            queryKey: nabanQueryKeys.getNabanDocument(nabanDocumentId),
        });
    }, [queryClient]);
    return invalidateNabanDocumentCache;
};
