const AccordionComponentStyle = {
    baseStyle: {
        root: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        container: {
            borderColor: 'neutral100',
        },
        button: {
            _hover: {
                backgroundColor: 'primary000',
            },
            _focus: {
                outline: 'none',
                border: 'none',
                boxShadow: 'none',
                backgroundColor: 'primary000',
            },
        },
    },
};
export default AccordionComponentStyle;
