const ListComponentStyle = {
    variants: {
        selectList: {
            container: {
                position: 'absolute',
                width: '100%',
                backgroundColor: 'white',
                borderBottomRadius: 'lg',
                borderWidth: 0,
                borderColor: 'transparent',
                boxShadow: 'none',
                borderTop: 'none',
                color: 'neutral500',
                overflow: 'auto',
                zIndex: 'popover',
                outline: 'none',
            },
            item: {
                display: 'flex',
                alignItems: 'center',
                px: 3,
                borderBottomWidth: 1,
                borderBottomColor: 'neutral100',
                backgroundColor: 'transparent',
                _hover: {
                    backgroundColor: 'primary100',
                },
                _last: {
                    borderBottom: 'none',
                },
            },
        },
    },
};
export default ListComponentStyle;
