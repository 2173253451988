import { buildQueryString } from '@izimi/utils';
class NewsApi {
    providers;
    apiUrl;
    defaultParams;
    constructor(providers) {
        this.providers = providers;
        this.defaultParams = {
            api_key: this.providers.getApiKey(),
        };
    }
    async get(path) {
        const apiUrl = this.providers.getApiUrl();
        if (!apiUrl) {
            throw new Error('The api url is undefined');
        }
        const res = await fetch(`${apiUrl}${path}`, {
            method: 'GET',
            headers: {
                'Accept-Language': this.providers.getLanguage(),
            },
        });
        if (!res.ok) {
            throw new Error(`Newsfeed API Error ${res.status} ${res.statusText}`);
        }
        const text = await res.text();
        try {
            const json = JSON.parse(text);
            return json;
        }
        catch {
            return text;
        }
    }
    getNewsFeed(params) {
        const qs = buildQueryString({ ...this.defaultParams, ...params });
        return this.get(`/all${qs}`);
    }
    getNewsItem({ type, id }) {
        let fixedType = type;
        if (type === 'brochure') {
            fixedType = 'brochures';
        }
        if (type === 'video') {
            fixedType = 'videos';
        }
        const qs = buildQueryString({ ...this.defaultParams });
        return this.get(`/${fixedType}/${id}${qs}`);
    }
}
export default NewsApi;
