import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import NotaryQueryKeys from './NotaryQueryKeys';
export const notaryQueryKeys = new NotaryQueryKeys('notary');
export const useInvalidatePreferredNotaryCache = () => {
    const queryClient = useQueryClient();
    const invalidatePreferredNotaryCache = useCallback(() => queryClient.invalidateQueries({
        queryKey: notaryQueryKeys.getPreferredNotary(),
    }), [queryClient]);
    return invalidatePreferredNotaryCache;
};
export const useInvalidateNotaryClaimedVaultsCache = () => {
    const queryClient = useQueryClient();
    const invalidateNotaryClaimedVaultsCache = useCallback(() => queryClient.invalidateQueries({
        queryKey: notaryQueryKeys.getNotaryClaimedVaults({}),
    }), [queryClient]);
    return invalidateNotaryClaimedVaultsCache;
};
export const useInvalidateNotaryHeirsCache = () => {
    const queryClient = useQueryClient();
    const invalidateNotaryHeirsCache = useCallback((vaultId) => queryClient.invalidateQueries({
        queryKey: notaryQueryKeys.getNotaryHeirs({ vaultId }),
    }), [queryClient]);
    return invalidateNotaryHeirsCache;
};
export const useSetNotaryMailConfigurationCache = () => {
    const queryClient = useQueryClient();
    const setUserMailConfiguration = useCallback((mailConfiguration) => queryClient.setQueryData(notaryQueryKeys.getNotaryMailConfiguration(), mailConfiguration), [queryClient]);
    return setUserMailConfiguration;
};
