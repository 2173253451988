const DropzoneComponentStyle = {
    parts: ['container', 'illustration', 'text'],
    baseStyle: {
        container: {
            backgroundColor: 'categoryAll200',
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            opacity: 0.95,
            zIndex: 'tooltip',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        text: {
            color: 'white',
            textAlign: 'center',
            fontWeight: 400,
            pointerEvents: 'none',
        },
        illustration: {
            color: 'categoryAll300',
        },
    },
};
export default DropzoneComponentStyle;
