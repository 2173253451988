const SwitchComponentStyle = {
    baseStyle: {
        container: {},
        track: {
            bg: 'neutral200',
            _focus: {
                boxShadow: 'none',
            },
            _focusVisible: {
                boxShadow: 'outline',
            },
            _checked: {
                bg: 'primary400',
            },
        },
        thumb: {},
    },
};
export default SwitchComponentStyle;
