class VaultQueryKeys {
    prefix;
    constructor(prefix) {
        this.prefix = prefix;
    }
    getVaultInfo() {
        return [this.prefix, 'info'];
    }
    getPreferences() {
        return [this.prefix, 'preferences'];
    }
}
export default VaultQueryKeys;
