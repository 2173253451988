const notaryApiUrl = {
    local: 'http://localhost:8080',
    development: [
        'https://notary-dev-certinot.credoc.be',
        'https://dev.notary-app.izimi.be',
    ],
    test: [
        'https://notary-test-certinot.credoc.be',
        'https://tst.notary-app.izimi.be',
    ],
    acc: ['https://notary-acc.izimi.be', 'https://acc.notary-app.izimi.be'],
    production: 'https://izimi.fednot.be',
};
const citizenApiUrl = {
    local: 'http://localhost:8080',
    development: ['https://dev-certinot.credoc.be', 'https://dev.app.izimi.be'],
    test: ['https://test-certinot.credoc.be', 'https://tst.app.izimi.be'],
    acc: ['https://acc.izimi.be', 'https://acc.app.izimi.be'],
    production: 'https://app.izimi.be',
};
const apiUrls = {
    local: [notaryApiUrl.local, citizenApiUrl.local],
    development: [...notaryApiUrl.development, ...citizenApiUrl.development],
    test: [...notaryApiUrl.test, ...citizenApiUrl.test],
    acc: [...notaryApiUrl.acc, ...citizenApiUrl.acc],
    production: [notaryApiUrl.production, citizenApiUrl.production],
};
const appBasePath = window.location.origin;
const getAppEnv = Object.keys(apiUrls).find(key => {
    if (key === 'local') {
        return appBasePath.includes('http://localhost');
    }
    return apiUrls[key].includes(appBasePath);
});
// Deciding the environment based on the base path
const viteEnv = import.meta.env.VITE_APP_ENV;
export const appEnv = viteEnv ?? getAppEnv ?? 'development';
/**
 * Returns the citizen API url based on the environment.
 */
export const getCitizenApiUrl = (env) => {
    switch (env) {
        case 'production':
            return citizenApiUrl.production;
        case 'local':
            return citizenApiUrl.local;
        case 'acc':
        case 'test':
        case 'development':
        default:
            return (citizenApiUrl[env].find(url => appBasePath.includes(url)) ??
                citizenApiUrl.development[0]);
    }
};
/**
 * Returns the notary API url based on the environment.
 */
export const getNotaryApiUrl = (env) => {
    switch (env) {
        case 'production':
            return notaryApiUrl.production;
        case 'local':
            return notaryApiUrl.local;
        case 'acc':
        case 'test':
        case 'development':
        default:
            return (notaryApiUrl[env].find(url => appBasePath.includes(url)) ??
                notaryApiUrl.development[0]);
    }
};
