import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Button, HStack } from '@chakra-ui/react';
import { eachYearOfInterval, getMonth, getYear, parse, startOfYear, } from 'date-fns';
import { FIRST_OF_JANUARY_2019 } from '@izimi/modules';
import { CustomIcon } from '../../icon';
import Select from '../../select/Select';
const years = eachYearOfInterval({
    start: startOfYear(FIRST_OF_JANUARY_2019),
    end: new Date(),
}).map(yearDate => {
    const year = yearDate.getFullYear();
    return { label: year, value: year };
});
export const DatePickerHeader = ({ date, decreaseMonth, increaseMonth, changeMonth, changeYear, formatDate, prevMonthButtonDisabled, nextMonthButtonDisabled, }) => {
    const selectedYear = years.find(year => year.value === getYear(date)) ?? years[0];
    const months = useMemo(() => {
        return Array.from({ length: 12 }, (_, i) => {
            const monthDate = parse(`${i + 1}`, 'M', new Date());
            return { label: formatDate(monthDate, 'MMMM'), value: i };
        });
    }, [formatDate]);
    return (_jsxs(HStack, { paddingBottom: 3, spacing: 1, justify: 'space-between', children: [_jsx(Button, { variant: 'link', minW: 'min-content', isDisabled: prevMonthButtonDisabled, children: _jsx(CustomIcon, { name: 'chevron-left', cursor: 'pointer', onClick: decreaseMonth, color: 'neutral600' }) }), _jsx(Select, { items: months, onSelectedItemChange: item => item?.value !== undefined && changeMonth(item.value), selectedItem: months[getMonth(date)], width: 32, size: 'sm', buttonLabelStyle: {
                    fontWeight: '600',
                } }), _jsx(Select, { items: years, onSelectedItemChange: item => item?.value && changeYear(item.value), selectedItem: selectedYear, size: 'sm', buttonLabelStyle: {
                    fontWeight: '600',
                } }), _jsx(Button, { variant: 'link', minW: 'min-content', isDisabled: nextMonthButtonDisabled, children: _jsx(CustomIcon, { name: 'chevron-right', cursor: 'pointer', onClick: increaseMonth, color: 'neutral600' }) })] }));
};
