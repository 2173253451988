class PublicQueryKeys {
    prefix = 'public';
    getConfiguration() {
        return [this.prefix, 'configuration'];
    }
    getMailUnsubscribeInfo(token) {
        return [this.prefix, 'mail', 'unsubscribe', 'info', token];
    }
}
export default PublicQueryKeys;
