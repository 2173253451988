export const createConfigurationLinks = (configuration, language, url) => {
    const baseUrl = `${url}/${language}`;
    const privacyPolicyPaths = configuration?.privacyPolicyPaths;
    const signingPolicyPaths = configuration?.signingPolicyPaths;
    const ssnFAQPaths = configuration?.ssnFAQPaths;
    const cookiePolicyPaths = configuration?.cookiePolicyPaths;
    const marketingMailOptPaths = configuration?.marketingMailOptPaths;
    const privacyUrl = `${baseUrl}${privacyPolicyPaths?.[language]}`;
    const cookieUrl = `${baseUrl}${cookiePolicyPaths?.[language]}`;
    const marketingMailUrl = `${baseUrl}${marketingMailOptPaths?.[language]}`;
    const termsAndConditionsUrl = `${baseUrl}/terms-and-conditions`;
    // the signing policy has to go to izimi.be on all front-ends
    const signingPolicyUrl = `https://izimi.be/${language}${signingPolicyPaths?.[language]}`;
    const ssnFAQurl = `https://izimi.be/${language}${ssnFAQPaths?.[language]}`;
    return {
        privacyUrl,
        cookieUrl,
        marketingMailUrl,
        termsAndConditionsUrl,
        signingPolicyUrl,
        ssnFAQurl,
    };
};
