import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, HStack } from '@chakra-ui/react';
import { documentsMessages, useModules } from '@izimi/modules';
import { buildQueryString } from '@izimi/utils';
import { withMessages } from '../../messages';
export const DocumentsReceivedActions = ({ notification }) => {
    const { t } = useTranslation('notifications');
    const { notifications: { useDeleteNotification }, documents: { useAcceptRejectSharedDocuments }, } = useModules();
    const { acceptRejectSharedDocuments, isPending: isLoadingAcceptDocuments } = useAcceptRejectSharedDocuments();
    const { deleteNotification, isPending: isLoadingDeleteNotification } = useDeleteNotification();
    const navigate = useNavigate();
    const { documentNames, documentIds } = notification.data;
    const documents = documentIds.map((id, index) => ({
        documentName: documentNames[index],
        documentId: id,
    }));
    const handleAcceptSingleDocument = async () => {
        await withMessages(acceptRejectSharedDocuments({
            acceptedDocuments: documents,
            rejectedDocuments: [],
        }))(documentsMessages.acceptDocuments(documents.length));
        deleteNotification(notification.id);
    };
    const handleRejectSingleDocument = async () => {
        await withMessages(acceptRejectSharedDocuments({
            acceptedDocuments: [],
            rejectedDocuments: documents,
        }))(documentsMessages.rejectDocuments(documents.length));
        deleteNotification(notification.id);
    };
    const handleNavigatePendingPreview = useCallback(() => {
        const qs = buildQueryString({
            documentNames,
            documentIds,
            id: documentIds[0],
            message: notification.data.message,
            notificationId: notification.id,
        });
        navigate(`/vault/pending${qs}`, { replace: true });
    }, [documentIds, documentNames, navigate, notification]);
    if (notification.data.documentIds.length > 1) {
        return (_jsx(HStack, { mt: 2, children: _jsx(Button, { variant: 'primary500', flex: 1, onClick: handleNavigatePendingPreview, children: t('watch') }) }));
    }
    return (_jsxs(HStack, { mt: 2, children: [_jsx(Button, { variant: 'primary500', flex: 1, onClick: handleAcceptSingleDocument, isDisabled: isLoadingAcceptDocuments || isLoadingDeleteNotification, children: t('accept') }), _jsx(Button, { variant: 'primary100', flex: 1, onClick: handleRejectSingleDocument, isDisabled: isLoadingAcceptDocuments || isLoadingDeleteNotification, children: t('reject') })] }));
};
