/**
 * Omits the timezone offset from a date.
 * This way, selecting a date in in the datepicker doesn't end up selecting the previous day.
 * Before it would select 22:00 the previous day (GMT+2), now it selects 00:00 of the selected day.
 */
export const convertLocalDateToUTCDate = (date) => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
/**
 * Is needed to make sure date-fns calculations happen in the timezone of the user
 */
export const convertUTCToLocalDate = (date) => new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
