export const getUserRole = (roles) => {
    const role = Array.isArray(roles) ? roles[0] : roles;
    return role;
};
export const isUserRole = (role, roles) => {
    if (roles == null || (Array.isArray(roles) && roles.length === 0)) {
        return false;
    }
    const userRole = getUserRole(roles);
    return userRole === role;
};
