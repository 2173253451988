export const arrayDifference = (array1, array2) => {
    const difference = [];
    for (let i = 0; i < array1.length; i++) {
        if (!array2.includes(array1[i])) {
            difference.push(array1[i]);
        }
    }
    for (let i = 0; i < array2.length; i++) {
        if (!array1.includes(array2[i])) {
            difference.push(array2[i]);
        }
    }
    return difference;
};
export const intersection = (a, b) => a.filter(x => b.includes(x));
export const getElementsNotInSecondArray = (a, b) => {
    const setOfB = new Set(b);
    return a.filter(x => !setOfB.has(x));
};
