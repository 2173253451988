import { useMutation } from '@tanstack/react-query';
export const createUserMessagesModule = ({ userMessagesApi, }) => {
    /**
     * Manual query for user messages.
     * Not using @tanstack/react-query because this is a one-off query. We don't need to cache or refetch it.
     */
    const useGetUserMessages = () => {
        const fetchUserMessages = async (language) => userMessagesApi.getUserMessages(language);
        return { fetchUserMessages };
    };
    const useAcknowledgeUserMessage = () => {
        const { mutateAsync, isPending } = useMutation({
            mutationFn: messageId => userMessagesApi.acknowledgeUserMessage(messageId),
        });
        return { acknowledgeUserMessage: mutateAsync, isPending };
    };
    return {
        useGetUserMessages,
        useAcknowledgeUserMessage,
    };
};
