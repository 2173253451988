function RealestateIcon({ size = 32 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
    >
      <rect x={2} width={28} height={26} rx={4} fill='#B42561' />
      <rect y={2} width={32} height={30} rx={4} fill='#EE4B68' />
      <rect
        y={2}
        width={32}
        height={30}
        rx={4}
        fill='url(#a)'
        fillOpacity={0.2}
        style={{
          mixBlendMode: 'multiply',
        }}
      />
      <path d='M13 13V9a3 3 0 1 1 6 0v4' stroke='#AF213C' strokeWidth={2} />
      <rect x={8} y={11} width={16} height={16} rx={8} fill='#F7D7DD' />
      <rect x={8} y={11} width={16} height={16} rx={8} fill='url(#b)' />
      <path
        opacity={0.8}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.022 19.33a2.286 2.286 0 1 0-2.044 0L13.142 23h5.714l-1.835-3.67Z'
        fill='#AF213C'
      />
      <defs>
        <linearGradient
          id='a'
          x1={16}
          y1={10}
          x2={16}
          y2={32}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#890D57' stopOpacity={0} />
          <stop offset={1} stopColor='#890D57' />
        </linearGradient>
        <linearGradient
          id='b'
          x1={16}
          y1={11}
          x2={16}
          y2={27}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default RealestateIcon;
