import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { appEnv } from '@izimi/core';
const userStorageKey = `auth:${appEnv}`;
const userStoragePrefix = 'oidc.';
export const getUserFromStorage = () => {
    const oidcStorage = localStorage.getItem(`${userStoragePrefix}${userStorageKey}`);
    if (!oidcStorage) {
        return undefined;
    }
    const user = User.fromStorageString(oidcStorage);
    if (user.expired) {
        localStorage.removeItem(`${userStoragePrefix}${userStorageKey}`);
        return undefined;
    }
    return user;
};
export const getSessionExpiredFromStorage = () => {
    const key = `auth:sessionExpired`;
    const sessionExpired = localStorage.getItem(key);
    return {
        sessionExpired: sessionExpired === 'true',
        removeSessionExpiredFromStorage: () => localStorage.removeItem(key),
    };
};
export const createUserManager = (auth, apiUrl) => {
    const authority = auth.loginUrl;
    const redirectUrl = appEnv === 'local' || appEnv === 'development' || appEnv === 'test'
        ? window.location.origin
        : apiUrl;
    const tokenEndpoint = appEnv === 'local'
        ? auth.tokenEndpoint
        : auth.tokenEndpoint.replace(apiUrl, '');
    const userInfoEndpoint = `${appEnv === 'local' ? apiUrl : ''}/api/user`;
    const endSessionEndpoint = `${apiUrl}${auth.logoutUrl}`;
    const userManagerSettings = {
        getUserStoreKey: () => userStorageKey,
        automaticSilentRenew: false,
        scope: auth.scope,
        client_id: auth.clientId,
        client_secret: auth.clientSecret,
        redirect_uri: redirectUrl,
        post_logout_redirect_uri: redirectUrl,
        authority,
        metadata: {
            authorization_endpoint: authority,
            token_endpoint: tokenEndpoint,
            userinfo_endpoint: userInfoEndpoint,
            end_session_endpoint: endSessionEndpoint,
        },
    };
    return new UserManager({
        loadUserInfo: true,
        userStore: new WebStorageStateStore({
            prefix: userStoragePrefix,
            store: window.localStorage,
        }),
        ...userManagerSettings,
    });
};
export const hasAuthParams = (location = window.location) => {
    let searchParams = new URLSearchParams(location.search);
    if ((searchParams.get('code') || searchParams.get('error')) &&
        searchParams.get('state')) {
        return true;
    }
    searchParams = new URLSearchParams(location.hash.replace('#', '?'));
    if ((searchParams.get('code') || searchParams.get('error')) &&
        searchParams.get('state')) {
        return true;
    }
    return false;
};
export const normalizeErrorFn = (fallbackMessage) => (error) => {
    if (error instanceof Error) {
        return error;
    }
    return new Error(fallbackMessage);
};
export const unsupportedEnvironment = (fnName) => () => {
    throw new Error(`UserManager#${fnName} was called from an unsupported context.`);
};
