import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { useModules } from '@izimi/modules';
import { CustomIcon } from '../icon';
import { Spinner } from '../Spinner/Spinner';
import { notificationActionItems } from './actions';
import NotificationItem from './NotificationItem';
import { NotificationPopOverBodyWrapper } from './NotificationPopOverBodyWrapper';
import { UnsupportedNotification } from './UnsupportedNotification';
export const NotificationPopOverBody = ({ routes, }) => {
    const { t } = useTranslation('notifications');
    const { notifications: { usePagedNotifications }, } = useModules();
    const { getNotificationItems, notifications, fetchNextPage, isFetchingNextPage, isLoading, refetch, hasNextPage, } = usePagedNotifications({ pageSize: 10 });
    const notificationItems = getNotificationItems();
    const renderItem = useCallback(({ type, ...notification }) => {
        const notificationItem = notificationItems[type];
        if (notificationItem) {
            return (_jsx(NotificationItem, { notification: notification, Icon: _jsx(CustomIcon, { ...notificationItems[type].icon }), messageValues: 
                // @ts-expect-error too complex for ts
                notificationItems[type].messageValues(notification), messageKey: notificationItems[type].messageKey, messageComponents: notificationActionItems[type]?.messageComponents
                    ? // @ts-expect-error too complex for ts
                        notificationActionItems[type]?.messageComponents(notification, routes)
                    : undefined, onDeleteNotification: refetch, children: notificationActionItems[type]?.actions
                    ? // @ts-expect-error too complex for ts
                        notificationActionItems[type]?.actions(notification)
                    : null }, notification.id));
        }
        return _jsx(UnsupportedNotification, { type: type }, notification.id);
    }, [notificationItems, refetch, routes]);
    const endReached = useCallback(() => {
        fetchNextPage();
    }, [fetchNextPage]);
    const [infiniteRef] = useInfiniteScroll({
        loading: isFetchingNextPage,
        hasNextPage: Boolean(hasNextPage),
        onLoadMore: endReached,
        rootMargin: '0px 0px 400px 0px',
    });
    if (!notifications?.length) {
        return (_jsx(NotificationPopOverBodyWrapper, { bodyOverflow: 'hidden', children: _jsx(Center, { py: 6, children: isLoading ? _jsx(Spinner, {}) : _jsx(Text, { children: t('notifications:empty') }) }) }));
    }
    return (_jsxs(NotificationPopOverBodyWrapper, { bodyOverflow: 'scroll', children: [notifications &&
                notifications?.length > 0 &&
                notifications.map(renderItem), _jsxs(Flex, { justifyContent: 'center', children: [isFetchingNextPage && _jsx(Spinner, {}), !isFetchingNextPage && !isLoading && _jsx(Box, { ref: infiniteRef })] })] }));
};
