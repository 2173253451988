import type { ComponentType, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import type { User } from 'oidc-client-ts';

import type { ApiUser } from '@izimi/api';
import { ApiAccessLevel } from '@izimi/api';
import { AuthManager, useAuth } from '@izimi/auth';
import { locales } from '@izimi/core';
import { useModules } from '@izimi/modules';
import { useCheckUserMessages } from 'common/hooks';
import config from 'core/config';

import { Routes } from '../navigation/types';

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const {
    public: { useWebConfiguration },
  } = useModules();
  const { configuration } = useWebConfiguration();
  const { i18n } = useTranslation();
  const { checkUserMessages } = useCheckUserMessages();

  const onSigninCallback = async (user: User | void) => {
    if (isNotRegistered(user?.profile)) {
      navigate(Routes.Registration, { replace: true });
    } else {
      if (
        user?.profile?.language &&
        typeof user?.profile?.language === 'string' &&
        locales.includes(user?.profile?.language) &&
        i18n.language !== user?.profile?.language
      ) {
        // after sign-in set the ui interface as configured by api
        i18n.changeLanguage(user.profile.language);
      }
      if (isDeactivated(user?.profile)) {
        navigate(Routes.Deactivation, { replace: true });
      } else {
        navigate(Routes.Home, { replace: true });
        if (user?.profile?.language) {
          await checkUserMessages(user.profile.language);
        }
      }
    }
  };

  return (
    <AuthManager
      apiUrl={config.apiUrl}
      configuration={configuration?.authentication}
      onSigninCallback={onSigninCallback}
    >
      {children}
    </AuthManager>
  );
};

const isNotRegistered = (user: ApiUser | undefined) =>
  user?.accessLevel === ApiAccessLevel.NotRegistered;

const isRegistered = (user: ApiUser | undefined) =>
  user?.accessLevel !== ApiAccessLevel.NotRegistered;

const isDeactivated = (user: ApiUser | undefined) =>
  user?.deactivationRequested;

export const withRedirectToHome = <T,>(Component: ComponentType<T>) => {
  return (props: T) => {
    const auth = useAuth();

    if (auth.isAuthenticated) {
      if (isDeactivated(auth.user?.profile)) {
        return <Navigate to={Routes.Deactivation} replace />;
      }
      if (isNotRegistered(auth.user?.profile)) {
        return <Navigate to={Routes.Registration} replace />;
      }
      return <Navigate to={Routes.Home} replace />;
    }

    if (auth.error?.message === 'client.technical.freezeVault') {
      return <Navigate to={Routes.DeceasedUser} replace />;
    }

    return <Component {...props} />;
  };
};

export const withRedirectToLogin = <T,>(Component: ComponentType<T>) => {
  return (props: T) => {
    const location = useLocation();
    const auth = useAuth();
    const user = auth.user?.profile;

    if (!auth.isAuthenticated && !auth.sessionExpired) {
      return <Navigate to={Routes.Login} />;
    }

    if (location.pathname === Routes.Deactivation && !isDeactivated(user)) {
      return <Navigate to={Routes.Home} replace />;
    }

    if (location.pathname !== Routes.Deactivation && isDeactivated(user)) {
      return <Navigate to={Routes.Deactivation} replace />;
    }

    if (location.pathname === Routes.Registration && isRegistered(user)) {
      return <Navigate to={Routes.Home} replace />;
    }

    if (location.pathname !== Routes.Registration && isNotRegistered(user)) {
      return <Navigate to={Routes.Registration} replace />;
    }

    return <Component {...props} />;
  };
};

export const withRedirectPublicPage = <T,>(Component: ComponentType<T>) => {
  return (props: T) => {
    const auth = useAuth();

    if (auth.isAuthenticated) {
      if (isNotRegistered(auth.user?.profile)) {
        return <Navigate to={Routes.Registration} replace />;
      }
      return <Navigate to={Routes.Home} replace />;
    }

    return <Component {...props} />;
  };
};
