import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import NotificationsQueryKeys from './NotificationsQueryKeys';
export const notificationsQueryKeys = new NotificationsQueryKeys('notifications');
export const useSetPagedNotificationsCache = () => {
    const queryClient = useQueryClient();
    const setPagedNotificationsCache = useCallback((pagedNotifications) => queryClient.setQueryData(notificationsQueryKeys.getPagedNotifications(), pagedNotifications), [queryClient]);
    return setPagedNotificationsCache;
};
export const useGetPagedNotificationsCache = () => {
    const queryClient = useQueryClient();
    const getPagedNotificationsCache = useCallback(() => {
        const pagedNotifications = queryClient.getQueryData(notificationsQueryKeys.getPagedNotifications());
        return pagedNotifications;
    }, [queryClient]);
    return getPagedNotificationsCache;
};
export const useInvalidateHasUnreadNotificationsCache = () => {
    const queryClient = useQueryClient();
    const invalidateHasUnreadNotificationsCache = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: notificationsQueryKeys.hasUnreadNotifications(),
            exact: true,
        });
    }, [queryClient]);
    return invalidateHasUnreadNotificationsCache;
};
export const useInvalidateNotificationsCache = () => {
    const queryClient = useQueryClient();
    const invalidateNotificationsCache = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: notificationsQueryKeys.getPagedNotifications(),
            exact: true,
        });
    }, [queryClient]);
    return invalidateNotificationsCache;
};
