import { small } from '../typography';
const FormErrorComponentStyle = {
    baseStyle: {
        text: {
            ...small,
            color: 'error400',
        },
    },
};
export default FormErrorComponentStyle;
