import { useSearchParams } from 'react-router-dom';

import { ApiDocumentCategory } from '@izimi/api';

import useCategoryItems from './useCategoryItems';

const alllowedCategoryIds = Object.values(ApiDocumentCategory);

const useActiveCategory = () => {
  const [searchParams] = useSearchParams();
  const category = searchParams.get('category');
  const { categoryItems } = useCategoryItems();

  const queryCategory = category as ApiDocumentCategory | undefined;

  let activeCategory = ApiDocumentCategory.All;
  if (queryCategory && alllowedCategoryIds.includes(queryCategory)) {
    activeCategory = queryCategory;
  }

  return { activeCategory, activeCategoryItem: categoryItems[activeCategory] };
};

export default useActiveCategory;
