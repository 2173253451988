// May implement other preferences in the future, for now only afterDeath
class VaultPreferences {
    afterDeath;
    constructor(vaultPreferences) {
        this.afterDeath = vaultPreferences.afterDeath;
    }
    get isConfirmed() {
        return this.afterDeath.confirmed;
    }
    get isEnabled() {
        return this.afterDeath.transferTarget === 'HEIRS_AND_CONTACTS';
    }
    get isHeirsAll() {
        return this.afterDeath.heirsDocumentSelection.selectionType === 'ALL';
    }
    get oldHeirsDocumentIds() {
        return this.afterDeath.heirsDocumentSelection.documents.map(d => d.documentId);
    }
}
export default VaultPreferences;
