import { isSameDay } from 'date-fns';
import { create } from 'zustand';
import { FIRST_OF_JANUARY_2019 } from '@izimi/modules';
import { createStoreHook } from './helpers';
const filtersInitialState = {
    filters: {
        labels: [],
        ownerIds: [],
        receiverIds: [],
        sources: [],
        onlySoonToBeTrashed: false,
        afterCreationDate: FIRST_OF_JANUARY_2019,
        beforeCreationDate: new Date(),
    },
};
const createSlice = (type) => (set, get) => ({
    [type]: {
        ...filtersInitialState,
        setFilters: (filters) => set({ [type]: { ...get()[type], filters } }),
        resetFilters: () => set({
            [type]: { ...get()[type], filters: filtersInitialState.filters },
        }),
        hasActiveFilters: () => {
            const { filters } = get()[type];
            return (filters.labels.length > 0 ||
                filters.ownerIds.length > 0 ||
                filters.receiverIds.length > 0 ||
                filters.sources.length > 0 ||
                !isSameDay(filters.afterCreationDate, FIRST_OF_JANUARY_2019) ||
                !isSameDay(filters.beforeCreationDate, new Date()) ||
                filters.onlySoonToBeTrashed);
        },
        onlyUnseen: () => {
            const { filters } = get()[type];
            return !!filters.unSeen;
        },
    },
});
const createVaultSlice = createSlice('vault');
const createFromContactsSlice = createSlice('fromContacts');
const createByYouSlice = createSlice('byYou');
const useBoundStore = create()((...a) => {
    const [set, get] = a;
    return {
        ...createVaultSlice(...a),
        ...createFromContactsSlice(...a),
        ...createByYouSlice(...a),
        shared: {
            isSearchModeActive: false,
            setIsSearchModeActive: (isSearchModeActive) => set({ shared: { ...get().shared, isSearchModeActive } }),
            setSearchValue: (searchValue) => set({ shared: { ...get().shared, searchValue } }),
            searchValue: '',
            resetStore: () => {
                const { byYou, fromContacts, vault } = get();
                byYou.resetFilters();
                fromContacts.resetFilters();
                vault.resetFilters();
                set({
                    shared: {
                        ...get().shared,
                        searchValue: '',
                        isSearchModeActive: false,
                    },
                });
            },
        },
    };
});
export const useDocumentFiltersStore = (type) => {
    const store = useBoundStore();
    return {
        ...store[type],
        ...store.shared,
    };
};
const createStoreHookSelector = createStoreHook(useBoundStore);
export const useDocumentFiltersSearchValue = createStoreHookSelector(state => state.shared.searchValue);
