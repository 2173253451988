import type { SVGAttributes } from 'react';
import type { ChakraProps } from '@chakra-ui/react';
import { chakra } from '@chakra-ui/react';

type Props = Omit<SVGAttributes<SVGElement>, keyof ChakraProps> & ChakraProps;

const IzimiLogo = (props: Props) => (
  <chakra.svg
    width={142}
    height={53}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1.276 12.025h8.468c.34 0 .664.133.905.371.24.238.376.56.377.898v38.43a1.264 1.264 0 0 1-.791 1.175 1.283 1.283 0 0 1-.49.095H1.275a1.28 1.28 0 0 1-.902-.372A1.266 1.266 0 0 1 0 51.725V13.294c.002-.336.137-.658.376-.896.238-.237.562-.372.9-.373ZM17.022 52.393l18.255-31.15H21.393a1.29 1.29 0 0 1-.908-.375 1.274 1.274 0 0 1-.374-.906v-6.68c0-.339.135-.664.375-.904s.567-.376.907-.377h30.76c.36 0 .482.12.177.607L34.099 43.757h15.434c.34.001.667.137.907.377s.375.565.375.904v6.68c0 .339-.135.663-.375.902-.24.24-.567.374-.907.374H17.174c-.36 0-.482-.121-.177-.607M60.09 12.025h8.468c.338.001.661.136.9.373.24.238.374.56.376.896v38.43c0 .337-.135.66-.374.898a1.28 1.28 0 0 1-.902.372H60.09a1.274 1.274 0 0 1-1.181-.783 1.256 1.256 0 0 1-.095-.486V13.294c0-.337.134-.66.373-.898a1.28 1.28 0 0 1 .903-.371Z'
      fill='#50B2C2'
    />
    <path
      d='M132.25 12.025h8.468c.339 0 .664.133.904.371.241.238.376.56.378.898v38.43a1.255 1.255 0 0 1-.376.9 1.27 1.27 0 0 1-.906.37h-8.468c-.339 0-.663-.134-.903-.372a1.267 1.267 0 0 1-.373-.897V13.294c.001-.336.136-.658.375-.896.239-.237.563-.372.901-.373ZM109.656 9.091a9.12 9.12 0 0 1-1.527 5.056 9.075 9.075 0 0 1-4.071 3.353 9.05 9.05 0 0 1-9.89-1.97 9.12 9.12 0 0 1-1.966-9.917 9.092 9.092 0 0 1 3.345-4.082 9.054 9.054 0 0 1 11.451 1.133 9.112 9.112 0 0 1 2.658 6.427Z'
      fill='#E58F38'
    />
    <path
      d='m100.337 34.15 6.716 6.503-6.405 6.742a.349.349 0 0 1-.55 0l-6.209-7.13 6.448-6.116ZM89.664 35.467l-.049 16.264a1.266 1.266 0 0 1-.375.893 1.28 1.28 0 0 1-.9.37h-8.493a1.28 1.28 0 0 1-.9-.37 1.266 1.266 0 0 1-.376-.893V23.97l11.093 11.496ZM122.75 23.97v27.755a1.274 1.274 0 0 1-1.283 1.269h-8.285a1.284 1.284 0 0 1-.9-.374 1.274 1.274 0 0 1-.376-.895V35.522L122.75 23.97Z'
      fill='#C95C2B'
    />
    <path
      d='M78.558 12.158v11.813l11.106 11.496L100.092 47.4l-6.203-7.136 6.472-6.116-21.26-22.051c-.237-.304-.543-.356-.543.06Z'
      fill='#E58F38'
    />
    <path
      d='m122.2 12.098-21.863 22.051 6.716 6.504 4.884-5.131 10.825-11.54V12.159c-.061-.425-.305-.364-.55-.06'
      fill='#E58F38'
    />
  </chakra.svg>
);

export default IzimiLogo;
