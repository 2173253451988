class ContactsQueryKeys {
    prefix;
    constructor(prefix) {
        this.prefix = prefix;
    }
    getContacts(type, params) {
        if (params) {
            return [this.prefix, type, params];
        }
        return [this.prefix, type];
    }
}
export default ContactsQueryKeys;
