function PropertyIcon({ size = 32 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
    >
      <rect x={2} width={28} height={26} rx={4} fill='#A04600' />
      <rect y={2} width={32} height={30} rx={4} fill='#EE9226' />
      <rect
        y={2}
        width={32}
        height={30}
        rx={4}
        fill='url(#a)'
        fillOpacity={0.2}
        style={{
          mixBlendMode: 'multiply',
        }}
      />
      <path
        d='m11 7-3 4-1 13a2 2 0 0 0 2 2l14.086.086a2 2 0 0 0 2-2L24 11l-3-4H11Z'
        fill='#FCE9D4'
      />
      <path
        d='m11 7-3 4-1 13a2 2 0 0 0 2 2l14.086.086a2 2 0 0 0 2-2L24 11l-3-4H11Z'
        fill='#FCE9D4'
      />
      <path
        d='m11 7-3 4-1 13a2 2 0 0 0 2 2l14.086.086a2 2 0 0 0 2-2L24 11l-3-4H11Z'
        fill='url(#b)'
      />
      <path d='m8 11 3-4h10l3 4H8Z' fill='#A04600' />
      <path
        opacity={0.8}
        d='M20 15a4 4 0 1 1-8 0'
        stroke='#EE9226'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path fill='#D87518' d='M11 7h10v4H11z' />
      <defs>
        <linearGradient
          id='a'
          x1={16}
          y1={10}
          x2={16}
          y2={32}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#9D2C09' stopOpacity={0} />
          <stop offset={1} stopColor='#9D2C09' />
        </linearGradient>
        <linearGradient
          id='b'
          x1={16}
          y1={7}
          x2={16}
          y2={27}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PropertyIcon;
