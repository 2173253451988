import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState, } from 'react';
import { Event, EventRegister } from '@izimi/utils';
import { authReducer, getInitialState } from './authReducer';
import { createUserManager, hasAuthParams, normalizeErrorFn, unsupportedEnvironment, } from './utils';
const AuthContext = createContext(null);
const navigatorKeys = [
    'signinPopup',
    'signinSilent',
    'signinRedirect',
    'signoutPopup',
    'signoutRedirect',
];
export const logout = () => {
    EventRegister.emit(Event.Logout);
};
export const AuthManager = ({ children, onSigninCallback, configuration, apiUrl, }) => {
    const [userManager, setUserManager] = useState();
    const [state, dispatch] = useReducer(authReducer, getInitialState());
    useEffect(() => {
        if (configuration && apiUrl) {
            setUserManager(createUserManager(configuration, apiUrl));
        }
    }, [configuration, apiUrl]);
    useEffect(() => {
        if (!userManager || !state.user?.access_token) {
            return;
        }
        EventRegister.addEventListener(Event.Logout, () => {
            // TODO: this should eventually be replaced by an actual id_token, not the access token
            // This line ensures the user correctly logs out
            userManager.signoutRedirect({
                id_token_hint: state.user?.access_token,
                extraQueryParams: state.user?.expired ? { logout: true } : undefined,
            });
        });
    }, [userManager, state.user]);
    const userManagerContext = useMemo(() => {
        if (!userManager) {
            return;
        }
        return {
            settings: userManager.settings,
            events: userManager.events,
            ...Object.fromEntries(navigatorKeys.map(key => [
                key,
                userManager[key]
                    ? async (...args) => {
                        try {
                            return await userManager[key](...args);
                        }
                        catch (error) {
                            dispatch({ type: 'ERROR', error });
                        }
                    }
                    : unsupportedEnvironment(key),
            ])),
        };
    }, [userManager]);
    const didInitialize = useRef(false);
    useEffect(() => {
        if (!userManager || didInitialize.current) {
            return;
        }
        didInitialize.current = true;
        (async () => {
            try {
                if (hasAuthParams()) {
                    const user2 = await userManager.signinCallback();
                    onSigninCallback?.(user2);
                }
                const user = await userManager.getUser();
                dispatch({ type: 'INITIALISED', user });
            }
            catch (error) {
                dispatch({
                    type: 'ERROR',
                    error: normalizeErrorFn('unable to login')(error),
                });
            }
        })();
    }, [userManager, onSigninCallback, state.user]);
    useEffect(() => {
        if (!userManager) {
            return;
        }
        const handleUserLoaded = (user) => {
            dispatch({ type: 'USER_LOADED', user });
        };
        userManager.events.addUserLoaded(handleUserLoaded);
        const handleUserUnloaded = () => {
            dispatch({ type: 'USER_UNLOADED' });
        };
        userManager.events.addUserUnloaded(handleUserUnloaded);
        const handleSilentRenewError = (error) => {
            dispatch({ type: 'ERROR', error });
        };
        userManager.events.addSilentRenewError(handleSilentRenewError);
        const handleAccessTokenExpiring = () => {
            // This handles the automatic logout 60s before the access token expires
            // We do this because we need to logout with the access token still valid
            // The logout parameter is added so backend knows it's an "auto logout"
            userManager?.signoutRedirect({
                id_token_hint: state.user?.access_token,
                extraQueryParams: { logout: true },
            });
            dispatch({ type: 'SESSION_EXPIRED' });
        };
        userManager.events.addAccessTokenExpiring(handleAccessTokenExpiring);
        return () => {
            userManager.events.removeUserLoaded(handleUserLoaded);
            userManager.events.removeUserUnloaded(handleUserUnloaded);
            userManager.events.removeSilentRenewError(handleSilentRenewError);
            userManager.events.removeAccessTokenExpiring(handleAccessTokenExpiring);
        };
    }, [userManager, state.user]);
    const setUser = useCallback(async (user) => {
        const currentUser = await userManager?.getUser();
        if (currentUser) {
            currentUser.profile = user;
            await userManager?.storeUser(currentUser);
            dispatch({ type: 'USER_LOADED', user: currentUser });
        }
    }, [userManager]);
    const value = useMemo(() => ({ ...state, ...userManagerContext, setUser }), [state, userManagerContext, setUser]);
    return (_jsx(AuthContext.Provider, { value: value, children: children }));
};
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('Wrap app with AuthManager');
    }
    return context;
};
