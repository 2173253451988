class VaultApi {
    fetchService;
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getVaultInfo() {
        return this.fetchService.get('/vault-info');
    }
    getPreferences() {
        return this.fetchService.get('/vault/preferences');
    }
    updatePreferences(values) {
        return this.fetchService.put('/vault/preferences', values);
    }
}
export default VaultApi;
