import * as variants from '../typography';
const linkButton = {
    ...variants.heading6(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    px: 6,
    height: 12,
    fontWeight: 700,
    borderRadius: 'md',
};
const LinkComponentStyle = {
    baseStyle: ({ theme }) => ({
        _hover: {
            textDecoration: 'none',
        },
        _focus: {
            boxShadow: 'none',
        },
        _focusVisible: {
            boxShadow: theme.shadows.outlinePrimary400,
        },
    }),
    variants: {
        ...variants,
        primary500: {
            ...linkButton,
            color: 'white',
            backgroundColor: 'primary500',
            _hover: {
                backgroundColor: 'primary600',
                _disabled: {
                    backgroundColor: 'neutral300',
                },
            },
            _focus: {
                borderColor: 'primary600',
                boxShadow: 'inset 0px 0px 0px 4px rgba(130, 197, 203, 0.25)',
            },
        },
        primary100: ({ theme }) => ({
            ...linkButton,
            color: 'primary500',
            backgroundColor: 'primary100',
            _hover: {
                backgroundColor: 'primary200',
                _disabled: {
                    backgroundColor: 'neutral100',
                },
            },
            _focus: {
                borderColor: 'primary300',
                boxShadow: theme.shadows.outlinePrimary400,
            },
        }),
    },
};
export default LinkComponentStyle;
