export var EventAction;
(function (EventAction) {
    EventAction["ApplyFilter"] = "Apply Filter";
    EventAction["ApplyHistoryLogFilter"] = "Apply History Log Filter";
    EventAction["SelectedCategory"] = "Selected Category";
    EventAction["TogglePdfConversion"] = "Toggle PDF Conversion";
    EventAction["UploadedDocument"] = "Uploaded Document";
    EventAction["UploadFromCameraRoll"] = "Upload From Camera Roll";
    EventAction["TakeAPicture"] = "Take A Picture";
    EventAction["PushDocuments"] = "Push Documents";
    EventAction["ScanDocuments"] = "Scan Documents";
    EventAction["UploadDocuments"] = "Upload Documents";
    EventAction["SharedDocumentCitizen"] = "Shared Document Citizen";
    EventAction["SharedNabanDocumentCitizen"] = "Shared Naban Document Citizen";
    EventAction["SharedDocumentStudy"] = "Shared Document Study";
    EventAction["SharedNabanDocumentStudy"] = "Shared Naban Document Study";
    EventAction["UnsharedDocument"] = "Unshared Document";
    EventAction["DownloadedDocument"] = "Downloaded Document";
    EventAction["ExportedDocument"] = "Exported Document";
    EventAction["RemovedDocument"] = "Removed Document";
    EventAction["ManagedDocumentLabels"] = "Managed Document Labels";
    EventAction["ManagedDocumentCategories"] = "Managed Document Categories";
    EventAction["OpenendPrivacyPolicy"] = "Opened Privacy Policy";
    EventAction["OpenedFAQ"] = "Opened FAQ";
    EventAction["AddedContact"] = "Added Contact";
    EventAction["AddedNotary"] = "Added Notary";
    EventAction["AddedHeir"] = "Added Heir";
    EventAction["DeletedContact"] = "Deleted Contact";
    EventAction["CopyNabanAct"] = "Copy Naban Act";
    EventAction["ViewNabanAct"] = "View Naban Act";
    EventAction["ToggleVaultTransferPreferences"] = "Toggle Vault Transfer Preferences";
    EventAction["AddVaultTransfer"] = "Add Vault Transfer";
    EventAction["EditVaultTransfer"] = "Edit Vault Transfer";
    EventAction["DeleteVaultTransfer"] = "Delete Vault Transfer";
    EventAction["DuplicateVaultTransfer"] = "Duplicate Vault Transfer";
    EventAction["SaveVaultTransferAll"] = "Save Vault Transfer All";
    EventAction["SaveVaultTransferSpecific"] = "Save Vault Transfer Specific";
    EventAction["ReportedAProblem"] = "Reported A Problem";
    EventAction["DeletedDocumentsPermanently"] = "Deleted Documents Permanently";
    EventAction["RecoveredDocument"] = "Recovered Document";
    EventAction["DeactivatedAccount"] = "Deactivated Account";
    EventAction["RegisteredPersonalData"] = "Registered Personal Data";
    EventAction["RegisteredEmailAddress"] = "Registered Email Address";
    EventAction["RegisteredTermsAndConditions"] = "Registered Terms And Conditions";
    EventAction["RegisteredNaban"] = "Registered Naban";
    EventAction["UpdatedEmailAddress"] = "Updated Email Address";
    EventAction["AddedEmailAddress"] = "Added Email Address";
    EventAction["SelectedPreferredNotary"] = "Selected Preferred Notary";
    EventAction["AcceptedNotifications"] = "Accepted Notifications";
    EventAction["CertifyInstallPlugin"] = "Certify Install Plugin";
    EventAction["CertifyChooseContact"] = "Certify Choose Contact";
    EventAction["CertifyAbort"] = "Certify Abort";
    EventAction["CertifyOriginDocument"] = "Certify Origin Document";
    EventAction["CertifyIdenticalDocument"] = "Certify Identical Document";
    EventAction["CertifySignatureDocument"] = "Certify Signature Document";
    EventAction["CertifyOtherDocument"] = "Certify Other Document";
    EventAction["CertificationScanDocuments"] = "Certification Scan Documents";
    EventAction["CertificationTakePicture"] = "Certification Take Picture";
    EventAction["CertificationCameraRoll"] = "Certification Camera Roll";
    EventAction["CertificationUploadDocuments"] = "Certification Upload Documents";
    EventAction["CertificationUploadFromVault"] = "Certification Upload From Vault";
    EventAction["CertificationPreferredNotary"] = "Certification Preferred Notary";
    EventAction["CertificationOtherNotary"] = "Certification Other Notary";
    EventAction["SSNConnectionAdded"] = "Connection SSN Added";
    EventAction["SSNConnectionRemoved"] = "Connection SSN Removed";
    EventAction["StartedOnboarding"] = "Started Onboarding";
    EventAction["AccountExisting"] = "Account Existing";
    EventAction["CompletedOnboarding"] = "Completed Onboarding";
    EventAction["LoginButtonPressed"] = "LoginButtonPressed";
    EventAction["InheritedVaultNavigation"] = "Navigate to Inherited Vault";
    EventAction["ClaimVault"] = "Claim Vault";
    EventAction["TransferVault"] = "Transfer Vault";
    EventAction["CopiedInheritedDocuments"] = "Copied Inherited Documents";
    EventAction["DownloadedInheritedDocuments"] = "Downloaded Inherited Documents";
    EventAction["OpenedInheritedDocument"] = "Opened Inherited Document";
    EventAction["InheritanceCardPress"] = "Inheritance Card Press";
    EventAction["PostedSocialMedia"] = "Posted Social Media";
    EventAction["SharedIzimiFromNudge"] = "Shared Izimi From Nudge";
})(EventAction || (EventAction = {}));
