import inputStyleConfig from './input';
const SelectComponentStyle = {
    defaultProps: {
        variant: 'default',
        size: 'sm',
    },
    variants: {
        categoryPicker: {
            field: {
                height: '62px',
                fontSize: '16px',
            },
        },
        default: {
            field: {
                // @ts-expect-error okay
                ...inputStyleConfig.baseStyle?.field,
                px: 3,
                borderRadius: 'md',
                cursor: 'pointer',
            },
            icon: {
                color: 'neutral400',
            },
        },
    },
};
export default SelectComponentStyle;
