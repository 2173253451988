import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, memo } from 'react';
import Highlighter from 'react-highlight-words';
import { Box, Button, Flex, Td, Text, useBreakpointValue, useTheme, } from '@chakra-ui/react';
import format from 'date-fns/format';
import { ApiDocumentSource } from '@izimi/api';
import { getExtension, getNameWithoutExtension } from '@izimi/utils';
import { Dot } from '../Dot/Dot';
import { FileIcon } from '../icon';
const DocumentInheritedCheckmark = memo(({ isCopiedFromInheritedVault }) => {
    if (!isCopiedFromInheritedVault) {
        return null;
    }
    return (_jsx(Box, { bg: 'white', position: 'absolute', borderRadius: 'full', top: '7px', left: '-6px', width: 3, height: 3, children: _jsxs("svg", { viewBox: '0 0 14 15', fill: 'none', children: [_jsx("circle", { cx: 7, cy: 7.5, r: 6, fill: '#0F9F37', stroke: '#fff', strokeWidth: 2 }), _jsx("path", { d: 'm4 7 2.308 2.5L10 5.5', stroke: '#fff', strokeWidth: 1.5 })] }) }));
});
const DocumentPendingDot = memo(({ isPending }) => {
    if (!isPending) {
        return null;
    }
    return _jsx(Dot, { variant: 'secondary', pos: 'absolute', bottom: '-2px', right: '-2px' });
});
export const NameCell = forwardRef(({ cell, document, fileIconColor, onClick, onDoubleClick, children, searchValue, showUnseen = true, }, ref) => {
    const theme = useTheme();
    const isCertified = document.isCertified || document.isNabanDataType;
    return (_jsx(Td, { ref: ref, onClick: onClick, onDoubleClick: onDoubleClick, children: _jsxs(Flex, { alignItems: 'center', pl: { base: 3, md: 0 }, children: [_jsxs(Flex, { shrink: 0, pos: 'relative', children: [_jsx(FileIcon, { extension: getExtension(document.name), color: fileIconColor, withFodFinIcon: document.source === ApiDocumentSource.Tuyeau, isThirdParty: false, withNotaryIcon: document.isUploadedByNotary, isCertified: isCertified }), _jsx(DocumentInheritedCheckmark, { isCopiedFromInheritedVault: document.isCopiedFromInheritedVault }), _jsx(DocumentPendingDot, { isPending: document.isPending })] }), _jsxs(Flex, { direction: 'column', ml: 3, children: [_jsx(Button, { onClick: onClick, onDoubleClick: onDoubleClick, title: document.name, variant: 'unstyled', whiteSpace: 'inherit', textAlign: 'left', children: _jsx(Text, { variant: (showUnseen && !document.isSeen && 'tableHeadBold') ||
                                    'tableHead', noOfLines: 1, wordBreak: 'break-all', children: _jsx(Highlighter, { autoEscape: true, searchWords: [searchValue || ''], textToHighlight: getNameWithoutExtension(document.name), highlightStyle: {
                                        borderRadius: '3px',
                                        backgroundColor: theme.colors.primary100,
                                        color: theme.colors.primary500,
                                        paddingInline: '2px',
                                    } }) }) }), children] })] }) }, cell.id));
});
export const EditedDateCell = forwardRef(({ cell, document, onClick, dateLocale, ...props }, ref) => {
    const dateFormat = useBreakpointValue({ md: 'P', lg: 'PPP' }, { ssr: false });
    return (_jsx(Td, { ref: ref, onClick: onClick, display: { base: 'none', '2xl': 'table-cell' }, ...props, children: _jsx(Text, { variant: 'tableCell', children: format(new Date(document.modificationDate), dateFormat || 'PPP', {
                locale: dateLocale,
            }) }) }, cell.id));
});
export const OwnerCell = forwardRef(({ cell, onClick, documentOwnerText }, ref) => {
    return (_jsx(Td, { ref: ref, onClick: onClick, display: { base: 'none', xl: 'table-cell' }, children: _jsx(Text, { variant: 'tableCell', children: documentOwnerText }) }, cell.id));
});
