import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, HStack } from '@chakra-ui/react';
import { useModules } from '@izimi/modules';
export const ContactRequestReceivedActions = ({ notification }) => {
    const { t } = useTranslation('notifications');
    const navigate = useNavigate();
    const { notifications: { useDeleteNotification }, } = useModules();
    const { deleteNotification, isPending } = useDeleteNotification();
    const handleClick = async () => {
        await deleteNotification(notification.id);
        navigate('/sharing/from-contacts', {
            replace: true,
        });
    };
    return (_jsx(HStack, { mt: 2, children: _jsx(Button, { w: 'full', variant: 'primary500', onClick: handleClick, isLoading: isPending, children: t('watch') }) }));
};
