function HouseholdIcon({ size = 32 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
    >
      <rect x={2} width={28} height={26} rx={4} fill='#B70B0B' />
      <rect y={2} width={32} height={30} rx={4} fill='#E72A2A' />
      <rect
        y={2}
        width={32}
        height={30}
        rx={4}
        fill='url(#a)'
        fillOpacity={0.2}
        style={{
          mixBlendMode: 'multiply',
        }}
      />
      <path
        d='M7 13.65 16 7l9 6.65V24.1c0 .504-.21.987-.586 1.343A2.055 2.055 0 0 1 23 26H9c-.53 0-1.04-.2-1.414-.556A1.853 1.853 0 0 1 7 24.1V13.65Z'
        fill='#FAD4D4'
      />
      <path
        d='M7 13.65 16 7l9 6.65V24.1c0 .504-.21.987-.586 1.343A2.055 2.055 0 0 1 23 26H9c-.53 0-1.04-.2-1.414-.556A1.853 1.853 0 0 1 7 24.1V13.65Z'
        fill='url(#b)'
      />
      <path
        d='m7 14 9-7 9 7'
        stroke='#A50A0A'
        strokeWidth={3}
        strokeLinecap='square'
        strokeLinejoin='round'
      />
      <path
        opacity={0.8}
        d='M19.383 16.107a2.108 2.108 0 0 0-1.489-.607 2.137 2.137 0 0 0-1.488.607l-.406.398-.406-.398a2.124 2.124 0 0 0-1.489-.607c-.558 0-1.094.218-1.488.607A2.053 2.053 0 0 0 12 17.57c0 .55.222 1.076.617 1.464l.405.4L16 22.5l2.977-3.067.406-.399A2.068 2.068 0 0 0 20 17.57a2.04 2.04 0 0 0-.617-1.463Z'
        fill='#E72A2A'
      />
      <defs>
        <linearGradient
          id='a'
          x1={16}
          y1={10}
          x2={16}
          y2={32}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6A0808' stopOpacity={0} />
          <stop offset={1} stopColor='#690909' />
        </linearGradient>
        <linearGradient
          id='b'
          x1={16}
          y1={7}
          x2={16}
          y2={26}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default HouseholdIcon;
