import type { ReactNode } from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
// history is included in the react-router package
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';

import type { Routes } from 'navigation/types';

const history = createBrowserHistory({ window });

const CustomRouter = ({ children, ...props }: { children: ReactNode }) => {
  return (
    <HistoryRouter history={history} {...props}>
      {children}
    </HistoryRouter>
  );
};

type To = Routes | Partial<{ pathname: Routes; search: string; hash: string }>;

export const historyNavigate = (to: To) => {
  history.push(to);
};

export const historyReplace = (to: To) => {
  history.replace(to);
};

export default CustomRouter;
