import { extendTheme } from '@chakra-ui/react';
import * as chakraComponents from './chakra-components';
import colors from './colors';
import shadows from './shadows';
import { link, paragraph } from './typography';
const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
};
const overrides = {
    components: { ...chakraComponents },
    fonts: {
        heading: 'Yorkten Slab',
        body: 'Assistant',
    },
    // we have to use variants for now :(
    // https://github.com/chakra-ui/chakra-ui/issues/3884
    textStyles: {},
};
const styles = {
    global: {
        ':root': {
            '--app-height': '100%',
        },
        body: {
            color: 'neutral500',
        },
        '.external-content': {
            h2: {
                fontSize: { base: '24px', md: '28px' },
                lineHeight: 1.25,
                letterSpacing: '-0.5px',
                color: 'primary400',
                mt: 4,
                mb: 2,
            },
            p: { ...paragraph, mb: 4 },
            ul: { pl: 4, my: 4 },
            ol: { pl: 4, mb: 4 },
            li: { mb: 2 },
            a: {
                ...link,
                color: 'primary500',
                textDecoration: 'underline',
                _hover: { color: 'primary400' },
            },
        },
    },
};
// after modifying the theme, run 'yarn generate:chakra:theme-typings'
const theme = extendTheme({ colors, config, styles, shadows, ...overrides });
export default theme;
export { default as Fonts } from './Fonts';
export * as typography from './typography';
export { chakraComponents };
