import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { HistoryLogQueryKeys } from './HistoryLogQueryKeys';
export const historyLogQueryKeys = new HistoryLogQueryKeys('historyLog');
export const useInvalidateHistoryLogs = () => {
    const queryClient = useQueryClient();
    const invalidateHistoryLogs = useCallback(() => queryClient.invalidateQueries({
        queryKey: historyLogQueryKeys.getHistoryLogs(),
    }), [queryClient]);
    return invalidateHistoryLogs;
};
