import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, forwardRef } from 'react';
import { Box, Center, Flex, Grid, Text } from '@chakra-ui/react';
import { BaseLayout } from './BaseLayout';
const DEFAULT_PADDING = 7;
export const BaseIllustrationLayout = forwardRef(({ title, subTitle, titleAction, children, renderHeader, renderFooter, Illustration, navigation, ...chakraProps }, ref) => {
    return (_jsx(BaseLayout, { navigation: navigation, ...chakraProps, children: _jsxs(Grid, { templateColumns: { base: '1fr', xl: '1.5fr 1fr' }, templateRows: renderFooter ? '1fr auto' : undefined, height: '100%', children: [_jsxs(Flex, { flexDir: 'column', p: DEFAULT_PADDING, overflow: 'auto', ref: ref, ...chakraProps, children: [renderHeader, _jsxs(Flex, { flexDir: 'column', maxW: title ? 'xl' : 'full', mx: 'auto', mt: 10, flex: 1, pos: 'relative', children: [title && (_jsxs(Flex, { justifyContent: 'space-between', children: [_jsx(Text, { as: 'h1', variant: 'heading1', mb: 2, children: title }), titleAction] })), subTitle && (_jsx(Text, { variant: 'lead', mb: 10, width: titleAction ? '80%' : 'full', children: subTitle })), children] })] }), renderFooter && (_jsx(Center, { w: 'full', bgColor: 'white', boxShadow: 'card', gridRow: '2 / span 1', h: ['75px', '100px'], pos: { base: 'sticky', md: 'revert' }, bottom: { base: 0, md: 'revert' }, children: renderFooter })), Illustration && (_jsx(Box, { gridRow: renderFooter ? '1 / span 2' : undefined, children: cloneElement(Illustration, {
                        display: { base: 'none', xl: 'flex' },
                    }) }))] }) }));
});
