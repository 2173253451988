import { jsx as _jsx } from "react/jsx-runtime";
import { Global } from '@emotion/react';
const Fonts = () => (_jsx(Global, { styles: `
      /* latin */
      @font-face {
        font-family: 'Assistant';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/fonts/Assistant-Bold.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: 'Assistant';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('/fonts/Assistant-SemiBold.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: 'Assistant';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/Assistant-Regular.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: 'Yorkten Slab';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/yorkton-slab-normal.woff2') format('woff2'), url('/fonts/yorkton-slab-normal.woff') format('woff'), url('/fonts/yorkton-slab-normal.otf') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: 'Yorkten Slab';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/fonts/yorkton-slab-bold.woff2') format('woff2'), url('/fonts/yorkton-slab-bold.woff') format('woff'), url('/fonts/yorkton-slab-bold.otf') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      ` }));
export default Fonts;
