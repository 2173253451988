const notaryMessages = {
    getPreferredNotary() {
        return {
            errorMessage: {
                message: 'messages.notary.getPreferredNotaryFailed',
            },
        };
    },
    getNotaries() {
        return {
            errorMessage: {
                message: 'messages.notary.getNotariesFailed',
            },
        };
    },
    updateMailConfiguration() {
        return {
            errorMessage: {
                message: 'messages.notary.mailConfigurationChangedFailed',
            },
            successMessage: {
                message: 'messages.notary.mailConfigurationChangedSuccess',
            },
        };
    },
    deleteEmail() {
        return {
            errorMessage: {
                message: 'messages.notary.deleteEmailFailed',
            },
            successMessage: {
                message: 'messages.notary.deleteEmailSuccess',
            },
        };
    },
    updatePreferredNotary() {
        return {
            errorMessage: {
                message: 'messages.notary.updatePreferredNotaryFailed',
            },
        };
    },
    addNotaryStudyContact() {
        return {
            errorMessage: {
                message: 'messages.notary.addNotaryStudyContactFailed',
            },
        };
    },
    pushNotaryDocuments({ documentName, userName, numberOfDocuments, count, }) {
        const successMessageTranslationKey = numberOfDocuments === 1
            ? 'messages.notary.pushNotaryDocumentsSuccess.singleDoc'
            : 'messages.notary.pushNotaryDocumentsSuccess.multiDoc';
        const successMessage = [
            successMessageTranslationKey,
            {
                documentName,
                userName,
                numberOfDocuments,
                count,
            },
        ];
        return {
            successMessage: {
                message: successMessage,
            },
            errorMessage: {
                message: 'messages.notary.pushNotaryDocumentsFailed',
            },
        };
    },
};
export default notaryMessages;
