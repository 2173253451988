import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { useModalStore } from './modalStore';
export const modalEntities = {
    editDocumentName: lazy(() => import('../EditDocumentNameModal')),
    changeLanguage: lazy(() => import('../ChangeLanguageModal')),
    changeEmail: lazy(() => import('../ChangeEmailModal')),
    deleteContact: lazy(() => import('../DeleteContactModal')),
    selectNotary: lazy(() => import('../SelectNotaryModal')),
    documentFilters: lazy(() => import('../DocumentFiltersModal')),
    editDocumentLabels: lazy(() => import('../EditDocumentLabelsModal')),
    deleteDocuments: lazy(() => import('../DeleteDocumentsModal')),
    emptyTrash: lazy(() => import('../EmptyTrashModal')),
    confirmTrashDocument: lazy(() => import('../ConfirmTrashDocument')),
    trashDocumentsTableOptions: lazy(() => import('../TrashDocumentsTableOptionsModal')),
    documentHistoryLog: lazy(() => import('../DocumentHistoryLogModal')),
    warningVaultSizeExceeded: lazy(() => import('../WarningVaultSizeExceededModal')),
    confirmAcceptDecline: lazy(() => import('../ConfirmAcceptDeclineModal')),
    abortUpload: lazy(() => import('../AbortUploadModal')),
    selectDocuments: lazy(() => import('../SelectDocumentsModal')),
    documentSources: lazy(() => import('../DocumentSourcesModal')),
    nabanVaultSizeExceeded: lazy(() => import('../NabanVaultSizeExceededModal')),
    historyLogFilters: lazy(() => import('../HistoryLogFiltersModal')),
};
const ModalsRoot = ({ entities }) => {
    const { modalEntity } = useModalStore();
    if (modalEntity) {
        const { type, ...props } = modalEntity;
        const allEntities = { ...modalEntities, ...entities };
        if (type in allEntities) {
            const ModalComponent = allEntities[modalEntity.type];
            // @ts-expect-error match the type of the modal component
            return _jsx(ModalComponent, { ...props });
        }
    }
    return null;
};
export default ModalsRoot;
