import { buildQueryString } from '@izimi/utils';
class TermsAndConditionsApi {
    fetchService;
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getTermsAndConditions(params) {
        const qs = buildQueryString(params);
        return this.fetchService.get(`/terms-and-conditions${qs}`);
    }
}
export default TermsAndConditionsApi;
