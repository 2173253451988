import { useCallback } from 'react';

import type { ApiAcceptedLanguage } from '@izimi/api';
import { userMessageZSchema } from '@izimi/api';
import { useModalStore } from '@izimi/components';
import { useModules } from '@izimi/modules';

const useCheckUserMessages = () => {
  const {
    userMessages: { useGetUserMessages },
  } = useModules();
  const { showModal } = useModalStore();
  const { fetchUserMessages } = useGetUserMessages();

  const checkUserMessages = useCallback(
    async (language: ApiAcceptedLanguage) => {
      const messages = await fetchUserMessages(language);
      if (messages.length > 0) {
        // We only show the first message
        const userMessage = userMessageZSchema.parse(messages[0]);
        showModal({ type: 'userMessage', userMessage });
      }
    },
    [showModal, fetchUserMessages],
  );

  return { checkUserMessages };
};

export default useCheckUserMessages;
