import { jsx as _jsx } from "react/jsx-runtime";
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { useQueryParams } from '@izimi/hooks';
export const UploadZonePositioningWrapper = ({ children, }) => {
    const sideBarWidthOffset = useBreakpointValue({
        md: '330px',
        '2xl': '450px',
    }, { ssr: false });
    const { params } = useQueryParams();
    const isSideBarVisible = Boolean(params.id || params.historyLog);
    return (_jsx(Box, { transition: '0.2s', pos: 'absolute', px: { base: 4, md: 0 }, bottom: 4, right: {
            base: 'initial',
            md: isSideBarVisible ? `calc(${sideBarWidthOffset} + 1rem)` : 4,
        }, w: { base: '100%', md: 'md' }, children: children }));
};
