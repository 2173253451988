import { create } from 'zustand';
import { persist } from 'zustand/middleware';
const initialState = {
    hideAcceptRejectDocumentConfirmation: false,
};
export const useDocumentAcceptRejectStore = create()(persist(set => ({
    ...initialState,
    setHideAcceptRejectDocumentConfirmation: hideAcceptRejectDocumentConfirmation => set({ hideAcceptRejectDocumentConfirmation }),
}), {
    name: 'documentAcceptRejectStore',
    partialize(state) {
        return {
            hideAcceptRejectDocumentConfirmation: state.hideAcceptRejectDocumentConfirmation,
        };
    },
}));
