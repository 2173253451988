import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Flex, HStack, IconButton, Link, Text, VStack } from '@chakra-ui/react';
import { FeatherIcon } from '../icon';
const LinkIllustration = (props) => (_jsxs("svg", { width: 44, height: 44, fill: 'none', xmlns: 'http://www.w3.org/2000/svg', ...props, children: [_jsx("circle", { cx: 24, cy: 24, r: 20, fill: '#F2F7F8' }), _jsx("circle", { cx: 20.5, cy: 20, r: 19.5, stroke: '#82C5CB' }), _jsx("path", { d: 'M18.333 20.833a4.165 4.165 0 0 0 6.284.45l2.5-2.5a4.166 4.166 0 0 0-5.892-5.891l-1.433 1.425', stroke: '#82C5CB', strokeWidth: 1.25, strokeLinecap: 'round', strokeLinejoin: 'round' }), _jsx("path", { d: 'M21.667 19.167a4.166 4.166 0 0 0-6.284-.45l-2.5 2.5a4.166 4.166 0 0 0 5.892 5.891l1.425-1.425', stroke: '#006D86', strokeWidth: 1.25, strokeLinecap: 'round', strokeLinejoin: 'round' })] }));
const LinkItemWrapper = ({ children }) => {
    return (_jsx(VStack, { boxShadow: '0px 2px 6px rgba(61, 56, 54, 0.08)', borderRadius: '12px', border: '1px solid #F3F0EE;', my: 2, p: 4, children: children }));
};
const LinkItemInner = ({ title, href, ...rest }) => {
    const { t } = useTranslation();
    return (_jsx(Link, { href: href, isExternal: true, my: 2, w: '100%', title: title, ...rest, children: _jsxs(HStack, { children: [_jsx(Flex, { shrink: 0, mx: 2, children: _jsx(LinkIllustration, {}) }), _jsx(Text, { variant: 'heading6', color: 'primary500', width: '100%', children: title }), _jsx(IconButton, { "aria-label": t('action.open'), icon: _jsx(FeatherIcon, { name: 'arrow-right', color: 'primary500' }) })] }) }));
};
const LinkItem = ({ title, href, ...rest }) => {
    return (_jsx(LinkItemWrapper, { children: _jsx(LinkItemInner, { title: title, href: href, ...rest }) }));
};
LinkItem.Wrapper = LinkItemWrapper;
LinkItem.Inner = LinkItemInner;
LinkItem.Illustration = LinkIllustration;
export default LinkItem;
