import { useDeferredValue } from 'react';
import { isSameDay } from 'date-fns';
import { create } from 'zustand';
import { FIRST_OF_JANUARY_2019 } from '../documents';
const initialState = {
    filters: {
        afterDate: FIRST_OF_JANUARY_2019,
        beforeDate: new Date(),
        types: [],
    },
    setFilters: () => { },
    resetFilters: () => { },
    searchValue: '',
    setSearchValue: () => { },
    hasActiveFilters: () => false,
};
export const useHistoryLogFiltersStore = create()((set, get) => ({
    ...initialState,
    setFilters: (filters) => {
        set({ ...get(), filters });
    },
    resetFilters: () => {
        set({ ...get(), filters: initialState.filters });
    },
    setSearchValue: (searchValue) => {
        set({ ...get(), searchValue });
    },
    hasActiveFilters: () => {
        const { afterDate, beforeDate, types } = get().filters;
        return (!isSameDay(afterDate, FIRST_OF_JANUARY_2019) ||
            !isSameDay(beforeDate, new Date()) ||
            types.length > 0);
    },
}));
export const useHistoryLogFiltersSearchValue = () => {
    const { searchValue } = useHistoryLogFiltersStore();
    const debouncedValue = useDeferredValue(searchValue);
    return debouncedValue;
};
