import { useTranslation } from 'react-i18next';

import { createConfigurationLinks } from '@izimi/core';
import { createPublicModule, useModules } from '@izimi/modules';
import { publicApi } from 'core/di';

export const publicModule = createPublicModule({ publicApi });

export const useConfigurationLinks = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const {
    public: { useWebConfiguration },
  } = useModules();
  const { configuration } = useWebConfiguration();

  return createConfigurationLinks(
    configuration,
    language,
    `https://www.izimi.be`,
  );
};
