import { useMemo } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { ApiDocumentSource } from '@izimi/api';
import { createDocumentsModule, useInvalidateDocumentsVaultListCache, useUpdatePagedDocumentsCache, } from '../documents';
import { nabanQueryKeys, useInvalidateNabanDocumentsCache } from './cache';
const STALE_30_MINUTES = 1800000;
const createNabanModule = ({ nabanApi, documentsApi, }) => {
    const { usePagedDocuments } = createDocumentsModule({
        documentsApi,
    });
    const usePagedNabanDocuments = ({ showCopiedDeeds = true, pageSize = 16, } = {}) => {
        const { data, isLoading, error, refetch, fetchNextPage, isFetchingNextPage, hasNextPage, isSuccess, } = useInfiniteQuery({
            queryKey: nabanQueryKeys.getNabanDocuments(),
            initialPageParam: 0,
            queryFn: ({ pageParam = 0 }) => nabanApi.getNabanDocuments({
                page: Number(pageParam),
                pageSize,
                showImportedDeeds: showCopiedDeeds,
            }),
            getNextPageParam: lastPage => {
                const currentPage = lastPage?.page || 0;
                const totalSize = lastPage?.totalSize || 0;
                const nextPage = currentPage + 1;
                const maxPage = Math.ceil(totalSize / pageSize);
                return nextPage <= maxPage ? nextPage : undefined;
            },
            staleTime: STALE_30_MINUTES,
        });
        const nabanDocuments = useMemo(() => {
            const allDocs = data?.pages?.flatMap(page => page.list) ?? [];
            return allDocs.filter((doc) => doc !== undefined);
        }, [data?.pages]);
        return {
            // naban documents in the vault + naban documents to be imported
            totalNabanDocumentsInDatabase: Number(data?.pages?.[0].totalSize ?? 0),
            nabanDocuments,
            allDeedsInVault: data?.pages?.[0].allDeedsInVault ?? false,
            error,
            isLoading,
            refetch,
            fetchNextPage,
            isFetchingNextPage,
            isSuccess,
            hasNextPage,
        };
    };
    const useGetNabanDocument = ({ nabanDocumentId, enabled, }) => {
        const { data, isLoading, error, refetch } = useQuery({
            queryKey: nabanQueryKeys.getNabanDocument(nabanDocumentId),
            queryFn: () => nabanApi.getNabanDocument({ nabanDocumentId }),
            enabled,
        });
        return {
            nabanDocument: data,
            error,
            isLoading,
            refetch,
        };
    };
    const useImportNabanDocuments = () => {
        const updatePagedDocumentsCache = useUpdatePagedDocumentsCache();
        const invalidateNabanDocumentsCache = useInvalidateNabanDocumentsCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: documentIds => nabanApi.importNabanDocuments(documentIds),
            onSuccess(documents) {
                updatePagedDocumentsCache('nabanList', allDocuments => {
                    return [...documents, ...allDocuments];
                });
                updatePagedDocumentsCache('vaultList', allDocuments => {
                    return [...documents, ...allDocuments];
                });
                invalidateNabanDocumentsCache();
            },
        });
        return {
            importNabanDocuments: mutateAsync,
            error,
            isPending,
        };
    };
    const useImportedNabanDocuments = ({ enabled = true } = {}) => {
        const { documents } = usePagedDocuments({
            enabled,
            type: 'nabanList',
            params: { sources: ApiDocumentSource.Naban },
        });
        return documents?.filter(d => d.isNabanDataType) ?? [];
    };
    const useDownloadNabanDocument = () => {
        const { mutateAsync, isPending, error } = useMutation({ mutationFn: params => nabanApi.downloadNabanDocument(...params) });
        return {
            downloadNabanDocument: mutateAsync,
            error,
            isPending,
        };
    };
    const useCopyNabanDocuments = () => {
        const invalidateNabanDocumentsCache = useInvalidateNabanDocumentsCache();
        const invalidateDocumentsNabanListCache = useInvalidateDocumentsVaultListCache();
        const { mutateAsync, isPending, error } = useMutation({
            mutationFn: nabanDocumentIds => nabanApi.copyNabanDocuments(nabanDocumentIds),
            onSuccess() {
                invalidateNabanDocumentsCache();
                invalidateDocumentsNabanListCache();
            },
        });
        return {
            copyNabanDocuments: mutateAsync,
            error,
            isPending,
        };
    };
    return {
        usePagedNabanDocuments,
        useGetNabanDocument,
        useImportNabanDocuments,
        useImportedNabanDocuments,
        useDownloadNabanDocument,
        useCopyNabanDocuments,
    };
};
export default createNabanModule;
