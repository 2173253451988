import { differenceInDays } from 'date-fns';
import { ApiDocumentSource } from '@izimi/api';
import { convertLocalDateToUTCDate, formatBytes, getExtension, getNameWithoutExtension, } from '@izimi/utils';
const DEFAULT_TRASHING_DAYS = 180;
const CLOSE_TO_TRASHING_DAYS = 30;
const CLOSE_TO_DELETION_DAYS = 7;
const previewableDocumentExtensions = [
    'pdf',
    'bmp',
    'gif',
    'jpg',
    'jpeg',
    'png',
    'svg',
];
class DocumentEntity {
    id;
    name;
    categories;
    labels;
    isShareable;
    isBeingScanned;
    isSeen;
    creationDate;
    modificationDate;
    scheduledTrashDate;
    scheduledDeleteDate;
    isOwner;
    size;
    trashingDate;
    sharedMessage;
    owner;
    source;
    sources;
    sharedWith;
    state;
    isCopied;
    hasVirus;
    metadata;
    afterDeathSelection;
    constructor(document) {
        Object.assign(this, document);
    }
    calculateDays(date) {
        if (!date)
            return undefined;
        const now = convertLocalDateToUTCDate(new Date());
        const scheduledDate = convertLocalDateToUTCDate(new Date(date));
        const difference = differenceInDays(scheduledDate, now);
        return difference <= 0 ? 1 : difference;
    }
    get fileNameWithoutExtension() {
        return getNameWithoutExtension(this.name);
    }
    get isNabanDataType() {
        return (this.source === ApiDocumentSource.Naban ||
            this.sources[0]?.uploadSource === 'NABAN');
    }
    get shouldShowNabanInfoMessage() {
        return (this.isOwner &&
            this.isNabanDataType &&
            this.source !== ApiDocumentSource.User);
    }
    /**
     * Returns the name of the source if the document was uploaded by a notary, otherwise returns null.
     * If the last source is 'FPS_FINANCE' or 'VLABEL', returns the name of the second source, otherwise returns the name of the first source.
     */
    get sourceName() {
        if (!this.isUploadedByNotary) {
            return null;
        }
        const lastSource = this.sources[0].uploadSource;
        if (lastSource === 'FPS_FINANCE' || lastSource === 'VLABEL') {
            return this.sources[1]?.name;
        }
        return this.sources[0]?.name;
    }
    get isPreviewable() {
        return previewableDocumentExtensions.includes(this.extension);
    }
    get isPDF() {
        return this.extension === 'pdf';
    }
    get extension() {
        return getExtension(this.name);
    }
    get isSharedWithOthers() {
        return this.sharedWith ? this.sharedWith.length > 0 : false;
    }
    get isSharedByOtherUser() {
        return Boolean(!this.isOwner && this.owner !== undefined);
    }
    get isCertified() {
        return Boolean(this.metadata?.certification);
    }
    get mimeType() {
        if (this.extension) {
            // FIXME: what about other mime-types
            const mimeTypes = {
                pdf: 'application/pdf',
                png: 'image/png',
                svg: 'image/svg+xml',
                jpeg: 'image/jpeg',
                jpg: 'image/jpg',
            };
            return mimeTypes[this.extension];
        }
        return null;
    }
    get formattedSize() {
        return formatBytes(this.size, 2);
    }
    get isUploadedByNotary() {
        return this.source === ApiDocumentSource.Study;
    }
    get isUploadedByThirdParty() {
        return this.source === ApiDocumentSource.SSN;
    }
    get isUploadedByUser() {
        return this.source === ApiDocumentSource.User;
    }
    get isVlabel() {
        return this.source === ApiDocumentSource.Vlabel;
    }
    get isCopiedFromInheritedVault() {
        return Boolean(this.state === 'INHERITED' && this.isCopied);
    }
    get isActive() {
        return this.state === 'ACTIVE';
    }
    get isPending() {
        return this.state === 'PENDING';
    }
    get isInherited() {
        return this.state === 'INHERITED';
    }
    get daysTillTrashing() {
        return this.calculateDays(this.scheduledTrashDate);
    }
    get isCloseToTrashing() {
        return ((this.daysTillTrashing ?? DEFAULT_TRASHING_DAYS) <= CLOSE_TO_TRASHING_DAYS);
    }
    get allowExtending() {
        const trashing = this.daysTillTrashing ?? DEFAULT_TRASHING_DAYS;
        const deletion = this.daysTillDeletion ?? DEFAULT_TRASHING_DAYS;
        return trashing < DEFAULT_TRASHING_DAYS || deletion < DEFAULT_TRASHING_DAYS;
    }
    get daysTillDeletion() {
        return this.calculateDays(this.scheduledDeleteDate);
    }
    get isCloseToDeletion() {
        return ((this.daysTillDeletion ?? DEFAULT_TRASHING_DAYS) <= CLOSE_TO_DELETION_DAYS);
    }
    get nabanMetadata() {
        if (!this.isNabanDataType || !this.metadata.providerData?.NABAN) {
            return undefined;
        }
        const nabanMetadata = this.metadata.providerData?.NABAN.reduce((acc, { key, value }) => {
            if (!key || !value)
                return acc;
            acc[key] = value;
            return acc;
        }, {});
        return nabanMetadata;
    }
    get vlabelMetaData() {
        if (!this.isVlabel || !this.metadata.providerData?.VLABEL) {
            return undefined;
        }
        const vlabelMetadata = this.metadata.providerData.VLABEL.reduce((acc, { key, value }) => {
            if (!key || !value)
                return acc;
            acc[key] = value;
            return acc;
        }, {});
        return {
            documentType: vlabelMetadata.DOCUMENTTYPE,
            taxFamily: vlabelMetadata.BELASTINGFAMILIE,
            ogmNumber: vlabelMetadata.OGMNUMMER,
            expeditionNumber: vlabelMetadata.EXPEDITIENUMMER,
            repertoryNumber: vlabelMetadata.REPERTORIUMNUMMER,
            heir: vlabelMetadata.ERFLATER,
        };
    }
}
export default DocumentEntity;
