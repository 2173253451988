import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef, useState } from 'react';
import { CloseButton, Input, InputGroup, InputLeftElement, InputRightElement, Text, } from '@chakra-ui/react';
import { CustomIcon } from '../../icon';
const CustomInput = ({ value, onClick, onChange, onClear, clearable, label }, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    return (_jsxs(_Fragment, { children: [label && _jsx(Text, { variant: 'heading6', children: label }), _jsxs(InputGroup, { children: [_jsx(InputLeftElement, { pointerEvents: 'none', children: _jsx(CustomIcon, { name: 'calendar', color: isFocused ? 'primary500' : 'neutral500' }) }), _jsx(Input, { autoComplete: 'off', background: 'white', value: value, ref: ref, onClick: onClick, onChange: onChange, onFocus: handleFocus, onBlur: handleBlur, borderColor: 'neutral200', focusBorderColor: 'primary400', _hover: {
                            borderColor: 'neutral400',
                        }, paddingRight: 8 }), clearable && (_jsx(InputRightElement, { width: 8, children: _jsx(CloseButton, { size: 'sm', onClick: onClear }) }))] })] }));
};
export const DatePickerInput = forwardRef(CustomInput);
